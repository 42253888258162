import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonPricingComponent } from '../../common-pricing';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';

@Component({
  templateUrl: './tile-global-configuration.html'
})

export class TileGlobalConfigurationComponent extends CommonPricingComponent implements OnInit {

  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef) {
    super(fb, toast, settingService, userService, cdr);
  }

  ngOnInit() {
    this.tabs = [
      { id: 'application', text: 'Applications' },
      { id: 'types', text: "Types" },
      { id: 'thickness', text: "Thickness" },
      { id: 'sizes', text: 'Sizes' },
      { id: 'sets', text: 'Sets' },
      { id: 'specialConditions', text: 'Special Conditions' },
      { id: 'trimTypes', text: "Trim Types" },
      { id: 'trimEdges', text: "Trim Edges" },
    ];
    this.curTab = this.tabs[0];

    super.ngOnInit();
  }
}
