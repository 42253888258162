import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../services/dashboard.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
import { ReportService } from 'src/app/services/report.service';
declare var $: any;

@Component({
  selector: 'page-dashboards',
  templateUrl: './dashboards.html',
  encapsulation: ViewEncapsulation.None
})

export class DashboardsComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;
  @ViewChild('modalAssignRole') modalAssignRole: BsModalComponent;

  loadingTenant: any;
  tenants: any;
  tenantsFromActiveDashboard: any;
  listRoles;
  tenantId = 0;
  dashboardIdDelete = 0;
  dashboardIdAssign = 0;
  modal_title: string;

  dashboards: any;
  dashboardCategories: any;
  dashboardsOfTenantFromActiveDashboard: any;
  tokenActiveDashboard = "";
  form: FormGroup;
  loading_submit: boolean;
  waiting_loading: string;
  error_message = '';

  paginationPage = 1;
  paginationCount: number;

  filter: PaginationFilter = {
    orderBy: 'dashboardName',
    order: 'desc',
    itemPerPage: 10,
    pageNumber: 1,
    tenantId: 1
  };

  constructor(private settingService: SettingService, private dashboardService: DashboardService, private reportService: ReportService, formBuilder: FormBuilder, private toastr: ToastrService) {
    this.form = formBuilder.group({
      dashboardId: [0],
      dashboardName: ['', Validators.required],
      menuName: [''],
      dashboardDescription: [''],
      dashboardCategoryId: ['', Validators.required],
      dashboardObjectName: [null, Validators.required],
      sourceDashboard: ['', Validators.required],
      tenantId: [''],
      parameterTenantId: [false, Validators.requiredTrue],
      parameterUserId: [false],
      parameterSelectorUserId: [false],
      parameterAccountId: [false],
      parameterProjectId: [false],
      parameterBidId: [false],
      parameterContractId: [false],
      parameterCommunityId: [false],
      parameterQuoteId: [false],
      parameterPriceSchemaId: [false]
    });
  }

  get dashboardId(): FormControl {
    return this.form.get('dashboardId') as FormControl;
  }

  get dashboardName(): FormControl {
    return this.form.get('dashboardName') as FormControl;
  }

  get dashboardDescription(): FormControl {
    return this.form.get('dashboardDescription') as FormControl;
  }
  get menuName(): FormControl {
    return this.form.get('menuName') as FormControl;
  }
  get dashboardCategoryId(): FormControl {
    return this.form.get('dashboardCategoryId') as FormControl;
  }
  getErrorDashboardCategoryId() {
    return this.dashboardCategoryId.hasError('required') ? 'Dashboard category is required' : '';
  }

  get dashboardObjectName(): FormControl {
    return this.form.get('dashboardObjectName') as FormControl;
  }
  getErrorDashboardObjectName() {
    return this.dashboardObjectName.hasError('required') ? 'Source dashboard is required' : '';
  }

  get sourceDashboard(): FormControl {
    return this.form.get('sourceDashboard') as FormControl;
  }

  get parameterTenantId(): FormControl {
    return this.form.get('parameterTenantId') as FormControl;
  }

  get parameterUserId(): FormControl {
    return this.form.get('parameterUserId') as FormControl;
  }

  get parameterSelectorUserId(): FormControl {
    return this.form.get('parameterSelectorUserId') as FormControl;
  }

  get parameterAccountId(): FormControl {
    return this.form.get('parameterAccountId') as FormControl;
  }

  get parameterProjectId(): FormControl {
    return this.form.get('parameterProjectId') as FormControl;
  }

  get parameterBidId(): FormControl {
    return this.form.get('parameterBidId') as FormControl;
  }

  get parameterContractId(): FormControl {
    return this.form.get('parameterContractId') as FormControl;
  }

  get parameterCommunityId(): FormControl {
    return this.form.get('parameterCommunityId') as FormControl;
  }

  get parameterQuoteId(): FormControl {
    return this.form.get('parameterQuoteId') as FormControl;
  }

  get parameterPriceSchemaId(): FormControl {
    return this.form.get('parameterPriceSchemaId') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    if (sessionStorage.getItem('tenantId')) this.tenantId = Number(sessionStorage.getItem('tenantId'));

    this.loadingTenant = true;
    this.settingService.getTenant().then(response => {
      if (response.status) this.tenants = response.data;
      else this.toastr.error('Can\'t get list tenant');
      this.loadingTenant = false;
    });

    this.dashboardService.getAllDashboardCategory().then(res => {
      if (res.status) this.dashboardCategories = res.data;
      else this.toastr.error('Can\'t get list dashboard category');
    });

    this.getListDashboard();
  }

  changeTenant() {
    sessionStorage.setItem('tenantId', this.tenantId.toString());
    this.getListDashboard();
  }

  setSourceDashboard() {
    this.sourceDashboard.setValue(this.dashboardsOfTenantFromActiveDashboard.find(rc => rc.id == this.dashboardObjectName.value).title);
  }

  getListDashboard() {
    this.dashboards = null;
    this.waiting_loading = 'Loading...';
    this.filter.tenantId = this.tenantId;
    if (this.filter.tenantId == 0) return;
    this.dashboardService.getListDashboards(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.dashboards = response.data.listData;
        this.paginationCount = response.data.total;
        this.waiting_loading = '';
      } else {
        this.waiting_loading = 'No data';
      }
      this.loadingTenant = false;
    }).catch(() => {
      this.dashboards = [];
      this.loadingTenant = false;
      this.waiting_loading = 'No data';
      this.paginationCount = 0;
    });
  }

  getListDashboardOfTenantFromActiveDashboard() {
    this.dashboardsOfTenantFromActiveDashboard = [];
    var tenantName = this.tenants.find(t => t.tenantId == this.tenantId).tenantName;
    this.reportService.getDocumentsByTenantName('dbd', tenantName).then(res => {
      this.modal.open();
      this.dashboardsOfTenantFromActiveDashboard = res.data;
    });
  }
  getListDashboardOfTenantFromActiveDashboard2() {
    this.dashboardsOfTenantFromActiveDashboard = [];
    this.dashboardService.loginActiveDashboard().then(res => {
      const token = res.Token;
      this.modal.open();
      this.dashboardService.getListTenantFromActiveDashboard(token).then(res1 => {
        this.tenantsFromActiveDashboard = res1;
        var tenantName = this.tenants.find(t => t.tenantId == this.tenantId).tenantName;
        var id = this.tenantsFromActiveDashboard.find(t => t.Name == tenantName).Id;
        this.dashboardService.getListDashboardOfTenantFromActiveDashboard(token, id).then(res2 => {
          this.dashboardsOfTenantFromActiveDashboard = res2;
        });
      });
    });
  }

  addNewDashboard() {
    this.modal_title = 'Add Dashboard';
    this.form.reset();
    this.form.patchValue({
      dashboardId: 0,
      dashboardName: '',
      menuName: '',
      dashboardDescription: '',
      dashboardCategoryId: '',
      dashboardObjectName: null,
      sourceDashboard: '',
      tenantId: this.tenantId,
      parameterTenantId: false,
      parameterUserId: false,
      parameterSelectorUserId: false,
      parameterAccountId: false,
      parameterProjectId: false,
      parameterBidId: false,
      parameterContractId: false,
      parameterCommunityId: false,
      parameterQuoteId: false,
      parameterPriceSchemaId: false
    });
    this.getListDashboardOfTenantFromActiveDashboard();
  }

  update(t) {
    this.modal_title = 'Edit Dashboard';
    this.form.reset();
    this.form.patchValue({
      dashboardId: t.dashboardId,
      dashboardName: t.dashboardName,
      menuName: t.menuName,
      dashboardDescription: t.dashboardDescription,
      dashboardCategoryId: t.dashboardCategoryId,
      dashboardObjectName: t.dashboardObjectName,
      sourceDashboard: t.sourceDashboard,
      tenantId: this.tenantId,
      parameterTenantId: t.parameterTenantId,
      parameterUserId: t.parameterUserId,
      parameterSelectorUserId: t.parameterSelectorUserId,
      parameterAccountId: t.parameterAccountId,
      parameterProjectId: t.parameterProjectId,
      parameterBidId: t.parameterBidId,
      parameterContractId: t.parameterContractId,
      parameterCommunityId: t.parameterCommunityId,
      parameterQuoteId: t.parameterQuoteId,
      parameterPriceSchemaId: t.parameterPriceSchemaId
    });
    this.getListDashboardOfTenantFromActiveDashboard();
  }

  activeDashboard(dashboard, status) {
    this.dashboardService.activeDashboard(dashboard.dashboardId, status).then(response => {
      if (response.status) {
        if (status) {
          this.toastr.success('Activate dashboard successfully');
        } else {
          this.toastr.success('Deactivate dashboard successfully');
        }
        dashboard.active = !dashboard.active;
      } else {
        if (status) {
          this.toastr.error('Activate dashboard failed');
        } else {
          this.toastr.error('Deactivate dashboard failed');
        }
      }
    });
  }

  setDashboardStandard(dashboard, status) {
    this.dashboardService.setDashboardStandard(dashboard.dashboardId, status).then(response => {
      if (response.status) {
        if (status) {
          this.toastr.success('Set dashboard standard successfully');
        } else {
          this.toastr.success('Set dashboard unstandard successfully');
        }
        dashboard.isStandard = !dashboard.isStandard;
      } else {
        if (status) {
          this.toastr.error('Set dashboard standard failed');
        } else {
          this.toastr.error('Set dashboard unstandard failed');
        }
      }
    });
  }
  assignRoles(dashboardId) {
    this.dashboardIdAssign = dashboardId;
    this.dashboardService.loadRoleByTenantId(this.tenantId, this.dashboardIdAssign).then(res => {
      if (res.status) {
        this.listRoles = res.data;
        this.modalAssignRole.open();
      }
      else {
        this.toastr.error('Load list roles failed.');
      }
    });
  }

  submitAssignRole() {
    let listRole = [];
    this.listRoles.forEach(e => {
      if (e.isCheck == '1') listRole.push(e.roleId);
    });

    this.dashboardService.assignDashboardPermission(this.tenantId, this.dashboardIdAssign, listRole).then(response => {
      if (response.status) {
        this.toastr.success('Assign role for dashboard successfully.', 'Success');
        this.modalAssignRole.close();
      } else {
        this.toastr.error('Assign role for dashboard failed.', 'Error');
        this.modalAssignRole.close();
      }
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
      this.modalAssignRole.close();
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading_submit = true;
      if (this.dashboardId.value > 0) {
        this.dashboardService.updateDashboard(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data >= 0) {
            this.toastr.success(response.message, 'Dashboard has been updated');
            this.getListDashboard();
            this.modal.close();
          }
          else {
            this.dashboardName.setErrors({ 'isExistDashboard': true });
            this.error_message = response.message;
          }
        });
      }
      else {
        this.dashboardService.addNewDashboard(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data > 0) {
            this.toastr.success(response.message, 'New dashboard has been created');
            this.getListDashboard();
            this.modal.close();
          }
          else {
            this.dashboardName.setErrors({ 'isExistDashboard': true });
            this.error_message = response.message;
          }
        });
      }
    }
  }

  deleteDashboard(dashboardId) {
    this.dashboardIdDelete = dashboardId;
    this.modalConfirmDelete.open();
  }

  submitDelete() {
    this.modalConfirmDelete.close();
    this.dashboardService.deleteDashboard(this.dashboardIdDelete).then(response => {
      this.loading_submit = false;
      if (response.status) {
        this.toastr.success(response.message, 'Dashboard has been removed');
        this.getListDashboard();
      }
    }).catch(() => {
      this.loading_submit = false;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  changeFilter(orderBy: string) {
    if (!orderBy) {
      this.filter.orderBy = 'dashboardName';
      this.filter.order = 'desc';
    }
    else if (orderBy === this.filter.orderBy) {
      if (this.filter.order === 'desc') {
        this.filter.order = 'asc';
      } else {
        this.filter.order = 'desc';
      }
    }
    else {
      this.filter.orderBy = orderBy;
      this.filter.order = 'desc';
    }
    this.getListDashboard();
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) {
      return;
    }
    this.filter.pageNumber = n;
    this.getListDashboard();
  }

  onNext(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getListDashboard();
  }

  onPrev(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getListDashboard();
  }
}

export class PaginationFilter {
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
  tenantId: number;
}
