import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
    selector: 'tab-channel-weights',
    templateUrl: './channel-weights.html',
    styleUrls: ['./channel-weights.scss']
})

export class ChannelWeightsComponent implements OnInit, OnDestroy {
    @Input() costSchemaId: any;
    @Input() channels: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('modalViewComments') modalViewComments: any;
    notes:any;
    channelId: any;
    processTypeId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;

    constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService) {

    }

    ngOnInit() {
        this.channelId = this.channels[0].channelId;
        this.processTypeId = 2;
        this.loadData()
    }
    ngOnDestroy(){
        this.save();
    }
    viewComment(item){
        // const id = item.value.schemaProcessId;
        // this.settingService.getMtxNoteChan(id).then(res=> {
        //     if(res.status){
        //         this.notes = res.data;
        //         // this.viewComments.open();
        //         this.modalViewComments.open();
        //     }
        //     else this.toast.error('view comment failed.');
        // });
    }
    loadData() {
        this.loading = true;
        this.settingService.getChannelWeights(this.costSchemaId).then(response => {
            if (response.status) {
                this.data = response.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
            else this.toast.error("Load data failed");
        });
    }

    filterData() {
        this.dataFilter = this.data.filter(e => e.processTypeId == this.processTypeId && e.channelId == this.channelId);
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            id: e.id,
            processName: e.processName,
            percentAdjustment: new PercentPipe('en-US').transform(e.percentAdjustment, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.id == e.id);
        item.percentAdjustment = this.settingService.replaceMask(e.percentAdjustment) / 100;
        item.note = e.note;
        return {
            id: e.id,
            percentAdjustment: this.settingService.replaceMask(e.percentAdjustment) / 100,
            note: e.note,
        };
    }

    save() {
        let dataUpdate = [];
        let cellUpdate: any;
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                dataUpdate.push(this.createItemUpdate(e.value));
                e.markAsPristine();
                e.markAsUntouched();
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateChannelWeights(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Channel weights has been updated');
                    cellUpdate?.get('updatedOn').setValue(response.data);
                    if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
                }
                else this.toast.error('Update channel weights failed');
            });
        }
    }
}
