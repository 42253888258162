<div class="container">
  <div class="row">
    <div class="col-lg-offset-4 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 col-xs-12">
      <img src="assets/img/QXM-Pro-Blue.png" style="display: block; margin-left: auto; margin-right: auto; margin-top: 50px; margin-bottom: 10px; height: 70px;" />
      <p class="text-center" style="font-size: 16px; margin-bottom: 30px">Adminstrative + Management Solution</p>
      <form id="loginForm">
        <div class="form-group input-group">
          <span class="input-group-addon">
            <i class="glyphicon glyphicon-envelope"></i>
          </span>
          <input class="form-control" type="text" name='email' (keypress)="keypress($event)" [(ngModel)]="email" placeholder="Email"
          />
        </div>
        <div class="form-group input-group" *ngIf="loginFlag">
          <span class="input-group-addon">
            <i class="glyphicon glyphicon-lock"></i>
          </span>
          <input class="form-control" type="password" name='password' (keypress)="keypress($event)" [(ngModel)]="password" placeholder="Password"
          />
        </div>
        <div class="form-group" *ngIf="loginFlag">
          <button type="button" id="btnLoginSubmit" class="btn btn-primary btn-block" (click)="login()">Login
            <i *ngIf="waiting_response" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px; position: absolute; left: 20px;"></i>
          </button>
        </div>
        <!-- <div class="form-group" *ngIf="!loginFlag">
          <button type="button" id="btnForgotSubmit" class="btn btn-primary btn-block" (click)="forgot()">Submit
            <i *ngIf="waiting_response" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px; position: absolute; left: 20px;"></i>
          </button>
        </div> -->
        <!--<div class="checkbox">-->
        <!--<a *ngIf="loginFlag && !waiting_response" href="javascript:void(0)" (click)="loginFlag = false">Forgot Password</a>-->
        <!--<a *ngIf="!loginFlag && !waiting_response" href="javascript:void(0)" (click)="loginFlag = true"><i class="fa fa-chevron-left" aria-hidden="true"></i> Sign In</a>-->
        <!--</div>-->
      </form>
    </div>
  </div>
</div>