<div class="row">
  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Rate Description</th>
              <th [style.width.px]="150">Rate</th>
              <th>Updated On</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <input type="text" class="form-control" formControlName="rateDescription" readonly>
                  </td>
                  <td [style.width.px]="150">
                    <input type="text" class="form-control text-right" prefix="$" mask="separator.4" thousandSeparator="," separatorLimit="100000000000" formControlName="rate" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
