import { BsModalComponent } from 'ng2-bs3-modal';
import { CostSchemaService } from './../../../../services/cost-schema.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { CommonPricingComponent } from "../../common-pricing";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { SettingService } from "src/app/services/setting.service";
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from "ngx-toastr";
declare var $: any;
@Component({
  templateUrl: "./slab-cost-detail.html",
  styleUrls: ["./slab-cost-detail.scss"],
})

export class SlabCostDetailComponent extends CommonPricingComponent implements OnInit {

  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  channels: any;
  activeCostSchemas: any;
  reassignSchemaId: number = 0;
  bidSubTypes: any;
  directionalities: any;
  thicknesses: any;
  siteAccesses: any;
  buildingLevels: any;
  form: FormGroup;
  tenantId: number = -1;
  openDetail: boolean = false;
  schemaId: number;
  loading: boolean = false;


  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef, activatedRoute: ActivatedRoute,
    private router: Router, private costSchema: CostSchemaService) {
    super(fb, toast, settingService, userService, cdr);
    this.form = fb.group({
      schemaName: ["", Validators.required],
      description: "",
      active: false,
    });

    if (this.isTenantOnly) this.form.disable();

    activatedRoute.params.subscribe(params => {
      this.schemaId = params.id;
      if (!Number(this.schemaId)) this.router.navigate(['/not-found']);
      else {
        this.settingService.getCostSchemaDetail(this.schemaId).then(res => {
          if (res.status) {
            const schema = res.data;
            this.form = fb.group({
              schemaName: [schema.schemaName, Validators.required],
              description: schema.description,
              active: schema.active,
            });
          }
        });
        super.changeData();
      }
    });


  }

  ngOnInit() {
    this.tabs = [
      { id: "ProcessStandard", text: "Process Standard" },
      { id: "SubTypeWeights", text: "Sub-Type Weights" },
      { id: "ApplicationWeights", text: "Application Weights" },
      { id: "MaterialWeights", text: "Material Weights" },
      { id: "MaterialDirection", text: "Material Direction" },
      { id: "LayoutApproval", text: "Layout Approval" },
      { id: "EdgeBase", text: "Edge Base" },
      { id: "EdgeProcess", text: "Edge Process" },
      { id: "EdgeMaterial", text: "Edge Material" },
      { id: "BookmatchWeights", text: "Bookmatch Weights" },
      { id: "TieredVolumeSizing", text: "Tiered Volume Sizing" },
      { id: "ThicknessWeights", text: "Thickness Weights" },
      { id: "SiteAccessDifficulty", text: "Site Access Difficulty" },
      { id: "BuildingLevels", text: "Building Levels" },
      { id: "AdjustOnsite", text: "Adjust Onsite" },
      { id: "OnsitePolish", text: "Onsite Polish" },
      { id: "ApplicationWaste", text: "Waste" },
      { id: 'supplyOverrides', text: 'Supply Overrides' },
      { id: "materialRemove", text: "Material Removal" },
      { id: "ChangeLogSlabCost", text: "Change Log" },
    ];
    this.curTab = this.tabs[0];

    this.settingService.getDefaultChannels(-1, 0, 0).then((response) => {
      if (response.status) {
        this.channels = response.data;
      } else this.toast.error("Can't get list channel");
    });

    this.settingService.getBidSubType().then((response) => {
      if (response.status) this.bidSubTypes = response.data;
      else this.toast.error("Can't get list bid sub-type");
    });

    this.settingService.getMaterialDirectionality().then((response) => {
      if (response.status) this.directionalities = response.data;
      else this.toast.error("Can't get list material directionalities");
    });

    this.settingService.getSlabThickness().then((response) => {
      if (response.status) this.thicknesses = response.data;
      else this.toast.error("Can't get list slab thickness");
    });

    this.settingService.getSiteAccess().then((response) => {
      if (response.status) this.siteAccesses = response.data;
      else this.toast.error("Can't get list site access");
    });

    this.settingService.getBuildingLevel().then((response) => {
      if (response.status) this.buildingLevels = response.data;
      else this.toast.error("Can't get list building level");
    });

    super.ngOnInit();
  }

  get schemaName(): FormControl {
    return this.form.get("schemaName") as FormControl;
  }
  get description(): FormControl {
    return this.form.get("description") as FormControl;
  }
  get active(): FormControl {
    return this.form.get("active") as FormControl;
  }

  save() {
    const schema: any = {};
    schema.schemaId = this.schemaId;
    schema.schemaName = this.schemaName.value;
    schema.description = this.description.value;
    schema.active = this.active.value;
    this.settingService.updateCostSchema(schema).then((res) => {
      if (res.status) {
        this.toast.success("Cost schema has been updated.");
      } else {
        this.toast.error(res.message);
      }
    });
  }
  copy() {
    this.costSchema.copy(this.schemaId).then(res => {
      if (res.status) {
        // Redirect & reload
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/cost-schema-detail/${res.data}`]);
          this.toast.success('Cost Schema has been copied.');
        });
      }
      else this.toast.error('Copy Cost schema has been failed!');
      this.loading = false;
    });
  }
  confirmDelete() {
    if (this.reassignSchemaId > 0) {
      this.modal.close();
      this.costSchema.delete(this.schemaId, this.reassignSchemaId).then(res => {
        this.loading = false;
        if (res.status) {
          this.router.navigate(['/cost-schemas']);
          this.toast.success('Cost Schema has been deleted.');
        }
        else {
          this.toast.error(res.message);
        }
      });
    }
  }
  delete() {
    this.loading = true;
    this.costSchema.isAssignedToChannel(this.schemaId).then(res => {
      if (res.status) {
        if (res.data) {
          this.costSchema.getAllActive().then(resCS => {
            if (resCS.status) {
              this.activeCostSchemas = resCS.data.filter(i => i.schemaId != this.schemaId);
            }
            else {
              this.toast.success('Channel has been load failed.');
            }
          });
          this.modal.open();
        }
        else {
          this.costSchema.delete(this.schemaId, 0).then(response => {
            this.loading = false;
            if (response.status) {
              this.router.navigate(['/cost-schemas']);
              this.toast.success('Cost Schema has been deleted.');
            }
            else {
              this.toast.error(res.message);
            }
          });

        }
      }
      else {
        this.toast.error(res.message);
        this.loading = false;
      }

    });
  }

  submitClearAllComments() {
    this.loading = true;
    this.modalConfirm.close();
    this.costSchema.clearAllComments(this.schemaId).then(res => {
      this.loading = false;
      if (res.status) {
        this.toast.success('All comment has been deleted.');
        this.reloadComponent();
      }
      else {
        this.toast.error(res.message);
      }
    })
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  showSlab(schema: any) {
    this.openDetail = true;
    this.schemaId = schema.schemaId;
    this.form.patchValue({
      schemaName: schema.schemaName,
      description: schema.description,
      active: schema.active,
    });
    super.changeTenant();
  }
  calcHeightTable(id: string) {
    if (this.curTab.id == "ChangeLogSlabCost") {
      setTimeout(() => {
        try {
          let maxHeightOfTable =
            $(window).height() -
            $("#" + id + " .table-responsive tbody").offset().top -
            $("footer").outerHeight() -
            31 -
            64;
          $("#" + id + " .table-responsive tbody").css(
            "max-height",
            maxHeightOfTable
          );
          let widthOfScrollBar =
            $("#" + id + " .table-responsive tbody").width() -
            $("#" + id + " .table-responsive tbody tr").width();
          $("#" + id + " .table-responsive thead").width(
            "calc(100% - " + widthOfScrollBar + "px)"
          );
        } catch { }
      });
    } else {
      super.calcHeightTable(id);
    }
  }
}
