import { Tenant } from './../../../../models/tenant.model';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Config } from 'src/app/config';

@Component({
  selector: 'tab-application',
  templateUrl: './application.html',
  styleUrls: ['./application.scss']
})

export class ApplicationComponent implements OnInit {
  @Input() tenantId: any;
  @Input() unitsOfMeasure: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  application: any;
  txtDefaultSupplyRate: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  applicationId: any;
  ddlUnitOfdMeasure: any;
  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.txtDefaultSupplyRate = new PercentPipe('en-US').transform(0, '.2-2');
    this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getApplications(this.tenantId, Config.BusinessUnitId.Slab).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      applicationId: e.applicationId,
      alias: e.alias,
      description: e.description ,
      active: !!e.active,
      applicationName: [e.applicationName, Validators.required],
      defaultSupplyRate: new DecimalPipe('en-US').transform(e.defaultSupplyRate, '.2-2'),
      unit: e.unit,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      isFrequent: e.isFrequent
    });
  }

  validData() {
    if (this.tenantId == 0) {
      this.toast.error('Please select a tenant');
      return false;
    }
    else if (this.application == null || this.application == '') {
      this.toast.error('Application cannot be empty');
      return false;
    }
    return true;
  }

  addApplication() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addApplications({
        tenantId: this.tenantId,
        applicationName: this.application,
        unitOfMeasure: this.ddlUnitOfdMeasure,
        defaultSupplyRate: this.settingService.replaceMask(this.txtDefaultSupplyRate)
      }).then(response => {
        if (response.status) {
          this.application = '';
          this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
          this.txtDefaultSupplyRate = new PercentPipe('en-US').transform(0, '.2-2');
          this.toast.success('New Application was has been added');
          this.reloadStatusBatchVersions.emit();
          this.loadData();
        }
        else {
          if (response.data == 0) {
            this.toast.error('This application already exists.  System doesn\'t support duplicate applications.  Make appropriate changes and retry.');
          }
          else {
            this.toast.error('The Application is added fail');
          }
          this.loading = false;
        }
      });
    }
  }

  deleteApplication(applicationId) {
    this.modalConfirm.open();
    this.applicationId = applicationId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteApplication(this.applicationId).then(response => {
      if (response.status) {
        this.toast.success('Application has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.applicationId == e.applicationId);
    item.defaultSupplyRate = this.settingService.replaceMask(e.defaultSupplyRate);
    item.applicationName = e.applicationName;
    return {
      applicationId: e.applicationId,
      active: e.active,
      description: e.description,
      alias: e.alias,
      defaultSupplyRate: this.settingService.replaceMask(e.defaultSupplyRate),
      applicationName: e.applicationName,
      isGlobal: false,
      tenantId: this.tenantId,
      isFrequent: e.isFrequent
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    let duplicate = false;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));

        e.markAsPristine();
        e.markAsUntouched();
        duplicate = !!(e.get('applicationName') as FormControl).errors;
      }
      if (e.value.applicationName == null || e.value.applicationName.trim() == "" || e.value.applicationName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Application cannot be empty');
        }
        isValid = false;
      }


    });
    if (isValid && duplicate) {
      this.toast.error('This application already exists. System doesn\'t support duplicate applications. Make appropriate changes and retry ');
      isValid = false;
    }

    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateApplications(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Application has been updated');
          this.loadData();
          if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
        }
        else {
          this.toast.error('Update application failed');
        }
      });
    }
  }

  checkDuplicate(e) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      if (e.applicationId.value != m.value.applicationId && e.applicationName.value.trim().toUpperCase() == m.value.applicationName.trim().toUpperCase()) {
        e.applicationName.setErrors({ 'hasDup': true });

      }

      // if (e.value.edgeType == null || e.value.edgeType.trim() == "" || e.value.edgeType.trim().length == 0) {
      //   if (isValid == true) {
      //     this.toast.error('Edge cannot empty');
      //   }
      //   isValid = false;
      // }
    });


  }
}
