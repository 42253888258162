<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Item Type Default Properties</h4>
  </div>
  <div class="col-xs-12">
    <form [formGroup]="formAdd" (submit)="add()" novalidate>
      <div class="row">
        <div class="col-lg-2 col-sm-4 col-xs-12">
          <div class="form-group" [class.has-error]="itemCategoryId.invalid && (itemCategoryId.dirty || itemCategoryId.touched)">
            <label class="control-label">Item Category</label>
            <select class="form-control" formControlName="itemCategoryId" (change)="filterItemTypes()" required>
              <option *ngFor="let ic of itemCategories" [ngValue]="ic.itemCategoryId">{{ic.itemCategory}}</option>
            </select>
            <span *ngIf="itemCategoryId.invalid && (itemCategoryId.dirty || itemCategoryId.touched)" class="help-block">{{getErrorItemCategoryId()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-xs-12">
          <div class="form-group" [class.has-error]="itemTypeId.invalid && (itemTypeId.dirty || itemTypeId.touched)">
            <label class="control-label">Item Type</label>
            <select class="form-control" formControlName="itemTypeId" (change)="filterItemPropertyTypes()" required>
              <option value=""></option>
              <option *ngFor="let it of itemTypesFilter" [ngValue]="it.itemTypeId">{{it.itemType}}</option>
            </select>
            <span *ngIf="itemTypeId.invalid && (itemTypeId.dirty || itemTypeId.touched)" class="help-block">{{getErrorItemTypeId()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-xs-12">
          <div class="form-group" [class.has-error]="itemPropertyTypeId.invalid && (itemPropertyTypeId.dirty || itemPropertyTypeId.touched)">
            <label class="control-label">New Item Property</label>
            <select class="form-control select2" style="width: 100%" required>
              <option value="">&nbsp;</option>
              <option *ngFor="let ipt of itemPropertyTypesFilter" [value]="ipt.itemPropertyTypeId">{{ipt.itemProperty}}</option>
            </select>
            <span *ngIf="itemPropertyTypeId.invalid && (itemPropertyTypeId.dirty || itemPropertyTypeId.touched)" class="help-block">{{getErrorItemPropertyTypeId()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-xs-12">
          <div class="form-group">
            <label class="control-label">Property Scope</label>
            <select class="form-control" formControlName="propertyScope">
              <option value="1">Required</option>
              <option value="2">Optional</option>
              <option value="3">Available</option>
            </select>
          </div>
        </div>
        <div class="col-lg-1 col-sm-2 col-xs-12" style="margin-top: 25px">
          <button type="submit" class="btn btn-primary btn-block">Add</button>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Item Category</th>
              <th>Item Type</th>
              <th>Item Property</th>
              <th [style.width.px]="140">Property Scope</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <input type="text" class="form-control" formControlName="itemCategory" readonly>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="itemType" readonly>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="itemProperty" readonly>
                  </td>
                  <td [style.width.px]="140">
                    <select class="form-control" formControlName="propertyScope" (change)="save()">
                      <option value="1">Required</option>
                      <option value="2">Optional</option>
                      <option value="3">Available</option>
                    </select>
                  </td>
                  <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" (click)="remove(item.value.itemDefaultId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? This will remove all property data associated to items.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmAddIpt>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    {{curTextNew}} isn't currently a defined property. Would you like to add it?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmAddIpt.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="modalConfirmAddIpt.close();modalAddIpt.open('sm')">OK</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAddIpt>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Add Custom Property</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form [formGroup]="formAddIpt" (submit)="addIpt()" id="ngForm" novalidate>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" [class.has-error]="itemProperty.invalid && (itemProperty.dirty || itemProperty.touched)">
            <label>Property <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="itemProperty" maxlength="50" required>
            <span *ngIf="itemProperty.invalid && (itemProperty.dirty || itemProperty.touched)" class="help-block">{{getErrorItemProperty()}}</span>
          </div>
        </div>
        <div class="col-xs-4">
          <label><input type="checkbox" formControlName="part" /> Part</label>
        </div>
        <div class="col-xs-5">
          <label><input type="checkbox" formControlName="service" /> Service</label>
        </div>
        <div class="col-xs-3">
          <label><input type="checkbox" formControlName="fee" /> Fee</label>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAddIpt.close()">Cancel</button>
    <button type="submit" class="btn btn-primary" form="ngForm">Add</button>
  </bs-modal-footer>
</bs-modal>
