import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-trim-types',
  templateUrl: './global-trim-types.html',
})

export class TileTrimTypesGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  // Form
  trimTypeName: string;
  description: string;
  outputHr: number;
  unitId: any;

  unitOfMeasures: any[];
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  trimTypeId: number;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.getUnitOfMeasures();
    this.loadData();
  }

  private getUnitOfMeasures() {
    this.settingService.getAllUnitsOfMeasure().then(response => {
      if (response.status) {
        this.unitOfMeasures = response.data;
        this.unitId = this.unitOfMeasures[0].unitId;
      }
      else this.toast.error('Can\'t get list units of measure');
    });
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileTrimTypes(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      trimTypeId: e.trimTypeId,
      trimTypeName: [e.trimTypeName, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedOn: e.updatedOn,
      description: e.description,
      unit: e.unit,
      outputHr: e.outputHr,
    });
  }

  validData() {
    if (this.trimTypeName == null || this.trimTypeName == '' || this.trimTypeName.length < 2) {
      this.toast.error('Trim Type name needs to be greater than 2 characters');
      return false;
    }
    if (this.unitId == null || this.unitId == 0) {
      this.toast.error('Unit of Measure is required');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addTileTrimTypes({
        tenantId: 0,
        trimTypeName: this.trimTypeName,
        description: this.description || '',
        unitId: this.unitId,
        outputHr: this.outputHr || 0,
      }).then(response => {
        if (response.status) {
          this.trimTypeName = '';
          this.toast.success('New Trim Type has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The Trim Type is added fail');
        }
      });
    }
  }

  delete(trimTypeId: number) {
    this.modalConfirm.open();
    this.trimTypeId = trimTypeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteTileTrimTypes(this.trimTypeId).then(response => {
      if (response.status) {
        this.toast.success('This Trim Type has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.trimTypeId == e.trimTypeId);
    item.trimTypeName = e.trimTypeName;
    item.active = e.active;
    return {
      trimTypeId: e.trimTypeId,
      trimTypeName: e.trimTypeName,
      active: e.active,
      isGlobal: true,
      description: e.description,
      unit: e.unit,
      outputHr: e.outputHr,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.trimTypeName == null || e.value.trimTypeName.trim() == "" || e.value.trimTypeName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Type name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileTrimTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Trim Type has been updated');
        }
        else this.toast.error('Update Trim Type failed');
      });
    }
  }
}
