import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tax-regions',
  templateUrl: './tax-regions.html',
  styleUrls: ['./tax-regions.scss']
})

export class TaxRegionsComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  formAdd: FormGroup;
  idToRemove: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
    this.formAdd = this.fb.group({
      tenantId: [this.tenantId],
      assignmentName: ['', Validators.required],
      salesTaxRate: ['0.0000%'],
      laborCostRate: ['0.0000%'],
      materialCostRate: ['0.0000%'],
      suppliesCostRate: ['0.0000%'],
      itemCostRate: ['0.0000%']
    });
  }

  get regionName(): FormControl {
    return this.formAdd.get('assignmentName') as FormControl;
  }

  getErrorRegionName() {
    return this.regionName.hasError('required') ? 'Region Name cannot be empty' : '';
  }

  loadData() {
    this.loading = true;
    this.settingService.getTaxRegions(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      taxAssignmentId: e.taxAssignmentId,
      assignmentName: e.assignmentName,
      salesTaxRate: new PercentPipe('en-US').transform(e.salesTaxRate, '.4-4'),
      laborCostRate: new PercentPipe('en-US').transform(e.laborCostRate, '.4-4'),
      materialCostRate: new PercentPipe('en-US').transform(e.materialCostRate, '.4-4'),
      suppliesCostRate: new PercentPipe('en-US').transform(e.suppliesCostRate, '.4-4'),
      itemCostRate: new PercentPipe('en-US').transform(e.itemCostRate, '.4-4'),
      active: e.active,
      editAllowable: e.editAllowable,
      deleteAllowable: e.deleteAllowable,
      updatedOn: e.updatedOn
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.taxAssignmentId == e.taxAssignmentId);
    item.salesTaxRate = this.settingService.replaceMask(e.salesTaxRate) / 100;
    item.laborCostRate = this.settingService.replaceMask(e.laborCostRate) / 100;
    item.materialCostRate = this.settingService.replaceMask(e.materialCostRate) / 100;
    item.suppliesCostRate = this.settingService.replaceMask(e.suppliesCostRate) / 100;
    item.itemCostRate = this.settingService.replaceMask(e.itemCostRate) / 100;
    item.active = e.active;
    return {
      taxAssignmentId: e.taxAssignmentId,
      assignmentName: e.assignmentName,
      salesTaxRate: new DecimalPipe('en-US').transform((this.settingService.replaceMask(e.salesTaxRate) / 100), '.6-6'),
      laborCostRate: new DecimalPipe('en-US').transform((this.settingService.replaceMask(e.laborCostRate) / 100), '.6-6'),
      materialCostRate: new DecimalPipe('en-US').transform((this.settingService.replaceMask(e.materialCostRate) / 100), '.6-6'),
      suppliesCostRate: new DecimalPipe('en-US').transform((this.settingService.replaceMask(e.suppliesCostRate) / 100), '.6-6'),
      itemCostRate: new DecimalPipe('en-US').transform((this.settingService.replaceMask(e.itemCostRate) / 100), '.6-6'),
      active: e.active
    };
  }

  add() {
    this.regionName.markAsDirty();
    this.regionName.markAsTouched();
    if (this.formAdd.valid) {
      const data = {
        tenantId: this.tenantId,
        assignmentName: this.formAdd.get('assignmentName').value,
        salesTaxRate: this.settingService.replaceMask(this.formAdd.get('salesTaxRate').value) / 100,
        laborCostRate: this.settingService.replaceMask(this.formAdd.get('laborCostRate').value) / 100,
        materialCostRate: this.settingService.replaceMask(this.formAdd.get('materialCostRate').value) / 100,
        suppliesCostRate: this.settingService.replaceMask(this.formAdd.get('suppliesCostRate').value) / 100,
        itemCostRate: this.settingService.replaceMask(this.formAdd.get('itemCostRate').value) / 100,
      }
      this.formAdd.disable();
      this.settingService.addTaxRegions(data).then(response => {
        if (response.status) {
          this.formAdd.patchValue({
            tenantId: this.tenantId,
            assignmentName: '',
            salesTaxRate: '0.0000%',
            laborCostRate: '0.0000%',
            materialCostRate: '0.0000%',
            suppliesCostRate: '0.0000%',
            itemCostRate: '0.0000%',
          });
          this.formAdd.markAsPristine();
          this.formAdd.markAsUntouched();
          this.toast.success('Tax region has been added');
          this.loadData();
        }
        else {
          this.toast.error(response.message);
        }
        this.formAdd.enable();
      });
    }
  }

  remove(id) {
    this.modalConfirm.open();
    this.idToRemove = id;
  }

  submitDelete() {
    this.settingService.removeTaxRegions(this.idToRemove).then(response => {
      this.modalConfirm.close();
      if (response.status) {
        this.toast.success('Tax region has been removed');
        this.loadData();
      }
      else this.toast.error(response.message);
    });
  }

  save() {
    let dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateTaxRegions(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Tax regions has been updated');
          if (dataUpdate.length > 0) {
            this.settingService.setBidsIsRefreshByTenant(this.tenantId);
            this.reloadStatusBatchVersions.emit();
          }
        }
        else this.toast.error('Update tax regions failed');
      });
    }
  }
}
