
<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">All Comments</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="table-responsive" >
      <table class="table table-bordered table-hover table-sm">
        <thead>
          <tr>
            <th [style.width.px]="300" class="text-left">Comment</th>
            <th [style.width.px]="150" class="text-left">Created By</th>
            <th [style.width.px]="110" class="text-left">Last Updated</th>
            <th [style.width.px]="80" *ngIf="!isTenantOnly"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="notes?.length < 1"><td class="text-center" style="font-weight: bold;">No data</td></tr>
          <tr *ngFor='let note of notes; let i = index' [ngClass]="i % 2 === 0? 'table-active' : 'table-light'">
            <td [style.width.px]="300">{{note.note}}</td>
            <td [style.width.px]="150" class="text-left">{{note.updatedBy}}</td>
            <td [style.width.px]="110" class="text-left" >{{note.updatedOn | date:'MM/dd/yyyy h:mm a'}} </td>
            <td [style.width.px]="80" class="text-center" *ngIf="!isTenantOnly"><span><a href="javascript:;" (click) ="deleteComment(note.id)"><i class="fa fa-2x fa-trash"></i></a></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
  </bs-modal-footer>
</bs-modal>