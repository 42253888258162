import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-sizes',
  templateUrl: './sizes.html'
})

export class TileSizesComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileSizes(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      sizeId: e.sizeId,
      sizeDescription: [e.sizeDescription, Validators.required],
      active:[e.active],
      isRemove: e.isRemove,
      alias: e.alias,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      frequentlyUsed: [e.frequentlyUsed],
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.sizeId == e.sizeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.sizeDescription = e.sizeDescription;
    item.active = e.active;
    return {
      sizeId: e.sizeId,
      alias: e.alias,
      sizeDescription: e.sizeDescription,
      active: e.active,
      isGlobal: false,
      tenantId: this.tenantId,
      frequentlyUsed: e.frequentlyUsed,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.sizeDescription == null || e.value.sizeDescription.trim() == "" || e.value.sizeDescription.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Size name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileSizes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Size has been updated');
          this.loadData();
        }
        else this.toast.error('Update size failed');
      });
    }
  }
}
