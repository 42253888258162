import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SettingService } from 'src/app/services/setting.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';
import { TenantsService } from 'src/app/services/tenants.service';
import { Config } from 'src/app/config';
import { CalculatorService } from './../../services/calculate.service';
declare var $: any;

@Component({
  selector: 'pricing-recalculation',
  templateUrl: './pricing-recalculation.html',
  styleUrls: ['./pricing-recalculation.scss']
})

export class PricingRecalculationComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirmStart') modalConfirmStart: BsModalComponent;
  @ViewChild('modalConfirmCancel') modalConfirmCancel: BsModalComponent;
  @ViewChild('modalCalculateBids') modalCalculateBids: BsModalComponent;

  modal_title: string;

  tenantCalculates: any;
  tenantIdRecalculating = 0;
  loading = false;
  timezones: any;
  tenants = [];

  form: FormGroup;
  loading_submit: boolean;
  waiting_loading: string;
  error_message = '';
  curTab: any = {};
  loadingTenant = false;
  bids = [];

  paginationPage = 1;
  paginationCount: number;
  tenantId = 0;
  schemas = [];
  schemaPriceId = 0;
  tabs = [
    { id: 'bid_calculate', name: 'Bid Calculating' },
    { id: 'price_calculate', name: 'Pricing Recalculation' }
  ];

  filter: PaginationFilter = {
    orderBy: 'tenantId',
    order: 'desc',
    itemPerPage: 20,
    pageNumber: 1
  };

  constructor(
    private service: TenantsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private settingService: SettingService,
    private userService: UserService,
    private calculatorService: CalculatorService) {
    this.form = this.formBuilder.group({
      tenantId: [0],
      tenantName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      pricingRecalculationLog: ['']
    });
    this.curTab = this.tabs[0];
    this.settingService.getTenant().then(response => {
      if (response.status) {
        this.tenants = response.data;
        if (this.userService.isTenantOnly()) {
          const accessTenants = this.userService.tenants;
          this.tenants = _.filter(this.tenants, (o) => {
            return o.tenantId > 0 && _.indexOf(accessTenants, o.tenantId) !== -1;
          });
        }
      }
      else { this.toastr.error('Can\'t get list tenant'); }
      this.loadingTenant = false;
    });
  }



  loadSchemas() {
    this.schemas = null;
    this.schemaPriceId = 0;
    if (this.tenantId <= 0) { return; }
    this.settingService.getPriceSchemas(this.tenantId, Config.BusinessUnitId.Slab).then(response => {
      if (response.status) { this.schemas = response.data; }
      else { this.toastr.error('Can\'t get list pricing schemas'); }
    });
  }

  changeTab(tab: any) {
    $('.tab-pane.box').removeClass('active');
    this.curTab = tab;
    setTimeout(() => {
      $('#' + this.curTab.id).addClass('active');
      if (this.curTab.id === 'price_calculate') {
        this.getListTenantPricingRecalculation();
      }
      else {

      }
    });
  }

  get tenantName(): FormControl {
    return this.form.get('tenantName') as FormControl;
  }
  get company(): FormControl {
    return this.form.get('company') as FormControl;
  }
  get website(): FormControl {
    return this.form.get('website') as FormControl;
  }
  get phone(): FormControl {
    return this.form.get('phone') as FormControl;
  }
  get timeZoneId(): FormControl {
    return this.form.get('timeZoneId') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    // this.getListTimeZone();
    this.loadSchemas();
  }
  changeSchema() {
    this.getInprogressBids();
  }
  recalculate() {
    this.loading = true;
    this.modalCalculateBids.close();
    this.calculatorService.recalculateBids(this.schemaPriceId, this.tenantId).then(res => {
      this.loading = false;
      if (res.status) {
        this.getInprogressBids();
        this.toastr.success('Open Bids recalculated');
        return;
      }
      this.toastr.error('Open Bids recalculate failed');
    });
  }
  getInprogressBids() {
    this.loading = true;
    this.bids = [];
    this.calculatorService.getInprogressBids(this.schemaPriceId).then(res => {
      if (res.status) {
        this.bids = res.data;
      }
      this.loading = false;
    });
  }

  changeTenant() {
    this.schemaPriceId = 0;
    this.loadSchemas();
  }

  refreshPage() {
    this.getListTenantPricingRecalculation();
  }

  // getListTimeZone() {
  //   this.service.getTimeZone().then(response => {
  //     if (response.status) this.timezones = response.data
  //     else this.toastr.error('Can\'t load list timezone');
  //   });
  // }

  getListTenant() {
    this.tenantCalculates = null;
    this.waiting_loading = 'Loading...';
    this.service.GetListTenant(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.tenantCalculates = response.data.listTenant;
        this.paginationCount = response.data.total;
      } else { this.waiting_loading = 'No data'; }
    });
  }


  getListTenantPricingRecalculation() {
    this.tenantCalculates = null;
    this.waiting_loading = 'Loading...';
    this.service.GetListTenantPricingRecalculation(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.tenantCalculates = response.data.listTenant;
        this.paginationCount = response.data.total;
      } else { this.waiting_loading = 'No data'; }
    });
  }

  // getDisplayTimezone(id) {
  //   if (this.timezones) {
  //     const name = this.timezones.find(t => t.id == id);
  //     if (name) return name.DisplayName;
  //   }
  //   return "";
  // }

  showLog(t) {
    this.modal_title = 'Recalculation Log';
    this.form.reset();
    this.form.setValue({
      tenantId: t.tenantId,
      tenantName: t.tenantName,
      pricingRecalculationLog: t.pricingRecalculationLog,
    });
    this.modal.open();
  }

  recalculatePricing(item) {
    this.tenantIdRecalculating = item.tenantId;
    if (item.pricingRecalculationStatus === 'STARTED'
      || item.pricingRecalculationStatus === 'IN PROGRESS') {
      this.modalConfirmCancel.open();
    }
    else {
      this.modalConfirmStart.open();
    }
  }



  startRecalculation() {
    const status = 'STARTED';
    this.execRecalculate(status);
  }

  cancelRecalculation() {
    const status = 'CANCEL';
    this.execRecalculate(status);
  }


  execRecalculate(status) {
    this.service.UpdatePricingRecalculationStatus(this.tenantIdRecalculating, status).then(response => {
      if (response.status) {
        this.toastr.success(response.message, 'Pricing Calculation process is started successfully');
        this.getListTenantPricingRecalculation();
      }
      else {
        this.toastr.error(response.message, 'Start Pricing Calculation process Error');
      }
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
    });

    this.modalConfirmStart.close();
    this.modalConfirmCancel.close();
  }


  submit() {
    if (this.form.valid) {
      this.loading_submit = true;
      if (this.form.get('tenantId').value > 0) {
        this.service.UpdateTenant(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data) {
            this.toastr.success(response.message, 'Tenant has been updated');
            this.getListTenant();
            this.modal.close();
          }
          else {
            this.tenantName.setErrors({ isExistTenant: true });
            this.error_message = response.message;
          }
        }).catch(() => {
          this.loading_submit = false;
          this.toastr.error('Internal Server Error', 'Error');
        });
      }
      else {
        this.loading_submit = false;
        this.toastr.error('This function is not available now.');
        // this.service.CreateTenant(this.form.value).then(response => {
        //   this.loading_submit = false;
        //   if (response.status && response.data) {
        //     this.toastr.success(response.message, 'Create new tenant successfully');
        //     this.getListTenant();
        //     this.modal.close();
        //   }
        //   else {
        //     this.tenantName.setErrors({ 'isExistTenant': true });
        //     this.error_message = response.message;
        //   }
        // }).catch(() => {
        //   this.loading_submit = false;
        //   this.toastr.error('Internal Server Error', 'Error');
        // });
      }
    }
  }

  changeFilter(orderBy: string) {
    if (!orderBy) {
      this.filter.orderBy = 'tenantName';
      this.filter.order = 'desc';
    } else if (orderBy === this.filter.orderBy) {
      if (this.filter.order === 'desc') { this.filter.order = 'asc'; } else { this.filter.order = 'desc'; }
    } else {
      this.filter.orderBy = orderBy;
      this.filter.order = 'desc';
    }

    this.getListTenantPricingRecalculation();
  }



  goToPage(n: number): void {
    if (this.filter.pageNumber === n) { return; }
    this.filter.pageNumber = n;
    this.getListTenantPricingRecalculation();
  }

  onNext(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getListTenantPricingRecalculation();
  }

  onPrev(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getListTenantPricingRecalculation();
  }
}

export class PaginationFilter {
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
}
