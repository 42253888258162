<div class="content-wrapper">
  <section class="content-header">
    <h1>License Types</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/license-types"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">License Types</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="panel-group" id="acc" role="tablist" aria-multiselectable="true">
          <div *ngFor="let module of modules" class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingAdd">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#acc"
                  href="#collapse_{{module.moduleId}}" aria-expanded="false"
                  aria-controls="collapse_{{module.moduleId}}" (click)="init(module)">
                  Module: {{module.moduleName}}
                </a>
              </h4>
            </div>
            <div id="collapse_{{module.moduleId}}" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="headingAdd">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xs-12">
                    <form [formGroup]="addLicenseForm" novalidate>
                      <div class="row">
                        <div class="col-md-3 col-xs-12">
                          <div class="form-group"
                            [class.has-error]="licenseType.invalid && (licenseType.dirty || licenseType.touched)">
                            <label class="control-label">License Type</label>
                            <input type="text" trim="blur" class="form-control" placeholder="License Type"
                              formControlName="licenseType" maxlength="50" required>
                            <span *ngIf="licenseType.invalid && (licenseType.dirty || licenseType.touched)"
                              class="help-block">{{getErrorLicenseName()}}&nbsp;</span>
                          </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <div class="form-group">
                            <label class="control-label">Description</label>
                            <input type="text" trim="blur" class="form-control" placeholder="Description"
                              formControlName="description" maxlength="250">
                          </div>
                        </div>
                        <div class="col-md-5 col-xs-12">
                          <button [disabled]="licenseType.invalid" type="button" (click)="addNewLicenseType()"
                            class="btn btn-primary" style="margin-top: 24px">Add Type</button>
                        </div>
                      </div>
                    </form>
                    <table class="table table-bordered table-hover table-sm">
                      <thead>
                        <tr>
                          <th [style.width.px]="300">License Type</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="loading" class="text-center w-100">
                          <td colspan="2"><i class="fa fa-refresh fa-spin fa-lg"></i></td>
                        </tr>
                        <tr colspan="2" class="text-center" *ngIf="!loading && !(moduleLicenseTypes?.length > 0)">
                          <td colspan="2"> No License Types</td>
                        </tr>
                        <tr *ngFor="let t of moduleLicenseTypes">
                          <td [style.width.px]="300">{{t.licenseType}}</td>
                          <td>{{t.description}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
