import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { AddressService } from 'src/app/services/address.service';
import { LocationAddressesService } from '../location-addresses.service';

@Component({
  selector: 'location-addresses',
  templateUrl: './location-addresses.html'
})

export class LocationAddressesComponent implements OnInit {

  @Input() currentRow: any;
  @Input() states: any[];

  addresses: any[];

  // Modal Address
  loadingAddress = false;
  @ViewChild('modalAddress') modalAddress: BsModalComponent;
  formEditAddress: FormGroup;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private locationAddressesService: LocationAddressesService
  ) {

    this.formEditAddress = this.formBuilder.group({
      id: [0],
      locationId: [0],
      addressType: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      active: [true]
    });
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.addressService.getAddressesByLocationId(this.currentRow.locationId).then(response => {
      if (response.status) {
        this.addresses = response.data;
      } else {
        this.toastr.error('Load data failed');
      }
    });
  }

  // Modal Address
  get addressId(): FormControl {
    return this.formEditAddress.get('id') as FormControl;
  }
  get addressType(): FormControl {
    return this.formEditAddress.get('addressType') as FormControl;
  }
  get address1(): FormControl {
    return this.formEditAddress.get('address1') as FormControl;
  }
  get address2(): FormControl {
    return this.formEditAddress.get('address2') as FormControl;
  }
  get city(): FormControl {
    return this.formEditAddress.get('city') as FormControl;
  }
  get state(): FormControl {
    return this.formEditAddress.get('state') as FormControl;
  }
  get postalCode(): FormControl {
    return this.formEditAddress.get('postalCode') as FormControl;
  }

  openPopupAddress(item) {
    this.formEditAddress.reset();
    this.formEditAddress.patchValue({
      id: item.id,
      locationId: item.locationId,
      addressType: item.addressType,
      address1: item.address1,
      address2: item.address2,
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      active: item.active,
    });
    this.modalAddress.open();
  }

  submitAddress() {
    this.updateAddress(this.formEditAddress.value, false);
  }

  updateAddress(item, onlyActive: boolean) {
    if (onlyActive) {
      item.active = !item.active;
      this.formEditAddress.patchValue({
        active: item.active
      });
      this.addressService.updateAddress(item.id, this.formEditAddress.value).then(response => {
        if (response.status) {
          this.toastr.success(response.message);
        }
        else {
          item.active = !item.active;
          this.toastr.error(response.message);
        }
      });
    }
    else {
      this.loadingAddress = true;
      this.addressService.updateAddress(this.addressId.value, this.formEditAddress.value).then(response => {
        this.loadingAddress = false;
        if (response.status) {
          this.loadData();
          this.modalAddress.close();
          this.toastr.success(response.message);
        }
        else {
          this.toastr.error(response.message);
        }
      });
    }
  }

  deleteAddress(id: number) {
    this.addressService.deleteAddress(id).then(response => {
      this.loadingAddress = false;
      if (response.status) {
        this.loadData();
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    });
  }
}
