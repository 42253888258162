import { LicenseTypeService } from '../../services/license-type.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ObjectPermission, UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SettingService } from 'src/app/services/setting.service';
declare var $: any;

@Component({
  templateUrl: './global-roles.html',
  styleUrls: ['./global-roles.scss']
})

export class GlobalRolesComponent implements OnInit {
  @ViewChild('modalDelete') modalDelete: BsModalComponent;
  loading = false;
  roles: any;
  rolesIdDelete: any;
  permissions: any;
  pageCount = 0;
  filter = {
    pageSize: 20,
    pageNo: 1,
    roleId: 0,
  };
  tabs = [];
  roleTabs = [];
  users = [];
  curTab: any = {};
  addRoleForm: FormGroup;
  editRoleForm: FormGroup;
  dataAddPermissions: any;
  checkAllViewAdd = false;
  checkAllAddAdd = false;
  checkAllEditAdd = false;
  checkAllDeleteAdd = false;
  settings = {};
  docTypeList = [];
  docTypeSelected = [];
  cmtTypeList = [];
  cmtTypeSelected = [];
  roleUnassign = 0;
  userUnassign = 0;
  loadingUser = false;
  checkAllDocType = false;
  checkAllCommentType = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private permissionService: PermissionService) {
    this.addRoleForm = this.fb.group({
      roleName: ['', Validators.required],
      roleDescription: [''],
      estimator: [false],
      qaTester: [false],
      qxmOnly: [false],
      permissions: [null],
      documentTypeIds: [[]],
      commentTypeIds: [[]],
    });

    this.settings = {
      text: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
    };

    this.tabs = [
      { id: 'app_permissions', name: 'Application Permissions' },
      { id: 'users', name: 'Assigned Users' },
      { id: 'accesses', name: 'Data Access' },
      { id: 'sys_permissions', name: 'System Permissions' }];
  }

  unAssigned() {
    this.loadingUser = true;
    this.userService.unassignRole(this.userUnassign, this.roleUnassign).then((res) => {
      if (!res.status) {
        this.toastr.error('Can\'t unassign the user with this role');
        return;
      }
      this.getAllUsers();
    }).finally(() => this.loadingUser = false);
  }

  goToPage(n: number): void {
    if (this.filter.pageNo === n) {
      return;
    }
    this.filter.pageNo = n;
    this.getAllUsers();
  }

  onNext(): void {
    this.filter.pageNo = +this.filter.pageNo;
    this.filter.pageNo++;
    this.getAllUsers();
  }

  onPrev(): void {
    this.filter.pageNo = +this.filter.pageNo;
    this.filter.pageNo--;
    this.getAllUsers();
  }
  getAllUsers() {
    this.users = [];
    this.userService.getUsersByGlobalRole(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.users = response.data.users;
        this.pageCount = response.data.total;
      } else {

      }
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
    });
  }
  changeTab(tab: any) {
    $('.tab-pane.box').removeClass('active');
    this.curTab = tab;
    setTimeout(() => {
      $('#' + this.curTab.id).addClass('active');
      if (this.curTab.id == 'users') {
        this.getAllUsers();
      }
    });
  }


  get roleName(): FormControl {
    return this.addRoleForm.get('roleName') as FormControl;
  }
  getErrorRoleName() {
    return this.roleName.hasError('required') ? 'Role name is required' : '';
  }

  initSelect2() {
    // $('.doctype').select2();
    // $('.cmttype').select2();

  }
  check() {
    this.checkAllDocType = !this.docTypeList?.find(i => !i.checked);
    this.checkAllCommentType = !this.cmtTypeList?.find(i => !i.checked);
  }

  initEditSelect2(role) {
    this.cmtTypeList?.forEach(e => {
      e.checked = role.commentTypeIds?.find((i: number) => i === Number(e.id));
    });
    this.docTypeList?.forEach(e => {
      e.checked = role.documentTypeIds?.find((i: number) => i === Number(e.id));
    });
    this.check();
    this.filter.roleId = role.roleId;
    console.log('init', role)
    if (role.isGlobal) {
      this.curTab = this.tabs[0];
      this.roleTabs = this.tabs || [];
      return;
    }
    this.roleTabs = this.tabs.filter(x => x.id === 'users');
    this.curTab = this.roleTabs[0];
    this.getAllUsers();
  }

  ngOnInit() {
    $('body').layout('fix');

    this.getRoles();

    this.permissionService.getAllDocumentType().then(res => {
      if (res.status) {
        // this.docTypes = res.data;
        this.docTypeList = res.data.map((d) => ({ id: d.documentTypeId, itemName: d.documentTypeName }));
      }
      else { this.toastr.error('Load document types failed'); }
    });

    this.permissionService.getListCommentTypes().then(res => {
      if (res.status) {
        // this.cmtTypes = res.data;
        this.cmtTypeList = res.data.map((c) => ({ id: c.commentTypeId, itemName: c.name }));
      }
      else { this.toastr.error('Load comment types failed'); }
    });

    this.loadDefaultPermissions();
  }

  private loadDefaultPermissions() {
    this.permissionService.getGlobalPermissions().then(response => {
      if (response.status) {
        this.permissions = response.data;
        this.renewDataAddPermission();
      }
      else {
        this.toastr.error('Load permissions failed');
      }
    });
  }
  checkAll(tab: string, value: boolean) {
    switch (tab) {
      case 'doc': this.docTypeList.forEach(e => e.checked = value); break;
      case 'cmt': this.cmtTypeList.forEach(e => e.checked = value); break;
    }
  }

  getRoles() {
    this.loading = true;
    this.permissionService.getGlobalRoles().then(response => {
      if (response.status) {
        this.roles = response.data;

        this.roles.forEach(r => {
          const permissions = r.permissions || [];
          permissions.forEach(e => {
            switch (e.permissionId) {
              case ObjectPermission.OthersAccount:
              case ObjectPermission.OthersProject:
              case ObjectPermission.OthersBid:
              case ObjectPermission.OthersQuote:
              case ObjectPermission.OtherOpportunities:
                e = Object.assign(e, { hideAdd: true }); break;
              case ObjectPermission.AccountPricingSchema:
              case ObjectPermission.AccountTransferOwnership:
                e = Object.assign(e, { hideAdd: true, hideDelete: true }); break;
              case ObjectPermission.BidMaterial:
                e.allowView = true;
                e = Object.assign(e, { hideView: true, hideDelete: true, hideEdit: true }); break;
              case ObjectPermission.BidPriceSchemaAssignment:
              case ObjectPermission.CommunityMaterialGroupChange:
              case ObjectPermission.BidSlabGroup:
              case ObjectPermission.BidItemGroup:
              case ObjectPermission.ContractOwnership:
              case ObjectPermission.BidPriceOverride:
              case ObjectPermission.ChangeProjectChannel:
              case ObjectPermission.ModifyDirectAccountCustomerAssignment:
              case ObjectPermission.ExcludeApplicationFromBidLevelCharges:
              case ObjectPermission.MaterialGroupPricing:
              case ObjectPermission.ItemGroupPricing:
              case ObjectPermission.EdgeGroupPricing:
              case ObjectPermission.EditMaterialGroupCost:
              case ObjectPermission.EditItemGroupCost:
              case ObjectPermission.EditEdgeGroupCost:
              case ObjectPermission.EditTileGroupCost:
              case ObjectPermission.Estimator:
                e.allowView = true;
                e = Object.assign(e, { hideAdd: true, hideView: true, hideDelete: true }); break;
              case ObjectPermission.DimensionAdjustment:
              case ObjectPermission.Dashboard:
              case ObjectPermission.ApplicationLineBudgetDetails:
              case ObjectPermission.ViewOtherEstimators:
                e = Object.assign(e, { hideAdd: true, hideEdit: true, hideDelete: true }); break;
              case ObjectPermission.AdminQuoteDeletion:
              case ObjectPermission.BulkContactDeletion:
                e.allowView = true;
                e = Object.assign(e, { hideAdd: true, hideView: true, hideEdit: true }); break;
              case ObjectPermission.CommunityPricing:
              case ObjectPermission.AdvancedApplicationPricing:
                e = Object.assign(e, { hideAdd: true, hideDelete: true }); break;
            }

            // // For permission estimator
            // switch (e.permissionId) {
            //   case ObjectPermission.Bid:
            //   case ObjectPermission.Quote:
            //   case ObjectPermission.Contract:
            //     if (!r.estimator) { e = Object.assign(e, { disabledView: true }); }
            //     break;
            // }
          });
          r.permissions = permissions;
        });

        this.loading = false;
      } else {
        this.toastr.error('Load roles failed');
      }
    });
  }

  renewDataAddPermission() {
    const data = [];
    this.permissions.forEach(e => {
      let per = {
        permissionName: e.permissionName,
        description: e.description,
        permissionId: e.permissionId
      };
      switch (e.permissionId) {
        case ObjectPermission.OthersAccount:
        case ObjectPermission.OthersProject:
        case ObjectPermission.OthersBid:
        case ObjectPermission.OthersQuote:
        case ObjectPermission.OtherOpportunities:
          per = Object.assign(per, { hideAdd: true }); break;
        case ObjectPermission.AccountPricingSchema:
        case ObjectPermission.AccountTransferOwnership:
          per = Object.assign(per, { hideAdd: true, hideDelete: true }); break;

        case ObjectPermission.BidMaterial:
          per = Object.assign(per, { hideView: true, hideDelete: true, hideEdit: true, allowView: true }); break;
        case ObjectPermission.BidPriceSchemaAssignment:
        case ObjectPermission.CommunityMaterialGroupChange:
        case ObjectPermission.BidSlabGroup:
        case ObjectPermission.BidItemGroup:
        case ObjectPermission.ContractOwnership:
        case ObjectPermission.BidPriceOverride:
        case ObjectPermission.ChangeProjectChannel:
        case ObjectPermission.ModifyDirectAccountCustomerAssignment:
        case ObjectPermission.ExcludeApplicationFromBidLevelCharges:
        case ObjectPermission.MaterialGroupPricing:
        case ObjectPermission.ItemGroupPricing:
        case ObjectPermission.EdgeGroupPricing:
        case ObjectPermission.EditMaterialGroupCost:
        case ObjectPermission.EditItemGroupCost:
        case ObjectPermission.EditEdgeGroupCost:
        case ObjectPermission.EditTileGroupCost:
        case ObjectPermission.Estimator:
          per = Object.assign(per, { hideView: true, hideDelete: true, hideAdd: true, allowView: true }); break;
        case ObjectPermission.DimensionAdjustment:
        case ObjectPermission.Dashboard:
        case ObjectPermission.ApplicationLineBudgetDetails:
        case ObjectPermission.ViewOtherEstimators:
        case ObjectPermission.Budget:
          per = Object.assign(per, { hideAdd: true, hideEdit: true, hideDelete: true, allowView: true }); break;
        case ObjectPermission.AdminQuoteDeletion:
        case ObjectPermission.BulkContactDeletion:
        case ObjectPermission.WorkOrders:
          per = Object.assign(per, { hideView: true, hideEdit: true, hideAdd: true, allowView: true }); break;
        case ObjectPermission.CommunityPricing:
        case ObjectPermission.AdvancedApplicationPricing:
          per = Object.assign(per, { hideAdd: true, hideDelete: true }); break;
        case ObjectPermission.Appointments:
          per = Object.assign(per, { hideView: true, allowView: true }); break;
        case ObjectPermission.Planning:
        case ObjectPermission.Execution:
          per = Object.assign(per, { hideAdd: true, hideDelete: true }); break;
        case ObjectPermission.Scheduling:
          per = Object.assign(per, { hideDelete: true }); break;
      }

      // // For permission estimator
      // switch (e.permissionId) {
      //   case ObjectPermission.Bid:
      //   case ObjectPermission.Quote:
      //   case ObjectPermission.Contract:
      //     if (!this.estimator.value) { per = Object.assign(per, { disabledView: true }); }
      //     break;
      // }

      per = Object.assign(per, {
        allowAdd: false,
        allowEdit: false,
        // allowView: false,
        allowDelete: false
      });
      data.push(per);
    });
    this.dataAddPermissions = data;

    this.addRoleForm.patchValue({ permissions: data });

    this.checkAllViewAdd = false;
    this.checkAllAddAdd = false;
    this.checkAllEditAdd = false;
    this.checkAllDeleteAdd = false;
  }

  // changeCheckEstimatorAdd() {
  //   this.dataAddPermissions.forEach(e => {
  //     switch (e.permissionId) {
  //       case ObjectPermission.Bid:
  //       case ObjectPermission.Quote:
  //       case ObjectPermission.Contract:
  //         if (!this.estimator.value) {
  //           e.disabledView = true;
  //           e.allowView = false;
  //           e.allowAdd = false;
  //           e.allowEdit = false;
  //           e.allowDelete = false;
  //         }
  //         else { delete e.disabledView; }
  //     }
  //   });
  //   this.addRoleForm.patchValue({ permissions: this.dataAddPermissions });
  // }

  viewCheckChange(e, per) {
    if (!e) {
      per.allowAdd = false;
      per.allowEdit = false;
      per.allowDelete = false;
    }
  }

  addRole() {
    this.roleName.markAsDirty();
    this.roleName.markAsTouched();
    if (this.addRoleForm.valid) {
      this.addRoleForm.patchValue({
        documentTypeIds: this.docTypeSelected.map(x => Number(x.id)) || [],
        commentTypeIds: this.cmtTypeSelected.map(x => Number(x.id)) || []
      });

      this.permissionService.addGlobalRoleWithPermissions(this.addRoleForm.value).then(response => {
        if (response.status) {
          this.getRoles();
          this.addRoleForm.reset();
          this.renewDataAddPermission();
          this.toastr.success('Role has been added');
        }
        else { response.message ? this.toastr.error(response.message) : this.toastr.error('Add role failed'); }
      });
    }
  }

  // changeCheckEstimatorEdit(role) {
  //   role.permissions.forEach(e => {
  //     switch (e.permissionId) {
  //       case ObjectPermission.Bid:
  //       case ObjectPermission.Quote:
  //       case ObjectPermission.Contract:
  //         if (!role.estimator) {
  //           e.disabledView = true;
  //           e.allowView = false;
  //           e.allowAdd = false;
  //           e.allowEdit = false;
  //           e.allowDelete = false;
  //         }
  //         else { delete e.disabledView; }
  //     }
  //   });
  // }

  editRole(role: any) {
    const documentTypeIds = this.docTypeList.filter(i => i.checked).map(i => Number(i.id)) || [];
    const commentTypeIds = this.cmtTypeList.filter(i => i.checked).map(i => Number(i.id)) || [];

    this.editRoleForm = this.fb.group({
      roleId: [role.roleId],
      roleName: [role.roleName, Validators.required],
      roleDescription: [role.roleDescription],
      estimator: [role.estimator],
      qaTester: [role.qaTester],
      qxmOnly: [role.qxmOnly],
      permissions: [role.permissions],
      documentTypeIds: [documentTypeIds],
      commentTypeIds: [commentTypeIds]
    });

    if (this.editRoleForm.valid) {
      this.permissionService.editGlobalRoleWithPermissions(this.editRoleForm.value).then(response => {
        if (response.status) {
          this.toastr.success('Role has been updated');
        } else {
          response.message ? this.toastr.error(response.message) : this.toastr.error('Edit role failed');
        }
      });
    }
  }

  deleteRole() {
    this.permissionService.deleteRole(this.rolesIdDelete).then(response => {
      if (response.status) {
        this.getRoles();
        this.toastr.success('Role has been removed');
      } else {
        this.toastr.error(response.message);
      }
      this.modalDelete.close();
    });
  }

  viewAll(role = null) {
    if (role == null) {
      this.doCheckAllWhenAdd('VIEW', this.checkAllViewAdd);
      if (!this.checkAllViewAdd) {
        this.checkAllAddAdd = false;
        this.checkAllEditAdd = false;
        this.checkAllDeleteAdd = false;
      }
    } else {
      this.doCheckAllWhenEdit('VIEW', role.checkAllViewEdit, role);
      if (!role.checkAllViewEdit) {
        role.checkAllAddEdit = false;
        role.checkAllEditEdit = false;
        role.checkAllDeleteEdit = false;
      }
    }
  }

  addAll(role = null) {
    if (role == null) { this.doCheckAllWhenAdd('ADD', this.checkAllAddAdd); }
    else { this.doCheckAllWhenEdit('ADD', role.checkAllAddEdit, role); }
  }

  editAll(role = null) {
    if (role == null) { this.doCheckAllWhenAdd('EDIT', this.checkAllEditAdd); }
    else { this.doCheckAllWhenEdit('EDIT', role.checkAllEditEdit, role); }
  }

  deleteAll(role = null) {
    if (role == null) { this.doCheckAllWhenAdd('DELETE', this.checkAllDeleteAdd); }
    else { this.doCheckAllWhenEdit('DELETE', role.checkAllDeleteEdit, role); }
  }

  private doCheckAllWhenAdd(type, check) {
    this.dataAddPermissions.forEach(this.doCheckAll(type, check));
    this.addRoleForm.patchValue({ permissions: this.dataAddPermissions });
  }

  private doCheckAllWhenEdit(type, check, role) {
    role.permissions.forEach(this.doCheckAll(type, check));
  }

  private doCheckAll(type: any, check: any): any {
    return e => {
      switch (type) {
        case 'VIEW':
          if (!e.hideView && !e.disabledView) {
            e.allowView = check;
            this.viewCheckChange(check, e);
          }
          break;
        case 'ADD':
          if (!e.hideAdd && e.allowView) {
            e.allowAdd = check;
          }
          break;
        case 'EDIT':
          if (!e.hideEdit && e.allowView) {
            e.allowEdit = check;
          }
          break;
        case 'DELETE':
          if (!e.hideDelete && e.allowView) {
            e.allowDelete = check;
          }
          break;
      }
    };
  }
}
