<aside class="main-sidebar">
  <section class="sidebar">
    <div class="user-panel" style="padding: 5px 0">
      <!-- <div class="pull-left image">
        <img src="assets/dist/img/user2-160x160.jpg" class="img-circle" alt="User Image">
      </div>
      <div class="pull-left info">
        <p>Admin</p>
        <i class="fa fa-circle text-success"></i> Online
      </div> -->
    </div>

    <ul class="sidebar-menu" data-widget="tree">
      <li class="treeview" *ngFor="let item of listMenu">
        <a *ngIf="!!item.subMenu && item.subMenu.length > 0" style="cursor: pointer;">
          <i class="fa {{item.icon}}"></i>
          <span>{{item.name}}</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul *ngIf="!!item.subMenu && item.subMenu.length > 0" class="treeview-menu">
          <li *ngFor="let e of item.subMenu">
            <a routerLink="{{e.link}}">
              <i class="fa {{e.icon}}"></i>{{e.name}}
            </a>
          </li>
        </ul>
        <a *ngIf="!item.subMenu" routerLink="{{item.link}}">
          <i class="fa {{item.icon}}"></i>
          <span>{{item.name}}</span>
        </a>
      </li>
    </ul>
  </section>
</aside>
