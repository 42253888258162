import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class CalculatorService {

  private controller = 'Calculators';

  constructor(private http: AuthHttp) { }

  recalculateBids(priceSchemaId: number, tenantId: number) {
    return this.http.post(`${this.controller}/RecalculateBids/${priceSchemaId}/${tenantId}`).toPromise();
  }
  getInprogressBids(priceSchemaId: number) {
    return this.http.get(`${this.controller}/GetInProgressBids/${priceSchemaId}`).toPromise();
  }
}
