import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonPricingComponent } from '../common-pricing';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { UserService } from '../../../services/user.service';
import { TenantChannelComponent } from './tenant-channels/tenant-channels';
import { TenantChannelsService } from './tenant-channels/tenant-channels.service';
import { TileTenantChannelsService } from '../tile/tenant-channels/tenant-channels/tenant-channels.service';

@Component({
  templateUrl: './tenant-channel-container.html',
})

export class TenantChannelContainerComponent extends CommonPricingComponent implements OnInit {

  @ViewChild(TenantChannelComponent) tenantChannel: TenantChannelComponent;
  enabledEdit = false;

  constructor(
    fb: FormBuilder,
    toast: ToastrService,
    settingService: SettingService,
    userService: UserService,
    cdr: ChangeDetectorRef,
    public tenantChannelsService: TenantChannelsService,
    public tileTenantChannelsService: TileTenantChannelsService) {
    super(fb, toast, settingService, userService, cdr);
  }

  ngOnInit() {
    this.tabs = [
      { id: 'tenantChannels', text: 'Channels' },
      { id: 'changeLog', text: 'Change Log' },
    ];
    this.curTab = this.tabs[0];

    super.ngOnInit();
  }

  apply() {
    if (this.enabledEdit) {
      this.tenantChannel.save(true);
    }
    this.enabledEdit = !this.enabledEdit;
    this.tenantChannel.setEnableForm(this.enabledEdit);
  }

  changeTenant2() {
    this.enabledEdit = false;
    this.tenantChannelsService.clear();
    this.tileTenantChannelsService.clear();
    this.changeTenant();
  }
}
