import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
declare var $: any;

@Component({
  selector: 'page-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})

export class HomeComponent {
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  constructor(router: Router, userService: UserService) {
    this.body.classList.add('skin-blue');
    this.body.classList.add('sidebar-mini');
    if (!userService.checkAuthorization()) router.navigate(['login']);
  }
  ngOnInit() {
    $('body').layout('fix');
  }
}