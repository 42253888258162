<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Base Process Configuration</h4>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <fieldset class="filter-type">
      <legend>Process Type Filter</legend>
      <div>
        <label class="radio-inline">
          <input type="radio" name="processTypeId" [value]="2" [(ngModel)]="processTypeId" (change)="filterData()"> Fabrication
        </label>
        <label class="radio-inline">
          <input type="radio" name="processTypeId" [value]="3" [(ngModel)]="processTypeId" (change)="filterData()"> Install
        </label>
      </div>
    </fieldset>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">Standard Slab Processes</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Process</th>
              <th [style.width.px]="100">Unit Type</th>
              <th [style.width.px]="130">Std Hourly Rate</th>
              <th>Process Description</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.processName}}</td>
                  <td [style.width.px]="100">{{item.value.unit}}</td>
                  <td [style.width.px]="130">
                    <input type="text" class="form-control text-right" prefix="$" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" [allowNegativeNumbers]="true" formControlName="stdHourlyRate" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="description" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
