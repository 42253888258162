import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-sizes',
  templateUrl: './global-sizes.html',
})

export class TileSizesGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  unitsOfMeasure: any[];

  // Form
  sizeDescription: string;
  width: number = 0;
  length: number = 0;
  unitId: number

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  sizeId: number;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.getUnitOfMeasures();
    this.loadData();
  }

  private getUnitOfMeasures() {
    this.settingService.getAllUnitsOfMeasure().then(response => {
      if (response.status) {
        this.unitsOfMeasure = response.data;
        this.unitId = this.unitsOfMeasure[0].unitId;
      }
      else this.toast.error('Can\'t get list units of measure');
    });
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileSizes(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      sizeId: e.sizeId,
      sizeDescription: [e.sizeDescription, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedOn: e.updatedOn,
      width: e.width,
      length: e.length,
      totalSqFt: e.totalSqFt,
      unit: e.unit,
    });
  }

  validData() {
    if (this.sizeDescription == null || this.sizeDescription == '' || this.sizeDescription.length < 2) {
      this.toast.error('Size description needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addTileSizes({
        tenantId: 0,
        sizeDescription: this.sizeDescription,
        width: this.width,
        length: this.length,
        unitId: this.unitId,
      }).then(response => {
        if (response.status) {
          this.unitId = this.unitsOfMeasure[0].unitId;
          this.width = 0;
          this.length = 0;
          this.sizeDescription = '';
          this.toast.success('New Size has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The size is added fail');
        }
      });
    }
  }

  delete(sizeId) {
    this.modalConfirm.open();
    this.sizeId = sizeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteTileSizes(this.sizeId).then(response => {
      if (response.status) {
        this.toast.success('This size has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.sizeId == e.sizeId);
    item.sizeDescription = e.sizeDescription;
    item.active = e.active;
    return {
      sizeId: e.sizeId,
      sizeDescription: e.sizeDescription,
      active: e.active,
      isGlobal: true,
      width: e.width,
      length: e.length,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.sizeDescription == null || e.value.sizeDescription.trim() == "" || e.value.sizeDescription.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Size name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileSizes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Size has been updated');
          this.loadData();
        }
        else this.toast.error('Update size failed');
      });
    }
  }
}
