export enum RouteDictionary {
  Login = 'login',
  Home = 'home',
  Tenants = 'tenants',
  TenantDetails = 'tenant-detail/:id',
  NewTenant = 'new-tenant',
  Roles = 'roles',
  RoleDetail = 'role-detail/:id',
  GlobalRoles = 'global-roles',
  Permissions = 'permissions',
  Regions = 'regions',
  LicenseTypes = 'license-types',
  ServiceLocations = 'service-regions',
  SlabCost = 'slab-cost',
  CostSchemas = 'cost-schemas',
  SlabCostDetail = 'cost-schema-detail/:id',
  SlabPricing = 'slab-pricing',
  SlabConfiguration = 'slab-configuration',
  GlobalConfiguration = 'global-configuration',
  TenantChannels = 'tenant-channels',
  Users = 'users',
  UserDetails = 'user-detail/:id',
  Reports = 'reports',
  Dashboards = 'dashboards',
  PricingRecalculation = 'pricing-recalculation',
  CompareTool = 'compare-tool',
  LogOfBatch = 'log-of-batch/:id',
  // Tile
  TileCostSchemas = 'tile-cost-schemas',
  TileTenantSpecificConfiguration = 'tile-tenant-specific-configuration',
  TileGlobalConfiguration = 'tile-global-configuration',
  TileCostSchemaDetail = 'tile-cost-schema-detail/:id',
  TileTenantChannels = 'tile-tenant-channels',
}
