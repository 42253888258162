<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Item Types</h4>
  </div>
  <div class="col-xs-12">
    <form [formGroup]="formAdd" (submit)="add()" novalidate>
      <div class="row">
        <div class="col-lg-2 col-sm-4 col-xs-12">
          <div class="form-group" [class.has-error]="itemCategoryId.invalid && (itemCategoryId.dirty || itemCategoryId.touched)">
            <label class="control-label">Item Category</label>
            <select class="form-control" formControlName="itemCategoryId" (change)="changeItemCategory()" required>
              <option *ngFor="let ic of itemCategories" [ngValue]="ic.itemCategoryId">{{ic.itemCategory}}</option>
            </select>
            <span *ngIf="itemCategoryId.invalid && (itemCategoryId.dirty || itemCategoryId.touched)" class="help-block">{{getErrorItemCategoryId()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-xs-12">
          <div class="form-group" [class.has-error]="itemType.invalid && (itemType.dirty || itemType.touched)">
            <label class="control-label">Item Type</label>
            <input type="text" trim="blur" class="form-control" formControlName="itemType" maxlength="200" required>
            <span *ngIf="itemType.invalid && (itemType.dirty || itemType.touched)" class="help-block">{{getErrorItemType()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4 col-xs-12">
          <div class="form-group">
            <label class="control-label">Item Description</label>
            <input type="text" trim="blur" class="form-control" formControlName="typeDescription" maxlength="200">
          </div>
        </div>
        <div class="col-lg-5 col-sm-10 col-xs-12">
          <div class="row">
            <div class="col-sm-4 col-xs-12 text-center">
              <label class="control-label" style="display: block">Charge Overhead</label>
              <input type="checkbox" formControlName="defaultChargeInstallOverhead">
            </div>
            <div class="col-sm-4 col-xs-12 text-center">
              <label class="control-label" style="display: block">Charge SGNA</label>
              <input type="checkbox" formControlName="defaultChargeSgna">
            </div>
            <div class="col-sm-4 col-xs-12 text-center">
              <label class="control-label" style="display: block">Charge Profit</label>
              <input type="checkbox" formControlName="defaultChargeProfit">
            </div>
          </div>
        </div>
        <div class="col-lg-1 col-sm-2 col-xs-12" style="margin-top: 25px">
          <button type="submit" class="btn btn-primary btn-block">Add</button>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Item Category</th>
              <th>Item Type</th>
              <th>Item Description</th>
              <th [style.width.px]="140" class="text-center">Charge Overhead</th>
              <th [style.width.px]="140" class="text-center">Charge SGNA</th>
              <th [style.width.px]="140" class="text-center">Charge Profit</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <input type="text" class="form-control" formControlName="itemCategory" readonly>
                  </td>
                  <td>
                    <input type="text" class="form-control" [style.border-color]="item.get('itemType').value == '' ? '#a94442' : null" formControlName="itemType" required (blur)="save()" (keyup.enter)="save()">
                    <span *ngIf="item.get('itemType').invalid" class="text-danger">Item type is required</span>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="typeDescription" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="140" class="text-center">
                    <input type="checkbox" formControlName="defaultChargeInstallOverhead" (change)="save()">
                  </td>
                  <td [style.width.px]="140" class="text-center">
                    <input type="checkbox" formControlName="defaultChargeSgna" (change)="save()">
                  </td>
                  <td [style.width.px]="140" class="text-center">
                    <input type="checkbox" formControlName="defaultChargeProfit" (change)="save()">
                  </td>
                  <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" (click)="remove(item.value.itemTypeId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this item types?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
