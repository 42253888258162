import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as _ from 'lodash';

@Component({
  selector: 'tab-edge',
  templateUrl: './edge.html',
  styleUrls: ['./edge.scss']
})

export class EdgeComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  dataFormInit: FormGroup;
  formAdd: FormGroup;

  edgeTypeId: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
    this.formAdd = this.fb.group({
      tenantId: [this.tenantId],
      edgeType: ['', Validators.required],
      alias: [''],
      helperText: [''],
      straight: [true],
      radius: [true],
      undermount: [true],
      onsite: [false],
      frequentlyUsed: [false]
    });
  }

  get edgeType(): FormControl {
    return this.formAdd.get('edgeType') as FormControl;
  }

  getErrorEdgeType() {
    return this.edgeType.hasError('required') ? 'Edge name cannot be empty' : '';
  }

  loadData() {
    this.loading = true;
    this.settingService.getEdgeTypes(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    this.dataFormInit = Object.assign({}, this.dataForm);
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      edgeId: e.edgeId,
      edgeType: [e.edgeType, Validators.required],
      alias: e.alias,
      helperText: e.helperText,
      straight: e.straight,
      radius: e.radius,
      undermount: e.undermount,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  addEdge() {
    this.edgeType.markAsDirty();
    this.edgeType.markAsTouched();
    if (this.formAdd.valid) {
      this.loading = true;
      this.formAdd.disable();
      this.settingService.addEdgeTypes(this.formAdd.value).then(response => {
        if (response.status) {
          this.formAdd.patchValue({
            tenantId: this.tenantId,
            edgeType: '',
            alias: '',
            helperText: '',
            straight: true,
            radius: true,
            undermount: true,
            onsite: false,
            frequentlyUsed: false
          });
          this.formAdd.markAsPristine();
          this.formAdd.markAsUntouched();
          this.toast.success('New Edge has been added');
          this.reloadStatusBatchVersions.emit();
          this.loadData();
        }
        else {
          if (response.data == 0) {
            this.toast.error('Edge was not added successfully. Be sure to check the required fields to ensure you\'ve provided the proper information. Additionally, each edge must be unique.');
          }
          else {
            this.toast.error('The Edge is added fail');
          }
          this.loading = false;
        }
        this.formAdd.enable();
      });
    }
  }

  deleteEdge(edgeTypeId) {
    this.modalConfirm.open();
    this.edgeTypeId = edgeTypeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteEdgeType(this.edgeTypeId).then(response => {
      if (response.status) {
        this.toast.success('Edge has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.edgeId == e.edgeId);
    item.edgeType = e.edgeType;
    item.alias = e.alias;
    item.helperText = e.helperText;
    item.straight = e.straight;
    item.radius = e.radius;
    item.undermount = e.undermount;
    item.onsite = e.onsite;
    item.frequentlyUsed = e.frequentlyUsed;
    return {
      edgeId: e.edgeId,
      edgeType: e.edgeType,
      alias: e.alias,
      helperText: e.helperText,
      straight: e.straight,
      radius: e.radius,
      undermount: e.undermount,
      onsite: e.onsite,
      frequentlyUsed: e.frequentlyUsed,
      isGlobal: false,
      tenantId: this.tenantId
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (this.dataForm.invalid) {
      this.toast.error(' Edge cannot be empty and must be unique');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateEdges(dataUpdate).then(response => {
        if (response.status) {
          this.dataFormInit = Object.assign({}, this.dataForm);
          // if (isReload) {
          //   this.loadData();
          //   if (dataUpdate.length > 0) {
          //     // this.settingService.setBidsIsRefesh(this.tenantId);
          //     this.reloadStatusBatchVersions.emit();
          //   }
          // }
          this.toast.success('Edge has been updated');
        }
        else {
          (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.value.edgeId == dataUpdate[0].edgeId) {
              let old = _.find(this.dataFormInit.value.dataUpdate, function (o) { return o.edgeId == dataUpdate[0].edgeId; });
              e.patchValue({
                alias: old.alias,
                helperText: old.helperText,
                straight: old.straight,
                radius: old.radius,
                undermount: old.undermount,
                onsite: old.onsite,
                frequentlyUsed: old.frequentlyUsed
              });
              e.markAsPristine();
              e.markAsUntouched();
            }
          });
          this.toast.error(response.message ?? 'Update edge failed');
        }
      });
    }
  }

  checkDuplicate(e: FormControl) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      if (e.parent.value.edgeId != m.value.edgeId && e.parent.value.edgeType.trim().toUpperCase() == m.value.edgeType.trim().toUpperCase()) {
        e.setErrors({ 'hasDup': true });
      }
    });
  }
}
