<tr>
  <div class="collapse" id="collapse_{{currentRow.locationId}}">
    <div class="table-responsive">
      <form>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="120">Address Type</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th [style.width.px]="60">State</th>
              <th [style.width.px]="120">Postal Code</th>
              <th [style.width.px]="80" class="text-center">Active</th>
              <th [style.width.px]="80" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of addresses">
              <tr>
                <td [style.width.px]="120">{{!!item.addressType ? item.addressType : 'Main'}}</td>
                <td>{{item.address1}}</td>
                <td>{{item.address2}}</td>
                <td>{{item.city}}</td>
                <td [style.width.px]="60">{{item.state}}</td>
                <td [style.width.px]="120">{{item.postalCode.length == 5 ? item.postalCode : item.postalCode.slice(0, 5) + '-' + item.postalCode.slice(5, 9)}}</td>
                <td [style.width.px]="80" class="text-center">
                  <a href="javascript:;" class="fa fa-lg" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="updateAddress(item, true)"></a>
                </td>
                <td [style.width.px]="80" class="text-center">
                  <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit" (click)="openPopupAddress(item)"></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="javascript:;" class="fa fa-lg fa-trash text-danger" title="Delete" (click)="deleteAddress(item.id)"></a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</tr>

<bs-modal #modalAddress [keyboard]="false" [backdrop]="'static'">
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Edit Address</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="formEditAddress">
      <div class="form-group" [class.has-error]="addressType.invalid && (addressType.dirty || addressType.touched)">
        <label>Address Type <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="addressType" required>
          <option value="Main">Main</option>
          <option value="Billing">Billing</option>
          <option value="Store">Store</option>
        </select>
        <ng-container *ngIf="addressType.invalid && (addressType.dirty || addressType.touched)">
          <span *ngIf="addressType.errors.required" class="help-block">Address type is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="address1.invalid && (address1.dirty || address1.touched)">
        <label>Address 1 <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="address1" maxlength="200" required>
        <ng-container *ngIf="address1.invalid && (address1.dirty || address1.touched)">
          <span *ngIf="address1.errors.required" class="help-block">Address 1 is required</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label>Address 2</label>
        <input type="text" trim="blur" class="form-control" formControlName="address2" maxlength="200">
      </div>
      <div class="form-group" [class.has-error]="city.invalid && (city.dirty || city.touched)">
        <label>City <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="city" maxlength="100" required>
        <ng-container *ngIf="city.invalid && (city.dirty || city.touched)">
          <span *ngIf="city.errors.required" class="help-block">City is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="state.invalid && (state.dirty || state.touched)">
        <label>State <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="state" required>
          <option *ngFor="let s of states" [ngValue]="s.stateCode">{{s.stateCode}}</option>
        </select>
        <ng-container *ngIf="state.invalid && (state.dirty || state.touched)">
          <span *ngIf="state.errors.required" class="help-block">State is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
        <label>Postal Code <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="postalCode" mask="00000" required>
        <ng-container *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
          <span *ngIf="postalCode.errors.required" class="help-block">Postal code is required</span>
          <span *ngIf="postalCode.errors.pattern" class="help-block">Invalid postal code format</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label><input type="checkbox" formControlName="active" /> Active</label>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loadingAddress" class="fa fa-spinner fa-spin fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>
    <button type="button" [disabled]="loadingAddress" class="btn btn-default" (click)="modalAddress.close()">Cancel</button>
    <button type="button" [disabled]="!formEditAddress.valid || loadingAddress" class="btn btn-primary" (click)="submitAddress()">Submit</button>
  </bs-modal-footer>
</bs-modal>
