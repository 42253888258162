<div class="content-wrapper">
  <section class="content-header">
    <h1>Tenants</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Tenants</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <button type="button" class="btn btn-primary" (click)="addNewTenant()">Add New</button>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" [(ngModel)]="isShowAll" (change)="showall()"/> Show all
            </label>
          </div>
        </div>
      </div>
    </div>
    <h4 style="line-height: 1.5" *ngIf="!tenants">{{ waiting_loading }}</h4>
    <div class="table-responsive" *ngIf="tenants">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="40" class="text-center">#</th>
            <th style="cursor: pointer;" (click)="changeFilter('tenantName')">Tenant Name
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'tenantName' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'tenantName' && filter.order == 'asc'"></i>
            </th>
            <th style="cursor: pointer;" (click)="changeFilter('company')">Company Name
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'company' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'company' && filter.order == 'asc'"></i>
            </th>
            <th style="cursor: pointer;" (click)="changeFilter('website')">Website
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'website' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'website' && filter.order == 'asc'"></i>
            </th>
            <th>Timezone</th>
            <th [style.width.px]="120">Phone</th>
            <th>Modules</th>
            <th [style.width.px]="80" class="text-center">Active</th>
            <th [style.width.px]="80" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tenants; let index = index">
            <td [style.width.px]="40" class="text-center">{{ filter.itemPerPage * (filter.pageNumber - 1) + index + 1 }}</td>
            <td>{{ item.tenantName }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.website }}</td>
            <td>{{ item.timeZone }}</td>
            <td [style.width.px]="120">
              <ng-container *ngIf="item.phone != null && item.phone != ''">({{item.phone | slice:0:3 }}) {{item.phone | slice:3:6}}-{{item.phone | slice:6:10}}</ng-container>
              <ng-container *ngIf="item.phone == null || item.phone == ''">{{item.phone}}</ng-container>
            </td>
            <td>{{ item.modules }}</td>
            <td [style.width.px]="80" class="text-center">
              <a href="javascript:;" class="fa fa-lg" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="updateTenantStatus(item)"></a>
            </td>
            <td [style.width.px]="80" class="text-center">
              <a routerLink="/tenant-detail/{{item.tenantId}}" class="fa fa-lg fa-pencil-square-o text-success" title="Edit"></a>&nbsp;
              <!--<a href="javascript:;" class="fa fa-lg fa-trash text-danger" title="Delete" (click)="delete(item.tenantId)"></a>-->
            </td>
          </tr>
        </tbody>
      </table>
      <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
    </div>
  </section>
</div>

<!-- <bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="form">
      <div class="form-group" [class.has-error]="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
        <label>Tenant Name <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="tenantName" maxlength="50" required>
        <ng-container *ngIf="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
          <span *ngIf="tenantName.errors.required" class="help-block">Tenant name is required.</span>
          <span *ngIf="tenantName.errors.pattern" class="help-block">Invalid tenant name format. Tenant name should not contain special characters and upper case characters.</span>
          <span *ngIf="tenantName.errors.isExistTenant" class="help-block">{{error_message}}</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="company.invalid && (company.dirty || company.touched)">
        <label>Company <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="company" maxlength="100" required>
        <ng-container *ngIf="company.invalid && (company.dirty || company.touched)">
          <span *ngIf="company.errors.required" class="help-block">Company is required.</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="website.invalid && (website.dirty || website.touched)">
        <label>Website <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="website" maxlength="100" required>
        <ng-container *ngIf="website.invalid && (website.dirty || website.touched)">
          <span *ngIf="website.errors.required" class="help-block">Website is required.</span>
          <span *ngIf="website.errors.pattern" class="help-block">Invalid url format of website.</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="phone.invalid && (phone.dirty || phone.touched)">
        <label>Phone</label>
        <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phone" />
        <ng-container *ngIf="phone.invalid && (phone.dirty || phone.touched)">
          <span *ngIf="phone.errors" class="help-block">Invalid phone number format.</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
        <label>Timezone <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="timeZoneId" required>
          <option *ngFor="let t of timezones" [ngValue]="t.Id">{{t.DisplayName}}</option>
        </select>
        <ng-container *ngIf="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
          <span *ngIf="timeZoneId.errors.required" class="help-block">Timezone is required.</span>
        </ng-container>
      </div>
      <div class="form-group" *ngIf="tenantId.value > 0">
        <label><input type="checkbox" formControlName="active" /> Active</label>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loading_submit" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px;"></i>
    <button type="button" [disabled]="loading_submit" class="btn btn-default" (click)="modal.close()">Cancel</button>
    <button type="button" [disabled]="!form.valid || loading_submit" class="btn btn-primary" (click)="submit()">Submit</button>
  </bs-modal-footer>
</bs-modal> -->

<!-- <bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to delete this Tenant?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal> -->
