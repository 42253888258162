import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserDetailService {

  public static MessageTypes = {
    RELOAD_PARENT_COMP: 'RELOAD_PARENT_COMPONENT',
  };

  private subject = new Subject<any>();

  sendMessage(messageType: string, payload: any = null) {
    this.subject.next({ messageType, payload });
  }

  // clearAll() {
  //   this.subject.next();
  // }

  getMessages(): Observable<any> {
    return this.subject.asObservable();
  }
}
