<div class="row">
  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="200">User</th>
            <th>Action</th>
            <th [style.width.px]="200">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td [style.width.px]="200">{{ item.fullName }}</td>
            <td>{{ item.actionDescription }}</td>
            <td [style.width.px]="200">{{ item.modifyTime | date:'MM/dd/yyyy h:mm a' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
