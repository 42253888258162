<div class="row">

  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px;">
      <span>
        <a (click)="change(1)" [ngClass]="{'sub-menu-hight': curTab == 1, 'sub-menu-txt': curTab != 1}">Base</a>
      </span>
      |
      <span>
        <a (click)="change(2)" [ngClass]="{'sub-menu-hight': curTab == 2, 'sub-menu-txt': curTab != 2}">Cutting</a>
      </span>
      |
      <span>
        <a (click)="change(3)" [ngClass]="{'sub-menu-hight': curTab == 3, 'sub-menu-txt': curTab != 3}">Pattern</a>
      </span>
      |
      <span>
        <a (click)="change(4)" [ngClass]="{'sub-menu-hight': curTab == 4, 'sub-menu-txt': curTab != 4}">Size</a>
      </span>
    </label>
  </div>

  <tab-tile-base-waste *ngIf="curTab == 1" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-base-waste>
  <tab-tile-cutting-waste *ngIf="curTab == 2" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-cutting-waste>
  <tab-tile-pattern-waste *ngIf="curTab == 3" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-pattern-waste>
  <tab-tile-size-waste *ngIf="curTab == 4" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-size-waste>

</div>
