<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Thickness Configuration</h4>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Thickness</th>
              <th>Thickness Alias</th>
              <th [style.width.px]="100">Active</th>
              <th>Order Index</th>
              <th>Updated On</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.thickness}}</td>
                  <td>
                    <div class="form-group" style="margin-bottom: 0">
                      <input type="text" class="form-control" formControlName="customNameAlias" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td class="text-center">
                    <input type="number" formControlName="orderIndex" (change)="save()">
                  </td>
                  <td>{{item.value.updatedAt | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
