<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: 22px;">Cost Schema Detail</label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i>Home</a></li>
      <li><a routerLink="/tile-cost-schemas">Cost Schema</a></li>
      <li class="active">Detail</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-lg-6 col-xs-12">
         <div class="form-top">
           <form novalidate [formGroup]="form">
             <div class="row inline-block">
               <div class="col-sm-5 col-xs-12">
                 <div class="form-group">
                   <label for='sname'>Schema Name
                     <span class="text-danger">*</span>
                   </label>
                   <input id='sname' type="text" trim="blur" class="form-control" formControlName="schemaName" (blur)= 'save()' (keyup.enter) = 'save()' maxlength="250" required>
                   <ng-container *ngIf="schemaName.invalid && (schemaName.dirty || schemaName.touched)">
                     <span *ngIf="schemaName.errors.required" class="help-block">Schema name is required.</span>
                   </ng-container>
                 </div>
               </div>
               <div class="col-sm-5 col-xs-12">
                 <div class="form-group" >
                   <label for = 'disc'>Description</label>
                   <input id='disc' type="text" trim="blur" class="form-control" style="resize: none" formControlName="description" (blur)= 'save()' (keyup.enter) = 'save()'>
                 </div>
               </div>
               <div class="col-sm-2">
                 <div class="form-group" style="margin-top: 30px;">
                   <label for="act" class="checkbox-inline">
                     <input id= 'act' class="custom-control-input" style="vertical-align: text-bottom;" type="checkbox" formControlName="active" (change)= 'save()'>Active
                   </label>
                 </div>
               </div>
             </div>
           </form>
         </div>
      </div>
   </div>
   <div class="row">
       <div class="col-sm-12">
         <a class="pull-right btn-close" routerLink='/tile-cost-schemas'><i class="fa fa-times-circle" aria-hidden="true"></i></a>
         <!-- <button class="pull-right bottom-right btn-close">X</button> -->
         <div class="nav-tabs-custom">
           <ul class="nav nav-tabs">
             <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
               <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
             </li>
             <div class="collapse navbar-collapse pull-right">
               <div>
                <i class="fa fa-cog fa-spin fa-2x fa-fw" *ngIf="loading"></i>
               </div>
               <div class="nav navbar-nav" *ngIf="!loading">
                 <div class="dropdown">
                   <a href="javascript:" style="font-size: 20px; color: black;" class="dropdown-toggle" data-toggle="dropdown"><span><i class="fa fa-list-ul"></i></span></a>
                   <ul class="dropdown-menu" style="margin-left: -90px;">
                      <li><a href="javascript:" (click) = 'copy();loading = true;' class="text-center">Copy</a></li>
                      <li><a href="javascript:" (click) = 'delete();' class="text-center">Delete</a></li>
                   </ul>
                 </div>
               </div>
             </div>
           </ul>
           <div class="tab-content">
              <div class="tab-pane box" id="ProcessStandard" *ngIf="curTab.id == 'ProcessStandard'">
                <tab-tile-process-standard [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ProcessStandard')"></tab-tile-process-standard>
              </div>
              <div class="tab-pane box" id="ApplicationBase" *ngIf="curTab.id == 'ApplicationBase'">
                <tab-tile-application-base [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ApplicationBase')"></tab-tile-application-base>
              </div>
              <div class="tab-pane box" id="SubTypeWeights" *ngIf="curTab.id == 'SubTypeWeights'">
                <tab-tile-sub-type-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('SubTypeWeights')"></tab-tile-sub-type-weights>
              </div>
              <div class="tab-pane box" id="Complexity" *ngIf="curTab.id == 'Complexity'">
                <tab-tile-application-complexity [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('Complexity')"></tab-tile-application-complexity>
              </div>
              <div class="tab-pane box" id="ApplicationWeights" *ngIf="curTab.id == 'ApplicationWeights'">
                <tab-tile-application-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ApplicationWeights')"></tab-tile-application-weights>
              </div>
              <div class="tab-pane box" id="TieredVolumeSizing" *ngIf="curTab.id == 'TieredVolumeSizing'">
                <tab-tile-tiered-volume-sizing [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TieredVolumeSizing')"></tab-tile-tiered-volume-sizing>
              </div>
              <div class="tab-pane box" id="TileType" *ngIf="curTab.id == 'TileType'">
                <tab-tile-type-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TileType')"></tab-tile-type-weights>
              </div>
              <div class="tab-pane box" id="TileSize" *ngIf="curTab.id == 'TileSize'">
                <tab-tile-size-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TileSize')"></tab-tile-size-weights>
              </div>
              <div class="tab-pane box" id="TilePattern" *ngIf="curTab.id == 'TilePattern'">
                <tab-tile-pattern-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TilePattern')"></tab-tile-pattern-weights>
              </div>
              <div class="tab-pane box" id="TileSet" *ngIf="curTab.id == 'TileSet'">
                <tab-tile-set-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TileSet')"></tab-tile-set-weights>
              </div>
              <div class="tab-pane box" id="TileCuttingType" *ngIf="curTab.id == 'TileCuttingType'">
                <tab-tile-cutting-type-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('TileCuttingType')"></tab-tile-cutting-type-weights>
              </div>
              <div class="tab-pane box" id="SpecialCondition" *ngIf="curTab.id == 'SpecialCondition'">
                <tab-special-condition-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('SpecialCondition')"></tab-special-condition-weights>
              </div>
              <div class="tab-pane box" id="Trim" *ngIf="curTab.id == 'Trim'">
                <tab-tile-trim [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('Trim')"></tab-tile-trim>
              </div>
              <div class="tab-pane box" id="SiteAccessDifficulty" *ngIf="curTab.id == 'SiteAccessDifficulty'">
                <tab-tile-site-access-difficulty [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('SiteAccessDifficulty')"></tab-tile-site-access-difficulty>
              </div>
              <div class="tab-pane box" id="BuildingLevels" *ngIf="curTab.id == 'BuildingLevels'">
                <tab-tile-building-levels [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('BuildingLevels')"></tab-tile-building-levels>
              </div>
              <div class="tab-pane box" id="Waste" *ngIf="curTab.id == 'Waste'">
                <tab-tile-waste [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('Waste')"></tab-tile-waste>
              </div>

              <div class="tab-pane box" id="ChangeLogSlabCost" *ngIf="curTab.id == 'ChangeLogSlabCost'">
                <tab-change-log-slab-cost [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ChangeLogSlabCost')"></tab-change-log-slab-cost>
              </div>
           </div>
         </div>
       </div>
   </div>
  </section>
</div>

<bs-modal #modal data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div>
      <label class="row col-sm-12">Currently, this cost schema is assigned to a channel.  You must assign another cost schema to channel prior to deleting Cost Schema.</label>
      <select class="row col-sm-12" style="width: 300px; height: 40px; margin-left: 10px;" [(ngModel)] = 'reassignSchemaId'>
        <option [value]="0">None</option>
        <option *ngFor="let c of activeCostSchemas" [value]="c.schemaId">{{ c.schemaName }}</option>
      </select>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-primary" [disabled]= "reassignSchemaId < 1"  (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close(); loading = false;">No</button>
  </bs-modal-footer>
</bs-modal>
