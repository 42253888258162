import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-special-conditions',
  templateUrl: './global-special-conditions.html',
})

export class TileSpecialConditionsGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  specialConditionName: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  specialConditionId: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getSpecialConditions(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      specialConditionId: e.specialConditionId,
      specialConditionName: [e.specialConditionName, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedOn: e.updatedOn,
    });
  }

  validData() {
    if (this.specialConditionName == null || this.specialConditionName == '' || this.specialConditionName.length < 2) {
      this.toast.error('Special Condition name needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addSpecialConditions({
        tenantId: 0,
        specialConditionName: this.specialConditionName,
      }).then(response => {
        if (response.status) {
          this.specialConditionName = '';
          this.toast.success('New Special Condition has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The Special Condition is added fail');
        }
      });
    }
  }

  delete(specialConditionId) {
    this.modalConfirm.open();
    this.specialConditionId = specialConditionId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteSpecialConditions(this.specialConditionId).then(response => {
      if (response.status) {
        this.toast.success('This Special Condition has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.specialConditionId == e.specialConditionId);
    item.specialConditionName = e.specialConditionName;
    item.active = e.active;
    return {
      specialConditionId: e.specialConditionId,
      specialConditionName: e.specialConditionName,
      active: e.active,
      isGlobal: true
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.specialConditionName == null || e.value.specialConditionName.trim() == "" || e.value.specialConditionName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Special Condition name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateSpecialConditions(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Special Condition has been updated');
        }
        else this.toast.error('Update Special Condition failed');
      });
    }
  }
}
