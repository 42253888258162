<tr>
  <div class="collapse" id="collapse_{{currentRow.id}}">
    <button [disabled]="!enabledEdit" (click)="passingDataToModal()" type="button" class="btn btn-primary pull-right" data-toggle="modal"
      data-target="#assignCostSchemaModal">
      <strong>+</strong>
    </button>
    <div class="table-responsive">
      <form>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="70">Type</th>
              <th>Schema Name</th>
              <th>Description</th>
              <th [style.width.px]="70">Default</th>
              <th>Last Updated</th>
              <th>Assigned By</th>
              <th>Assigned</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of assignedCostSchemas">
              <tr>
                <td [style.width.px]="70">{{ item.businessUnit }}</td>
                <td>
                  {{ item.schemaName }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td [style.width.px]="70" class="text-center">
                  <input type="checkbox" name="isDefault_{{item.id}}" [disabled]="item.isDefault || !enabledEdit"
                    [ngModel]="item.isDefault" (change)="updateIsDefault(item, $event)" />
                </td>
                <td>
                  {{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td>
                  {{ item.updatedBy }}
                </td>
                <td>
                  {{ item.assignedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td [style.width.px]="96">
                  <button [disabled]="!enabledEdit" *ngIf="!item.isDefault" type="button" class="btn btn-primary"
                    (click)="delete(item.id, 0)">Remove</button>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngFor="let item of getArrAdd()">
              <tr>
                <td [style.width.px]="70">{{ item.businessUnit }}</td>
                <td>
                  {{ item.schemaName }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td [style.width.px]="70" class="text-center">
                  <input type="checkbox" name="isDefault_{{item.id}}" [disabled]="item.isDefault || !enabledEdit"
                    [ngModel]="item.isDefault" (change)="updateIsDefault2(item, $event)" />
                </td>
                <td>
                  {{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td>
                  {{ item.updatedBy }}
                </td>
                <td>
                  {{ item.assignedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td [style.width.px]="96">
                  <button [disabled]="!enabledEdit" *ngIf="!item.isDefault" type="button" class="btn btn-primary"
                    (click)="delete(item.id, item.schemaId)">Remove</button>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngFor="let item of tileAssignedCostSchemas">
              <tr>
                <td [style.width.px]="70">{{ item.businessUnit }}</td>
                <td>
                  {{ item.schemaName }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td [style.width.px]="70" class="text-center">
                  <input type="checkbox" name="isDefault_{{item.id}}" [disabled]="item.isDefault || !enabledEdit"
                    [ngModel]="item.isDefault" (change)="tileUpdateIsDefault(item, $event)" />
                </td>
                <td>
                  {{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td>
                  {{ item.updatedBy }}
                </td>
                <td>
                  {{ item.assignedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td [style.width.px]="96">
                  <button [disabled]="!enabledEdit" *ngIf="!item.isDefault" type="button" class="btn btn-primary"
                    (click)="tileDelete(item.id, 0)">Remove</button>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngFor="let item of tileGetArrAdd()">
              <tr>
                <td [style.width.px]="70">{{ item.businessUnit }}</td>
                <td>
                  {{ item.schemaName }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td [style.width.px]="70" class="text-center">
                  <input type="checkbox" name="isDefault_{{item.id}}" [disabled]="item.isDefault || !enabledEdit"
                    [ngModel]="item.isDefault" (change)="tileUpdateIsDefault2(item, $event)" />
                </td>
                <td>
                  {{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td>
                  {{ item.updatedBy }}
                </td>
                <td>
                  {{ item.assignedOn | date:'MM/dd/yyyy h:mm a' }}
                </td>
                <td [style.width.px]="96">
                  <button [disabled]="!enabledEdit" *ngIf="!item.isDefault" type="button" class="btn btn-primary"
                    (click)="tileDelete(item.id, item.schemaId)">Remove</button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</tr>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? Removing the cost schema will reassign open bids to default cost schema.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #tileModalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? Removing the cost schema will reassign open bids to default cost schema.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="tileModalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="tileSubmitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
