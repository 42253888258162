<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Edge Configuration</h4>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Edge Type</th>
              <th>Edge Alias</th>
              <th>Helper Text</th>
              <th [style.width.px]="130" class="text-center">Straight</th>
              <th [style.width.px]="130" class="text-center">Radius</th>
              <th [style.width.px]="130" class="text-center">Undermount</th>
              <th [style.width.px]="100" class="text-center">Onsite</th>
              <th [style.width.px]="130" class="text-center">Frequently Used</th>
              <th [style.width.px]="180">Updated On</th>
              <th>Updated By</th>
              <!-- <th [style.width.px]="96"></th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.edgeType}}</td>
                  <td>
                    <input type="text" trim="blur" maxlength="200" class="form-control" formControlName="alias" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>
                    <input type="text" trim="blur" maxlength="200" class="form-control" formControlName="helperText" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="straight" (change)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="radius" (change)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="undermount" (change)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="onsite" (change)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="frequentlyUsed" (change)="save()">
                  </td>
                  <td [style.width.px]="180">{{item.value.updatedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this edge? Removing the edge type will convert all bids with this edge to None.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
