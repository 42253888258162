import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SlabCostDetailService {

  public static MessageTypes = {
    RELOAD_PARENT_COMMENT: 'RELOAD_PARENT_COMPONENT'
  };

  private subject = new Subject<any>();

  sendMessage(messageType: string, payload: any = null) {
    this.subject.next({ messageType, payload });
  }

  getMessages(): Observable<any> {
    return this.subject.asObservable();
  }
}
