import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class ChannelService {
  constructor(private http: AuthHttp) { }

  addChannel(data): Promise<any> {
    return this.http.post(`Channels/AddChannel`, data).toPromise();
  }

  // deleteChannel(channelId: number): Promise<any> {
  //   return this.http.delete(`Channels/DeleteChannel/${channelId}`).toPromise();
  // }
}
