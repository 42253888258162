<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Tile Configuration</a></span> | <span><a class="sub-menu-txt">Cost Schema</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Tile Cost</li>
    </ol>
  </section>
  <section class="content panel-margin">
    <div class="panel-custom">
      <p class="title-half-border">Cost Schema</p>
      <button type="submit" [disabled]="isDisabledBtnAdd" [disabled] = 'loading' (click)="add()" class="btn btn-primary pull-right" style="margin-top: 10px; margin-right: 10px;" *ngIf="!isTenantOnly"><span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span><strong *ngIf="!loading">+ </strong> Add</button>
      <div class="table-responsive" >
        <table class="table table-bordered table-hover table-sm">
          <thead>
            <tr>
              <th class="text-left">Schema Name</th>
              <th class="text-left">Description</th>
              <th class="text-center" [style.width.px]="100">Active</th>
              <th class="text-left" [style.width.px]="200">Last Updated</th>
              <th class="text-left" [style.width.px]="160">Updated By</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let schema of costSchemas; let i = index' [ngClass]="i % 2 == 0? 'table-active' : 'table-light'">
              <td class="text-left">
                <a href="javascript:;" class="text-primary" routerLink="/tile-cost-schema-detail/{{schema.schemaId}}">{{schema.schemaName}}</a>
              </td>
              <td class="text-left">{{schema.description}}</td>
              <td [style.width.px]="100" class="text-center">
                <a href="javascript:;" class="fa fa-lg" style="cursor: pointer" [ngClass]="schema.active != 1 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'"></a>
              </td>
              <td [style.width.px]="200" class="text-left">{{schema.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
              <td [style.width.px]="160" class="text-left">{{schema.updatedBy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
