import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class CompareService {
  constructor(private http: AuthHttp) {

  }

  compareCosmosDataByTenant() {
    return this.http.requestSpecial('POST', `${environment.linkAPIToolCompare}/ToolCompare/CompareCosmosDataByTenant/1`, null, null).toPromise();
  }

  compareCosmosOnlyOneBid(bidId) {
    return this.http.requestSpecial('GET', `${environment.linkAPIToolCompare}/ToolCompare/CompareCosmosOnlyOneBid/${bidId}`, null, null).toPromise();
  }

  getBatchJobs() {
    return this.http.requestSpecial('GET', `${environment.linkAPIToolCompare}/ToolCompare/GetBatchJobs`, null, null).toPromise();
  }

  getLogByBatchId(id) {
    return this.http.requestSpecial('GET', `${environment.linkAPIToolCompare}/ToolCompare/GetLogByBatchId/${id}`, null, null).toPromise();
  }
}