<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Site Access</h4>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Access Level</label>
      <select [(ngModel)]="siteAccessId" (change)="filterData()" class="form-control">
        <option *ngFor="let s of siteAccesses" [ngValue]="s.siteAccessId">{{s.siteAccessName}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <div class="form-group">
      <label>Instructions:</label>
      <p>Provide adjustments for each difficulty level for project sites. Adjustments will reduce the amount of output per hour for each process.</p>
    </div>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">REDUCTION OF OUTPUT PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Slab Process</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.processName}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="percentAdjustment" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>                 
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>