import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router, public userService: UserService) { }

    canActivate() {
        if (this.userService.checkAuthorization()) {
            return true;
        }
        this.router.navigate(['login']);
        return false;
    }
}
