<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Slab Configuration</a></span> | <span><a class="sub-menu-txt">Global Configuration</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Global Configuration</li>
    </ol>
  </section>
  <section class="content">
    <div class="nav-tabs-custom" *ngIf="!loadingTenant">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box" id="application" *ngIf="curTab.id == 'application'">
          <tab-global-application *ngIf="unitsOfMeasure" [unitsOfMeasure]="unitsOfMeasure"
           (reloadStatusBatchVersions)="loadStatusBatchVersions()" [isTenantOnly]="isTenantOnly"
           (calcHeightTable)="calcHeightTable('application')"></tab-global-application>
        </div>
        <div class="tab-pane box" id="materialTypes" *ngIf="curTab.id == 'materialTypes'">
          <tab-global-material-types (reloadStatusBatchVersions)="loadStatusBatchVersions()" 
          [isTenantOnly]="isTenantOnly" (calcHeightTable)="calcHeightTable('materialTypes')"></tab-global-material-types>
        </div>
        <div class="tab-pane box" id="edge" *ngIf="curTab.id == 'edge'">
          <tab-global-edge (reloadStatusBatchVersions)="loadStatusBatchVersions()" 
          [isTenantOnly]="isTenantOnly" (calcHeightTable)="calcHeightTable('edge')"></tab-global-edge>
        </div>
        <div class="tab-pane box" id="thickness" *ngIf="curTab.id == 'thickness'">
          <tab-global-thickness (calcHeightTable)="calcHeightTable('thickness')" [isTenantOnly]="isTenantOnly"></tab-global-thickness>
        </div>
      </div>
    </div>
  </section>
</div>

<bs-modal #modalUpdateStatusBatchVersions>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Update status batch versions</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="statusBatchVersionsForm">
      <div class="form-group">
        <label>Batch Id</label>
        <input type="text" class="form-control" formControlName="batchVersionId" readonly>
      </div>
      <div class="form-group">
        <label>Name of the Batch</label>
        <input type="text" trim="blur" class="form-control" formControlName="batchName" maxlength="100">
      </div>
      <div class="form-group">
        <label>Short Note</label>
        <input type="text" trim="blur" class="form-control" formControlName="note" maxlength="200">
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalUpdateStatusBatchVersions.close()">Cancel</button>
    <button type="button" [disabled]="statusBatchVersionsForm.disabled" class="btn btn-primary" (click)="updateStatus()">Publish</button>
  </bs-modal-footer>
</bs-modal>
