import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-trim-types',
  templateUrl: './trim-types.html'
})

export class TrimTypesComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileTrimTypes(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      trimTypeId: e.trimTypeId,
      trimTypeName: [e.trimTypeName, Validators.required],
      active:[e.active],
      isRemove: e.isRemove,
      alias: e.alias,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      frequentlyUsed: [e.frequentlyUsed],
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.trimTypeId == e.trimTypeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.trimTypeName = e.trimTypeName;
    item.active = e.active;
    return {
      trimTypeId: e.trimTypeId,
      alias: e.alias,
      trimTypeName: e.trimTypeName,
      active: e.active,
      isGlobal: false,
      tenantId: this.tenantId,
      frequentlyUsed: e.frequentlyUsed,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.trimTypeName == null || e.value.trimTypeName.trim() == "" || e.value.trimTypeName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Trim Type name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileTrimTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Trim Type has been updated');
          this.loadData();
        }
        else this.toast.error('Update Trim Type failed');
      });
    }
  }
}
