import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-default-tax-by-channel',
  templateUrl: './default-tax-by-channel.html',
  styleUrls: ['./default-tax-by-channel.scss']
})

export class DefaultTaxByChannelComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();

  loading = false;
  taxAssignments: any;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;

    this.settingService.getTaxAssignments(this.tenantId).then(response => {
      if (response.status) this.taxAssignments = response.data;
      else this.toast.error('Can\'t get list tax assignments');
    });

    this.settingService.getDefaultTaxByChannel(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      taxDefaultAssignmentId: e.taxDefaultAssignmentId,
      channelName: e.channelName,
      bidSubTypeName: e.bidSubTypeName,
      defaultTaxRegionId: e.defaultTaxRegionId,
      salesTaxRate: new PercentPipe('en-US').transform(e.salesTaxRate, '.2-2'),
      laborCostRate: new PercentPipe('en-US').transform(e.laborCostRate, '.2-2'),
      materialCostRate: new PercentPipe('en-US').transform(e.materialCostRate, '.2-2'),
      suppliesCostRate: new PercentPipe('en-US').transform(e.suppliesCostRate, '.2-2'),
      itemCostRate: new PercentPipe('en-US').transform(e.itemCostRate, '.2-2')
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.taxDefaultAssignmentId == e.taxDefaultAssignmentId);
    item.defaultTaxRegionId = e.defaultTaxRegionId;
    return {
      taxDefaultAssignmentId: e.taxDefaultAssignmentId,
      defaultTaxRegionId: Number(e.defaultTaxRegionId)
    };
  }

  save() {
    let dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        let itemView = this.taxAssignments.find(i => i.taxAssignmentId == e.value.defaultTaxRegionId);
        e.patchValue({
          salesTaxRate: new PercentPipe('en-US').transform(itemView.salesTaxRate, '.2-2'),
          laborCostRate: new PercentPipe('en-US').transform(itemView.laborCostRate, '.2-2'),
          materialCostRate: new PercentPipe('en-US').transform(itemView.materialCostRate, '.2-2'),
          suppliesCostRate: new PercentPipe('en-US').transform(itemView.suppliesCostRate, '.2-2'),
          itemCostRate: new PercentPipe('en-US').transform(itemView.itemCostRate, '.2-2')
        });
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateDefaultTaxByChannel(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Default tax by channel has been updated');
          if (dataUpdate.length > 0) {
                        this.settingService.setBidsIsRefreshByTenant(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
        }
        else this.toast.error('Update default tax by channel failed');
      });
    }
  }
}
