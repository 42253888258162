<div class="content-wrapper">
  <section class="content-header">
    <h1>Users</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Users</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!arrTenant"></i>
        <div class="form-group" *ngIf="arrTenant">
          <label class="control-label">Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option [ngValue]="0">All Tenants</option>
            <option [ngValue]="-1">QXM Pro Admin</option>
            <option *ngFor="let t of arrTenant" [ngValue]="t.tenantId">{{t.tenantName}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <div class="checkbox">
            <label>
              <input type="checkbox" [(ngModel)]="showQXM" (change)="onShowQXM()"/> QXM Staff
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-10 col-12">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="filter.textSearch"
              placeholder="Search for users by Email Address, First Name, Last Name, Phone" (keyup.enter)="search()">
            <span class="input-group-btn">
              <button class="btn btn-primary" type="button" (click)="search()" title="Search">Search</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-10 col-12">
        <div class="form-group" *ngIf="arrTenant && tenantId > 0">
          <button type="button" class="btn btn-primary" (click)="addNewUser()">Add New</button>
        </div>
      </div>
    </div>
    <h4 style="line-height: 1.5" *ngIf="!users">{{ waiting_loading }}</h4>
    <div class="table-responsive" *ngIf="users">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="40" class="text-center">#</th>
            <th [style.width.px]="250">Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Tenant</th>
            <th [style.width.px]="60">QXM Admin</th>
            <th [style.width.px]="60">QXM Staff</th>
            <th [style.width.px]="120">Phone</th>
            <th [style.width.px]="160">Last Login</th>
            <th [style.width.px]="100">Number of Password Reset</th>
            <th [style.width.px]="70" class="text-center">Active</th>
            <th [style.width.px]="80" class="text-center">Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of users; let index = index">
            <ng-container *ngIf="item.tenantId != ''">
              <td [style.width.px]="40" class="text-center">{{index +1}}</td>
              <td [style.width.px]="250"><a routerLink="/user-detail/{{item.userId}}">{{ item.email }}</a></td>
              <td>{{ item.firstName }}</td>
              <td>{{ item.lastName }}</td>
              <td>
                <span *ngFor="let tenantItem of item.arrTenant;let i = index">
                  {{tenantItem.tenantName}}<span *ngIf="i + 1 < item.arrTenant.length">,</span>
                </span>
              </td>
              <td [style.width.px]="60">{{ item.qxmAdmin ? 'Yes' : 'No' }}</td>
              <td [style.width.px]="60">{{ item.qxmStaff ? 'Yes' : 'No' }}</td>
              <td [style.width.px]="120">
                <ng-container *ngIf="item.phone">({{item.phone | slice:0:3 }})
                  {{item.phone | slice:3:6}}-{{item.phone | slice:6:10}}</ng-container>
              </td>
              <td [style.width.px]="160">{{item.lastLogin | date:'MM/dd/yyyy h:mm a'}}</td>
              <td [style.width.px]="100">{{item.numberOfPasswordReset}}</td>
              <td [style.width.px]="70" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer"
                  [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'"
                  (click)="activeUser(item, !item.active)"></i>
              </td>
              <td [style.width.px]="80" class="text-center">
                <a routerLink="/user-detail/{{item.userId}}" title="Edit"
                  class="fa fa-lg fa-pencil-square-o text-success"></a>&nbsp;
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10"
        [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
    </div>
  </section>
</div>

<bs-modal #modalAddUser>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Add New User</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="addUserForm">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="firstName.invalid && (firstName.dirty || firstName.touched)">
            <label>First Name
              <span class="text-danger">*</span>
            </label>
            <input type="text" trim="blur" class="form-control" formControlName="firstName" maxlength="50" required>
            <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
              class="help-block">{{getErrorFirstName()}}</span>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="lastName.invalid && (lastName.dirty || lastName.touched)">
            <label>Last Name
              <span class="text-danger">*</span>
            </label>
            <input type="text" trim="blur" class="form-control" formControlName="lastName" maxlength="50" required>
            <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              class="help-block">{{getErrorLastName()}}</span>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="email.invalid && (email.dirty || email.touched)">
            <label>Email
              <span class="text-danger">*</span>
            </label>
            <input type="email" trim="blur" class="form-control" formControlName="email" maxlength="200" required>
            <span *ngIf="email.invalid && (email.dirty || email.touched)" class="help-block">{{getErrorEmail()}}</span>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="confirmEmail.invalid && (confirmEmail.dirty || confirmEmail.touched)">
            <label>Confirm Email
              <span class="text-danger">*</span>
            </label>
            <input type="email" class="form-control" formControlName="confirmEmail" maxlength="200" required>
            <span *ngIf="confirmEmail.invalid && (confirmEmail.dirty || confirmEmail.touched)"
              class="help-block">{{getErrorConfirmEmail()}}</span>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="phone.invalid && (phone.dirty || phone.touched)">
            <label>Phone</label>
            <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phone" />
            <span *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="help-block">{{getErrorPhone()}}</span>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group" style="margin-top: 30px">
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="employee" /> Employee
              </label>
              &nbsp;
              <label>
                <input type="checkbox" formControlName="staff" /> QXM staff
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAddUser.close()">Cancel</button>
    <button type="button" [disabled]="addUserForm.invalid || addUserForm.disabled" class="btn btn-primary"
      (click)="saveUser()">Submit</button>
  </bs-modal-footer>
</bs-modal>
