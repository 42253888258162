import { Component, OnInit, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/services/setting.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import EdgeType from './edgeType.model';
import Thickness from './thickness.model';

@Component({
  selector: 'tab-global-thickness',
  templateUrl: './global-thickness.html'
})

export class ThicknessGlobalComponent implements OnInit {

  @Input() isTenantOnly: any;
  @Output() calcHeightTable = new EventEmitter<any>();
  @ViewChild('modal') modal: BsModalComponent;
  loading = false;
  data: Thickness[];
  dataFilter: Thickness[];
  dataForm: FormGroup;
  edgeTypes: EdgeType[];

  // ngModel
  thicknessId: number;
  slabThicknessName: string;
  edgeTypeId = 1;

  // Modal
  ok: boolean;
  dstThicknessId = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    Promise.all([
      this.settingService.getGlobalEdgeTypes(),
      this.settingService.getSlabThickness(),
    ]).then(res => {
      if (!res[0].status || !res[1].status) {
        this.toast.error('Load data failed');
      }
      this.edgeTypes = res[0].data;

      this.data = res[1].data;
      this.filterData();
      this.loading = false;
      this.calcHeightTable.emit();
    });
  }

  private filterData() {
    this.dataFilter = this.data;
    const dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      thicknessId: e.thicknessId,
      slabThicknessName: [e.slabThicknessName, Validators.required],
      defaultUndermountEdgeId: [{value: e.defaultUndermountEdgeId, disabled: this.isTenantOnly}],
      defaultUndermountEdge: e.defaultUndermountEdge,
      updatedOn: e.updatedOn
    });
  }

  private validData() {
    if (this.slabThicknessName == null || this.slabThicknessName == '') {
      this.toast.error('Thickness Name cannot be empty');
      return false;
    }
    return true;
  }

  addThickness() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addThickness({
        tenantId: 0,
        slabThicknessName: this.slabThicknessName,
        defaultUndermountEdgeId: this.edgeTypeId,
      }).then(response => {
        if (response.status) {
          this.slabThicknessName = '';
          this.toast.success('New Thickness has been added');
          this.loadData();
        }
        else {
          this.toast.error(response.message);
          this.loading = false;
        }
      });
    }
  }

  deleteThickness(thicknessId: number) {
    this.ok = false;
    this.dstThicknessId = 0;
    this.modal.open();
    this.thicknessId = thicknessId;
  }

  private submitDelete() {
    this.loading = true;
    this.modal.close();
    this.settingService.deleteThickness(this.thicknessId, this.dstThicknessId).then(response => {
      if (response.status) {
        this.toast.success('Thickness has been removed');
        this.loading = true;
        this.loadData();
      } else {
        this.toast.error(response.message);
      }
      this.loading = false;
    });
  }

  private createItemUpdate(e) {
    let item = this.data.find(i => i.thicknessId === e.thicknessId);
    item.slabThicknessName = e.slabThicknessName;
    return {
      thicknessId: e.thicknessId,
      slabThicknessName: e.slabThicknessName,
      defaultUndermountEdgeId: e.defaultUndermountEdgeId,
      isGlobal: true
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.slabThicknessName == null || e.value.slabThicknessName.trim() == "" || e.value.slabThicknessName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Thickness cannot be empty');
        }
        isValid = false;
      }
    });
    if (isValid && this.dataForm.invalid) {
      this.toast.error('This thickness already exists. System doesn\'t support duplicate thicknesses. Make appropriate changes and retry ');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateThickness(dataUpdate[0]).then(response => {
        if (response.status) {
          this.toast.success('Thickness has been updated');
        }
        else {
          this.toast.error(response.message);
        }
      });
    }
  }

  checkDuplicate(e) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      if (e.thicknessId.value != m.value.thicknessId
        && e.slabThicknessName.value.trim().toUpperCase() == m.value.slabThicknessName.trim().toUpperCase()) {
        e.slabThicknessName.setErrors({ 'hasDup': true });
      }
    });
  }

  confirm(cancel: boolean = false){
    if (cancel) {
      this.modal.close();
      return;
    }
    if (!this.ok){
      this.deleteThicknessCheck();
      return;
    }
    if (this.ok && !!this.dstThicknessId) {
      this.submitDelete();
      this.modal.close();
      return;
    }
  }

  private deleteThicknessCheck(): void {
    this.settingService.deleteThicknessCheck(this.thicknessId).then(response => {
      if (response.status) {
        this.dstThicknessId = null;
        this.submitDelete();
        this.modal.close();
      } else {
        this.ok = true;
      }
    });
  }
}
