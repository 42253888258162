<div class="row">

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">REDUCTION OF OUTPUT PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Special Condition</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.specialConditionName}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="percentage" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}} <span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes'></app-view-comment>

