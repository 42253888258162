<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Tile Configuration</a></span> | <span><a class="sub-menu-txt">Global Configuration</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Global Configuration</li>
    </ol>
  </section>
  <section class="content">
    <div class="nav-tabs-custom" *ngIf="!loadingTenant">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box" id="application" *ngIf="curTab.id == 'application'">
          <tab-tile-global-application (calcHeightTable)="calcHeightTable('application')"></tab-tile-global-application>
        </div>
        <div class="tab-pane box" id="types" *ngIf="curTab.id == 'types'">
          <tab-tile-global-types (calcHeightTable)="calcHeightTable('types')"></tab-tile-global-types>
        </div>
        <div class="tab-pane box" id="thickness" *ngIf="curTab.id == 'thickness'">
          <tab-tile-global-thickness (calcHeightTable)="calcHeightTable('thickness')"></tab-tile-global-thickness>
        </div>
        <div class="tab-pane box" id="sizes" *ngIf="curTab.id == 'sizes'">
          <tab-tile-global-sizes (calcHeightTable)="calcHeightTable('sizes')"></tab-tile-global-sizes>
        </div>
        <div class="tab-pane box" id="sets" *ngIf="curTab.id == 'sets'">
          <tab-tile-global-sets (calcHeightTable)="calcHeightTable('sets')"></tab-tile-global-sets>
        </div>
        <div class="tab-pane box" id="specialConditions" *ngIf="curTab.id == 'specialConditions'">
          <tab-tile-global-special-conditions (calcHeightTable)="calcHeightTable('specialConditions')"></tab-tile-global-special-conditions>
        </div>
        <div class="tab-pane box" id="trimTypes" *ngIf="curTab.id == 'trimTypes'">
          <tab-tile-global-trim-types (calcHeightTable)="calcHeightTable('trimTypes')"></tab-tile-global-trim-types>
        </div>
        <div class="tab-pane box" id="trimEdges" *ngIf="curTab.id == 'trimEdges'">
          <tab-tile-global-trim-edges (calcHeightTable)="calcHeightTable('trimEdges')"></tab-tile-global-trim-edges>
        </div>
      </div>
    </div>
  </section>
</div>
