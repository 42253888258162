<div class="content-wrapper">
    <section class="content-header">
        <h1>Dashboard</h1>
        <!-- <ol class="breadcrumb">
            <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
            <li class="active">Dashboard</li>
        </ol> -->
    </section>

    <section class="content" >
    </section>
</div>