import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class TenantsService {
  constructor(private http: AuthHttp) { }

  getTimeZone(): Promise<any> {
    return this.http.get('Utilities/TimeZone').toPromise();
  }

  GetListTenant(filter: any): Promise<any> {
    return this.http.post('GetListTenant', JSON.stringify(filter)).toPromise();
  }

  CreateTenant(data: any): Promise<any> {
    return this.http.post('CreateTenant', JSON.stringify(data)).toPromise();
  }

  getTenantDetails(id): Promise<any> {
    return this.http.get('GetTenantDetails/' + id).toPromise();
  }

  UpdateTenant(data: any): Promise<any> {
    return this.http.post('UpdateTenant', JSON.stringify(data)).toPromise();
  }

  addSubscription(info): Promise<any> {
    return this.http.post(`Subscriptions`, JSON.stringify(info)).toPromise();
  }

  updateSubscription(subscriptionId: number, data: any): Promise<any> {
    return this.http.put(`Subscriptions/${subscriptionId}`, JSON.stringify(data)).toPromise();
  }

  addAddressTenant(data): Promise<any> {
    return this.http.post('AddAddressTenant', JSON.stringify(data)).toPromise();
  }

  updateAddressTenant(data, onlyActive): Promise<any> {
    return this.http.post('UpdateAddressTenant/' + onlyActive, JSON.stringify(data)).toPromise();
  }

  RemoveTenant(data: any): Promise<any> {
    return this.http.post('RemoveTenant', JSON.stringify(data)).toPromise();
  }

  GetListTenantPricingRecalculation(filter: any): Promise<any> {
    return this.http.post('GetListTenantPricingRecalculation', JSON.stringify(filter)).toPromise();
  }

  UpdatePricingRecalculationStatus(tenantId: any, status: any): Promise<any> {
    return this.http.post(`UpdatePricingRecalculationStatus/${tenantId}/${status}`).toPromise();
  }

  insertData(data): Promise<any> {
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    return this.http.requestSpecial("GET", environment.linkDPT + '/DPT/InsertData/' + data, null, headers).toPromise();
  }

  fillDataCPBidHeader(data): Promise<any> {
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    return this.http.requestSpecial("GET", environment.linkDPT + '/DPT/FillDataCPBidHeader/' + data, null, headers).toPromise();
  }

  UpdateTenantIntegration(data: any): Promise<any> {
    return this.http.post('UpdateTenantIntegration', JSON.stringify(data)).toPromise();
  }
}
