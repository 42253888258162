import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ReportService {
  constructor(private http: AuthHttp) { }

  getAllReportCategory(): Promise<any> {
    return this.http.get('Utilities/GetAllReportCategory').toPromise();
  }

  getListReports(filter: any): Promise<any> {
    return this.http.post('Quote/GetListReports', JSON.stringify(filter)).toPromise();
  }

  addNewReport(report: any): Promise<any> {
    return this.http.post('Quote/AddNewReport', JSON.stringify(report)).toPromise();
  }

  updateReport(report: any): Promise<any> {
    return this.http.post('Quote/UpdateReport', JSON.stringify(report)).toPromise();
  }

  activeReport(id, status): Promise<any> {
    return this.http.put(`Quote/SetActiveReport/${id}/${status}`).toPromise();
  }

  setReportStandard(id, isStandard): Promise<any> {
    return this.http.get(`Setting/setReportStandard/${id}/${isStandard}`).toPromise();
  }


  deleteReport(reportId): Promise<any> {
    return this.http.delete('Quote/DeleteReport/' + reportId).toPromise();
  }

  loadRoleByTenantId(tenantId, reportId): Promise<any> {
    return this.http.get(`Setting/LoadRoleByTenantId/${tenantId}/${reportId}`).toPromise();
  }

  getDocumentsByTenantName(type: string, tenantName: string): Promise<any> {
    return this.http.get(`Report/GetDocumentsByTenantName/${type}/${tenantName}`).toPromise();
  }
  assignReportPermission(tenantId, reportId, info): Promise<any> {
    return this.http.post(`Setting/AssignReportPermission/${tenantId}/${reportId}`, JSON.stringify(info)).toPromise();
  }
}
