<div class="content-wrapper">
  <section class="content-header">
    <h1>Regions</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Regions</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-6 col-xs-12">
         <div class="form-top">
           <form novalidate [formGroup]="form">
             <div class="row inline-block">
               <div class="col-sm-5 col-xs-12">
                 <div class="form-group" >
                   <input id='reg' type="text" trim="blur" class="form-control" style="resize: none" formControlName="regionName">
                 </div>
               </div>
               <div class="col-sm-2">
                 <div class="form-group" style="padding-top:5px">
                   <label for="act" class="checkbox-inline">
                     <input id= 'act' class="custom-control-input" style="vertical-align: text-bottom;" type="checkbox" formControlName="active">Active
                   </label>
                 </div>
               </div>
               <div class="col-sm-5 col-xs-12">
                <div class="form-group">
                  <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="addNew()"><strong [disable]="form.invalid">+ </strong> Add</button>
                </div>
              </div>
              </div>
           </form>
         </div>
      </div>
   </div>
    <h4 style="line-height: 1.5" *ngIf="!data">{{ waiting_loading }}</h4>
    <div class="table-responsive" *ngIf="data">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="40" class="text-center">#</th>
            <th style="cursor: pointer;" (click)="changeFilter('tenantName')">Region
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'region' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'region' && filter.order == 'asc'"></i>
            </th>
            <th [style.width.px]="80" class="text-center">Active</th>
            <th style="cursor: pointer;" (click)="changeFilter('company')">Created By
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'createdBy' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'createdBy' && filter.order == 'asc'"></i>
            </th>
            <th style="cursor: pointer;" (click)="changeFilter('website')">Created On
              <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'createdOn' && filter.order == 'desc'"></i>
              <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'createdOn' && filter.order == 'asc'"></i>
            </th>
            <th [style.width.px]="80" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data; let index = index">
            <td [style.width.px]="40" class="text-center">{{ filter.itemPerPage * (filter.pageNumber - 1) + index + 1 }}</td>
            <td>{{ item.region }}</td>
            <td [style.width.px]="80" class="text-center">
              <a href="javascript:;" class="fa fa-lg" [ngClass]="!item.active ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="setActive(item)"></a>
            </td>
            <td>{{ item.createdBy }}</td>
            <td>{{ item.createdOn  | date:'MM/dd/yyyy h:mm a' }}</td>
            <td [style.width.px]="80" class="text-center">
              <!-- <a routerLink="/tenant-detail/{{item.tenantId}}" class="fa fa-lg fa-pencil-square-o text-success" title="Edit"></a>&nbsp; -->
              <a href="javascript:;" class="  fa fa-lg fa-trash text-danger" title="Delete" (click)="delete(item.regionId)"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
    </div>
  </section>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Are you sure?</h4>
  </bs-modal-header>
  <bs-modal-body>
    This will require existing region assignments to be changed to a new Region.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button id="cancelDelete" type="button" class="btn btn-default" (click)="modalConfirm.close()">Cancel</button>
    <button id="submitDelete" type="button" class="btn btn-danger" (click)="submitDelete()">Submit</button>
  </bs-modal-footer>
</bs-modal>
