import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class CostSchemaAssignmentService {

  controller = 'CostSchemaAssignments';

  constructor(private http: AuthHttp) {
  }

  getByTenant(tenantId): Promise<any> {
    return this.http.get(`${this.controller}/${tenantId}`).toPromise();
  }

  // updateByTenant(data): Promise<any> {
  //   return this.http.put(`${this.controller}`, data).toPromise();
  // }

  getChangeLogs(tenantId): Promise<any> {
    return this.http.get(`${this.controller}/GetChangeLogs/${tenantId}`).toPromise();
  }

  getCostSchemaAssignsByChannelId(tenantId: number, channelId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetCostSchemaAssignsByChannelId/${tenantId}/${channelId}`).toPromise();
  }

  // assignCostSchemasToChannel(data): Promise<any> {
  //   return this.http.post(`${this.controller}`, data).toPromise();
  // }

  // updateIsDefault(data): Promise<any> {
  //   return this.http.put(`${this.controller}/UpdateAssignedCostSchemas`, data).toPromise();
  // }

  // unassignCostSchema(id: number): Promise<any> {
  //   return this.http.delete(`${this.controller}/UnassignCostSchema/${id}`).toPromise();
  // }

  applyChanges(model: any): Promise<any> {
    return this.http.post(`${this.controller}/ApplyChanges`, model).toPromise();
  }
}
