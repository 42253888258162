import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompareService } from '../../services/compare.service';
import { BsModalComponent } from 'ng2-bs3-modal';
declare var $: any;

@Component({
  templateUrl: './compare-tool.html'
})

export class CompareToolComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  dataBatch: any;
  currentBidId = 0;
  currentDataBid = {};

  constructor(private toastr: ToastrService, private compareService: CompareService) {

  }

  ngOnInit() {
    $('body').layout('fix');
    this.loadData();
  }

  loadData() {
    this.dataBatch = null;
    this.compareService.getBatchJobs().then(res => {
      if (res.status) this.dataBatch = res.data || [];
      else this.toastr.error("Get data batch failed");
    });
  }

  compareByTenantStockett() {
    this.compareService.compareCosmosDataByTenant().then(res => {
      if (res.status) {
        this.toastr.success(`Your batch job ID is: ${res.data}`);
        this.loadData();
      }
      else this.toastr.error("Can not compare data now");
    });
  }

  showLog(bidId) {
    this.compareService.compareCosmosOnlyOneBid(bidId).then(res => {
      if (res.status) {
        this.currentBidId = bidId;
        this.currentDataBid = JSON.stringify(res.data[0], undefined, 4);
        this.modal.open();
      }
      else this.toastr.error("Not found");
    });
  }
}
