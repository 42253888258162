import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class AddressService {

  controller = 'Addresses';

  constructor(private http: AuthHttp) { }

  getAddressesByLocationId(locationId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetAddressesByLocationId/${locationId}`).toPromise();
  }

  addAddress(data): Promise<any> {
    return this.http.post(`${this.controller}/AddAddress`, JSON.stringify(data)).toPromise();
  }

  updateAddress(addressId: number, data: any): Promise<any> {
    return this.http.put(`${this.controller}/UpdateAddress/${addressId}`, JSON.stringify(data)).toPromise();
  }

  deleteAddress(addressId: number): Promise<any> {
    return this.http.delete(`${this.controller}/DeleteAddress/${addressId}`).toPromise();
  }
}
