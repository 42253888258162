<div class="row">
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Process</label>
      <select [(ngModel)]="processId" (change)="filterData()" class="form-control">
        <option *ngFor="let p of processFilter" [ngValue]="p.processId">{{p.processName}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <fieldset class="filter-type">
      <legend>Process Type Filter</legend>
      <div>
        <label class="radio-inline">
          <input type="radio" name="processTypeId11" [value]="2" [(ngModel)]="processTypeId" (change)="filterProcess();filterData()"> Fabrication
        </label>
        <label class="radio-inline">
          <input type="radio" name="processTypeId11" [value]="3" [(ngModel)]="processTypeId" (change)="filterProcess();filterData()"> Install
        </label>
      </div>
    </fieldset>
  </div>
  <div class="col-md-3 col-xs-12" style="margin-top: 25px; margin-bottom: 15px" *ngIf="!loading && !isTenantOnly">
    <button type="button" class="btn btn-primary" (click)="changeTiers()">Change Tiers</button>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">OUTPUT REDUCTION PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="100">Lower Value</th>
              <th [style.width.px]="100">Upper Value</th>
              <th class="text-center" [style.width.px]="110">Tiered Volume</th>
              <!-- <th class="text-center" [style.width.px]="110">Commercial</th>
              <th class="text-center" [style.width.px]="110">Retail</th>
              <th class="text-center" [style.width.px]="110">Production</th>
              <th class="text-center" [style.width.px]="110">Other</th> -->
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td [style.width.px]="100">
                    <input type="text" class="form-control text-center" formControlName="lowerValue" readonly>
                  </td>
                  <td [style.width.px]="100">
                    <input type="text" class="form-control text-center" formControlName="upperValue" readonly>
                  </td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="percentAdjustment" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <!-- <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="commercial" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="retail" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="production" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="other" (blur)="save()" (keyup.enter)="save()">
                  </td> -->
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalProcessVolumeTiers>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Process Volume Tiers</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="form-horizontal">
      <div class="form-group">
        <label class="col-sm-4 control-label">Process</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" value="{{processName}}" readonly>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-4 control-label">Enter Upper Bound</label>
        <div class="col-sm-4">
          <input #valueAdd type="text" class="form-control" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" [allowNegativeNumbers]="true" [(ngModel)]="addTierValue" (focus)="valueAdd.select()">
        </div>
        <div class="col-sm-3">
          <button type="button" class="btn btn-primary" (click)="addNewTier()">Add New Tier</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Lower Value</th>
                <th>Upper Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tiers">
                <td>
                  <input type="text" class="form-control text-center" value="{{item.lowerValue}}" readonly>
                </td>
                <td>
                  <input type="text" class="form-control text-center" value="{{item.upperValue}}" readonly>
                </td>
                <td>
                  <button type="button" class="btn btn-primary" (click)="removeTier(item.tierId)">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-primary" (click)="modalConfirmSave.open('sm')">Save + Close</button>
    <button type="button" class="btn btn-primary" (click)="modalProcessVolumeTiers.dismiss()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modalConfirmReset.open('sm')">Reset</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmReset>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm reset</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? This will reset the entire process to default tiers.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmReset.dismiss()">No</button>
    <button type="button" class="btn btn-danger" (click)="resetTier()">Yes</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmSave>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm save</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? Any changes to volume tiers will remove existing rules for the specific process.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmSave.dismiss()">No</button>
    <button type="button" class="btn btn-danger" (click)="saveAndClose()">Yes</button>
  </bs-modal-footer>
</bs-modal>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
