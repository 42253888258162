<div class="content-wrapper">
  <section class="content-header">
    <h1>User: {{ userInfo?.fullName }}</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/users"> Users</a></li>
      <li class="active"> {{userInfo?.fullName}}</li>
    </ol>
  </section>

  <section class="content" *ngIf="userInfo">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active"><a href="#userInfo" data-toggle="tab"
            (click)="showSave = true; showResetPass = true; showSendInvitation = true;">User Info</a></li>
        <li><a href="#assignedTenant" data-toggle="tab"
            (click)="showSave = false; showResetPass = false; showSendInvitation = false;">Assigned Tenant(s)</a></li>
        <li><a href="#roles" data-toggle="tab"
            (click)="showSave = tenantRoles.length > 0; showResetPass = false; showSendInvitation = false;">Tenant Roles</a></li>
        <li *ngIf="toggleControlAccessGlobalRoles"><a href="#tabGlobalRoles" data-toggle="tab"
            (click)="showSave = true; showResetPass = false; showSendInvitation = false;">Global Roles</a></li>
        <li><a href="#activityLog" data-toggle="tab"
            (click)="showSave = false; showResetPass = false; showSendInvitation = false;">Activity Log</a></li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box active" id="userInfo">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <form novalidate [formGroup]="editUserForm">
                <div class="row">
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group"
                      [class.has-error]="firstName.invalid && (firstName.dirty || firstName.touched)">
                      <label>First Name <span class="text-danger">*</span></label>
                      <input type="text" trim="blur" class="form-control" formControlName="firstName" maxlength="50"
                        required>
                      <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                        class="help-block">{{getErrorFirstName()}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group"
                      [class.has-error]="lastName.invalid && (lastName.dirty || lastName.touched)">
                      <label>Last Name <span class="text-danger">*</span></label>
                      <input type="text" trim="blur" class="form-control" formControlName="lastName" maxlength="50"
                        required>
                      <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                        class="help-block">{{getErrorLastName()}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group" [class.has-error]="email.invalid && (email.dirty || email.touched)">
                      <label>Email <span class="text-danger">*</span></label>
                      <input type="text" trim="blur" class="form-control" formControlName="email" maxlength="200"
                        required [readonly]="lockEmail ? 'readonly': null" style="padding-right: 23px;">
                      <i class="fa fa-unlock" *ngIf="!lockEmail" (click)="lockEmail = true"
                        style="position: absolute; top: 35px; right: 23px; cursor: pointer;"></i>
                      <i class="fa fa-lock" *ngIf="lockEmail" (click)="lockEmail = false"
                        style="position: absolute; top: 35px; right: 23px; cursor: pointer;"></i>
                      <span *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="help-block">{{getErrorEmail()}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group" [class.has-error]="phone.invalid && (phone.dirty || phone.touched)">
                      <label>Phone</label>
                      <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phone" />
                      <span *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                        class="help-block">{{getErrorPhone()}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group form-inline">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" formControlName="employee" /> Employee</label>
                      </div>
                      <div class="checkbox" style="margin-left: 30px;">
                        <span>
                          <i class="fa fa-lg" style="cursor: pointer"
                            [ngClass]="toggleControlUserActive ? 'fa-toggle-on text-success' : 'fa-toggle-off text-danger'"
                            (click)="toggleUserActive()"></i>
                          Active
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-xs-12">
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" formControlName="staff"/> QXM staff</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-xs-12">
                    <div class="form-group">
                      <div class="checkbox">
                        <span>
                          <i class="fa fa-lg" style="cursor: pointer"
                            [ngClass]="toggleControlAccessGlobalRoles ? 'fa-toggle-on text-success' : 'fa-toggle-off text-danger'"
                            (click)="confirmAccessGlobalRoles()"></i>
                          Access Global Roles
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="tab-pane box" id="assignedTenant">
          <user-assigned-tenants (reload)="reload()" [userId]="userId" [tenants]="assignedTenants"></user-assigned-tenants>
        </div>
        <div class="tab-pane box" id="roles">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default" *ngFor="let tenant of tenantRoles; let i = index">
                  <div class="panel-heading" role="tab" id="heading_{{i}}">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_{{i}}"
                        aria-expanded="true" aria-controls="collapse_{{i}}">
                        {{tenant.tenantName}} {{ countRoleAssigned(tenant) }}<ng-container *ngIf="!tenant.active">(Inactive)</ng-container>
                      </a>
                    </h4>
                  </div>
                  <div id="collapse_{{i}}" class="panel-collapse collapse" role="tabpanel"
                    aria-labelledby="heading_{{i}}">
                    <div class="panel-body">
                      <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th [style.width.px]="40" class="text-center">#</th>
                              <th [style.width.px]="250">Role Name</th>
                              <th>Role Description</th>
                              <th [style.width.px]="120">Module</th>
                              <th [style.width.px]="120">Service Type</th>
                              <th [style.width.px]="110">Client Visible</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let role of tenant.arrRole">
                              <ng-container>
                                <td [style.width.px]="40" class="text-center">
                                  <input type="checkbox" [(ngModel)]="role.isCheck">
                                </td>
                                <td [style.width.px]="250">
                                  <a href="javascript:;" class="text-primary" routerLink="/role-detail/{{role.roleId}}">{{ role.roleName }}</a>
                                </td>
                                <td>{{role.roleDescription}}</td>
                                <td [style.width.px]="120">{{ role.moduleName }}</td>
                                <td [style.width.px]="120">{{ role.serviceType }}</td>
                                <td [style.width.px]="110" class="text-center">{{ !!role.clientVisible ? 'Yes': 'No' }}</td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane box" id="tabGlobalRoles" *ngIf="!!globalRoles && toggleControlAccessGlobalRoles">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="heading_global">
                    <h4 class="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_global"
                        aria-expanded="true" aria-controls="collapse_global">
                        {{globalRoles.tenantName}}
                      </a>
                    </h4>
                  </div>
                  <div id="collapse_global" class="panel-collapse collapse" role="tabpanel"
                    aria-labelledby="heading_global">
                    <div class="panel-body">
                      <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th [style.width.px]="40" class="text-center">#</th>
                              <th [style.width.px]="250">Role Name</th>
                              <th>Role Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let role of globalRoles.arrRole">
                              <ng-container>
                                <td [style.width.px]="40" class="text-center">
                                  <input type="checkbox" [(ngModel)]="role.isCheck">
                                </td>
                                <td [style.width.px]="250">{{role.roleName}}</td>
                                <td>{{role.roleDescription}}</td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane box" id="activityLog">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th [style.width.px]="160">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listDataActivity; let index = index">
                  <td>{{ item.activity }}</td>
                  <td [style.width.px]="160">{{item.modifyTime | date:'MM/dd/yyyy h:mm a'}}</td>
                </tr>
              </tbody>
            </table>
            <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10"
              [page]="paging.pageNumber" [perPage]="paging.itemPerPage" [count]="paginationCount"></my-pagination>
          </div>
          <p class="text-center">
            <i *ngIf="loadLog" class="fa fa-spinner fa-spin fa-2x"></i>
          </p>
        </div>
        <ng-container *ngIf="showSave">
          <button type="button" class="btn btn-primary" [disabled]="editUserForm.invalid || loading"
            (click)="save()">Save</button>
          <button type="button" *ngIf="showResetPass" class="btn btn-danger" style="margin-left: 5px;"
            [disabled]="email.invalid || loading || !(!!userInfo.active && !!userInfo.hasAtleastOneRole)" (click)="modalConfirm.open()">Reset
            Password</button>
          <button type="button" class="btn btn-warning" style="margin-left: 5px;"
            *ngIf="showSendInvitation" [disabled]="loading || !!!userInfo.hasAtleastOneRole" (click)="resendInvitation()">Send Invitation</button>
          <a type="button" class="btn btn-default" style="margin-left: 5px;" routerLink="/users" *ngIf="!loading">Cancel</a>
          <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg" style="margin-left: 15px;"></i>
        </ng-container>
      </div>
    </div>
  </section>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to reset password for this user?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="resetPassword()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmTurnOfGlobalRoles>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? This will remove all global roles from user.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-primary" (click)="toggleControlGlobalRoles()">Yes</button>
    <button type="button" class="btn btn-default" (click)="modalConfirmTurnOfGlobalRoles.close()">No</button>
  </bs-modal-footer>
</bs-modal>
