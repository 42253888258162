<div class="content-wrapper">
  <section class="content-header">
    <h1>Tenant Roles</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Roles</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label class="control-label">Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-12">
        <div class="form-group" *ngIf="!loading && tenantId > 0">
          <label class="control-label">Module</label>
          <select [(ngModel)]="moduleSelectedId" (change)="changeModule()" class="form-control">
            <option [value]="1">Sales</option>
            <option [value]="2">Production Sales</option>
            <option [value]="3">Scheduling</option>
            <option [value]="4">Pricing Manager</option>
            <option [value]="5">Admin</option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-12">
        <div class="form-group" *ngIf="!loading && tenantId > 0">
          <label class="control-label">Service Type</label>
          <select [(ngModel)]="serviceSelectedId" (change)="changeService()" class="form-control">
            <option [value]="0">All</option>
            <option [value]="1" *ngIf="moduleSelectedId == 1 || moduleSelectedId == 2 || moduleSelectedId == 3">Slab</option>
            <option [value]="2" *ngIf="moduleSelectedId == 1">Tile</option>
          </select>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12">
        <label class="control-label"></label>
        <div class="form-group filterVisible" *ngIf="!loading && tenantId > 0">
          <div>
            <label class="radio-inline">
              <input type="radio" name="filterVisible" [value]="0" [(ngModel)]="filterVisible"
                (change)="changeFilterVisible()"> All
            </label>
            <label class="radio-inline">
              <input type="radio" name="filterVisible" [value]="1" [(ngModel)]="filterVisible"
                (change)="changeFilterVisible()"> Client Visible
            </label>
            <label class="radio-inline">
              <input type="radio" name="filterVisible" [value]="2" [(ngModel)]="filterVisible"
                (change)="changeFilterVisible()"> QXM Only
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-12">
        <button type="button" class="btn btn-primary btn-add-new-role" *ngIf="!loading && tenantId > 0 && moduleSelectedId != 4 && moduleSelectedId != 5" (click)="modalAddNewRole.open()">Add New Role</button>
      </div>
    </div>

    <h4 style="line-height: 1.5" *ngIf="loading">Loading...</h4>

    <div class="table-responsive" *ngIf="!loading && tenantId > 0">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Role Name</th>
            <th>Description</th>
            <th>Service Type</th>
            <th>Client Visible</th>
            <th>QXM Only</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of rolesFilter">
            <td class="text-left">
              <a href="javascript:;" class="text-primary" routerLink="/role-detail/{{item.roleId}}">{{ item.roleName }}</a>
            </td>
            <td>{{ item.roleDescription }}</td>
            <td>{{ item.services }}</td>
            <td>{{ !!item.clientVisible ? 'Yes' : 'No' }}</td>
            <td>{{ !!item.qxmOnly ? 'Yes' : 'No' }}</td>
            <td>{{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<bs-modal #modalAddNewRole>
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Add new role</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form [formGroup]="addRoleForm" novalidate>
      <div class="col-12">
        <div class="form-group"
          [class.has-error]="roleName.invalid && (roleName.dirty || roleName.touched)">
          <label class="control-label">Role Name (*)</label>
          <input type="text" trim="blur" class="form-control" placeholder="Role Name"
            formControlName="roleName" maxlength="50" required>
          <span *ngIf="roleName.invalid && (roleName.dirty || roleName.touched)"
            class="help-block">{{getErrorRoleName()}}&nbsp;</span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Role Description</label>
          <input type="text" trim="blur" class="form-control" placeholder="Role Description"
            formControlName="roleDescription" maxlength="250">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Service Type</label>
          <select class="form-control" formControlName="serviceId">
            <option value="1">Slab</option>
            <option value="2" *ngIf="moduleSelectedId != 2">Tile</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <!-- <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="estimator"> Estimator
          </label>
        </div> -->
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="qaTester"> QA Tester (Special Roles)
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="qxmOnly"> QXM Only (Special Roles)
          </label>
        </div>
      </div>
    </form>
    <div class="col-12">
      <table class="table table-bordered table-hover table-sm">
        <thead class="border-less">
          <tr>
            <th class="text-center"></th>
            <th>License Types</th>
            <th>Descriptions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let x of listLicenseType">
            <td [style.width.px]="100" class="text-center">
              <input type="checkbox" [(ngModel)]="x.checked" />
            </td>
            <td [style.width.px]="200">
              {{x.licenseType}}
            </td>
            <td>
              {{x.description}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <div class="row" style="margin: 0;">
      <div class="col-6" style="float: left;">
        <form [formGroup]="addRoleForm" novalidate>
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="clientVisible"> Client Visible
            </label>
          </div>
        </form>
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-default" (click)="modalAddNewRole.close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="addNewRole(); modalAddNewRole.close();" [disabled]="addRoleForm.invalid">Save</button>
      </div>
    </div>
  </bs-modal-footer>
</bs-modal>
