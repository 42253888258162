<div class="content-wrapper">
  <section class="content-header">
    <h1>Dashboards</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/dashboards"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Dashboards</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="loadingTenant">
      <p class="text-center">
        <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loadingTenant"></i>
      </p>
    </div>
    <div *ngIf="!loadingTenant && tenantId > 0">
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="addNewDashboard()">Add New</button>
      </div>
      <h4 style="line-height: 1.5" *ngIf="!dashboards">{{ waiting_loading }}</h4>
      <div class="table-responsive" *ngIf="dashboards">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">#</th>
              <th style="cursor: pointer;" (click)="changeFilter('dashboardName')">Dashboard Name
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'asc'"></i>
              </th>
              <th>Dashboard Description</th>
              <th>Menu Name</th>
              <th style="cursor: pointer;" (click)="changeFilter('dashboardCategoryName')">Dashboard Category
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'asc'"></i>
              </th>
              <th [style.width.px]="110" class="text-center">Standard</th>
              <th>Tenant Source Folder</th>
              <th style="cursor: pointer;" (click)="changeFilter('sourceDashboard')">Source Dashboard
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'sourceDashboard' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'sourceDashboard' && filter.order == 'asc'"></i>
              </th>

              <th [style.width.px]="110" class="text-center">Assign Roles</th>
              <th [style.width.px]="80" class="text-center">Active</th>
              <th [style.width.px]="80" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dashboards; let index = index">
              <td [style.width.px]="40" class="text-center">{{ filter.itemPerPage * (filter.pageNumber - 1) + index + 1 }}</td>
              <td>{{ item.dashboardName }}</td>
              <td>{{ item.dashboardDescription }}</td>
              <td>{{ item.menuName }}</td>
              <td>{{ item.dashboardCategoryName }}</td>
              <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.isStandard != 1 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="setDashboardStandard(item, !item.isStandard)"></i>
              </td>
              <td>{{ item.tenantSourceFolder }}</td>
              <td>{{ item.sourceDashboard }}</td>
              <td [style.width.px]="110" class="text-center">
                <a href="javascript:;" (click)="assignRoles(item.dashboardId)" title="Assign Roles" class="fa fa-lg fa-pencil-square-o text-success"></a>
              </td>
              <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="activeDashboard(item, !item.active)"></i>
              </td>
              <td [style.width.px]="80" class="text-center">
                <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit" (click)="update(item)"></a>&nbsp;&nbsp;&nbsp;
                <a href="javascript:;" class="fa fa-lg fa-trash-o text-danger" title="Delete" (click)="deleteDashboard(item.dashboardId)"></a>
              </td>
            </tr>
          </tbody>
        </table>
        <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
      </div>
      <div>
        <center>{{ waiting_loading }}</center>
      </div>
    </div>
  </section>
</div>

<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="form">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li class="active"><a href="#info" data-toggle="tab">Information</a></li>
          <li><a href="#parameters" data-toggle="tab">Parameters</a></li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane box active" id="info">
            <div class="form-group" [class.has-error]="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
              <label>Dashboard Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" trim="blur" class="form-control" formControlName="dashboardName" maxlength="250" required>
              <ng-container *ngIf="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
                <span *ngIf="dashboardName.errors.required" class="help-block">Dashboard name is required.</span>
                <span *ngIf="dashboardName.errors.isExistDashboard" class="help-block">{{error_message}}</span>
              </ng-container>
            </div>
            <div class="form-group" [class.has-error]="menuName.invalid && (menuName.dirty || menuName.touched)">
              <label>Menu Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" trim="blur" class="form-control" style="resize: none" formControlName="menuName" required>
              <ng-container *ngIf="menuName.invalid && (menuName.dirty || menuName.touched)">
                <span *ngIf="menuName.errors.required" class="help-block">Menu name is required.</span>
              </ng-container>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea type="text" trim="blur" class="form-control" style="resize: none" formControlName="dashboardDescription"></textarea>
            </div>
            <div class="form-group" [class.has-error]="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)">
              <label>Dashboard Category
                <span class="text-danger">*</span>
              </label>
              <select formControlName="dashboardCategoryId" class="form-control" required>
                <ng-container *ngFor="let rc of dashboardCategories">
                  <option [ngValue]="rc.id">{{rc.name}}</option>
                </ng-container>
              </select>
              <span *ngIf="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)" class="help-block">{{getErrorDashboardCategoryId()}}</span>
            </div>
            <div class="form-group" [class.has-error]="dashboardObjectName.invalid && (dashboardObjectName.dirty || dashboardObjectName.touched)">
              <label>Source Dashboard
                <span class="text-danger">*</span>
              </label>
              <select formControlName="dashboardObjectName" class="form-control" (change)="setSourceDashboard()" required>
                <ng-container *ngFor="let t of dashboardsOfTenantFromActiveDashboard">
                  <option [ngValue]="t.id">{{t.title}}</option>
                  <!--<option [ngValue]="t._id">{{t.Name}}</option>-->
                </ng-container>
              </select>
              <!-- <input type="text" trim="blur" class="form-control" formControlName="dashboardObjectName" maxlength="200" required> -->
              <span *ngIf="dashboardObjectName.invalid && (dashboardObjectName.dirty || dashboardObjectName.touched)" class="help-block">{{getErrorDashboardObjectName()}}</span>
            </div>
          </div>
          <div class="tab-pane box" id="parameters">
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterTenantId" required> TenantId <span class="text-danger">* (Will be required on all dashboards)</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterUserId"> UserId
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterSelectorUserId"> SelectorUserId
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterAccountId"> AccountId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterProjectId"> ProjectId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterBidId"> BidId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterContractId"> ContractId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterCommunityId"> CommunityId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterQuoteId"> QuoteId (Implement later)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="parameterPriceSchemaId"> PriceSchemaId (Implement later)
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loading_submit" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px;"></i>
    <button type="button" [disabled]="loading_submit" class="btn btn-default" (click)="modal.close()">Cancel</button>
    <button type="button" [disabled]="form.invalid || loading_submit" class="btn btn-primary" (click)="submit()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure delete this dashboard?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmDelete.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAssignRole>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Assign role of dashboard for this tenant</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="form-group">
      <div class="checkbox" *ngFor="let role of listRoles; let i = index">
        <label>
          <input type="checkbox" [(ngModel)]="role.isCheck">{{role.roleName}}
        </label>
      </div>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAssignRole.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submitAssignRole()">Submit</button>
  </bs-modal-footer>
</bs-modal>
