import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { UserDetailService } from '../../user-detail.service';
import * as _ from 'lodash';

@Component({
  selector: 'user-assigned-tenants',
  templateUrl: './assigned-tenants.html'
})

export class UserAssignedTenantsComponent implements OnInit {
  @Input() userId: number;
  @Input() tenants: any[];
  @ViewChild('modalAddTenant') modalAddTenant: BsModalComponent;
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirmRemove') modalConfirmRemove: BsModalComponent;
  @Output() reload = new EventEmitter();
  tmpTenants: any[];
  disableAssign = false;
  assignedTenants: any[];
  licenseTypes: any[];
  licenseValidate: any;
  valueValidate: any;
  expanded = [];

  tenantIdRemove: number = 0;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private userDetailService: UserDetailService) {
  }

  ngOnInit() {
    this.loadData();
    this.tmpTenants = JSON.parse(JSON.stringify(this.tenants.filter(x => !x.isAssigned)));
  }
  submit() {
    this.disableAssign = true;
    const data = [];
    this.assignedTenants.forEach(e => {
      const childs = e.childs || [];
      childs.forEach(c => {
        data.push({
          userId: Number(this.userId),
          moduleId: Number(c.moduleId),
          licenseTypeId: Number(c.licenseTypeId),
          tenantId: Number(e.tenantId)
        });
      });
      if (!!e?.isModify) this.expanded.push(e.tenantId);
    });
    this.userService.applyChangeUserLicenseType(data).then((res) => {
      if (res.status) {
        this.toastr.success('Licenses Type has been updated.');
        this.reload.emit();
      }
      else {
        this.toastr.error('Licenses Type hasn\'t been updated.');
      }
      this.loadData();
      this.modal.close(true);
    }).catch(() => this.disableAssign = false);
  }

  validate(license, newValue) {
    this.licenseValidate = license;
    this.valueValidate = newValue;
    if (license.id == 0) this.confirm(true);
    else this.modal.open();
  }

  confirm(ok) {
    const license = this.licenseValidate;
    const newValue = this.valueValidate;
    if (!ok) {
      license.licenseTypeId = license.ref;
      this.modal.close();
      return;
    }

    this.userService.validateUserLicenseType({
      userId: Number(this.userId),
      moduleId: Number(license.moduleId),
      licenseTypeId: Number(newValue),
      tenantId: Number(license.tenantId)
    }).then(response => {
      this.modal.close();
      if (!response.status) {
        this.toastr.error(response.message);
        license.licenseTypeId = license.ref;
        return;
      }
      license.ref = license.licenseTypeId;
      this.submit();
    });
  }

  private loadData() {
    this.userService.getUserLicenseTypesByModuleId(-1).then(res => {
      if (!res.status) {
        this.toastr.error(res.message);
        return;
      }
      this.licenseTypes = res.data;

      this.userService.getUserModuleLicenseTypes(this.userId).then(res => {
        if (!res.status) {
          this.toastr.error(res.message);
          return;
        }
        const data = res.data;
        const uniqueTenantIds = [...new Set(data.map(item => item.tenantId))];
        this.assignedTenants = [];
        for (const tenantId of uniqueTenantIds) {
          const childs = [...data].filter(x => Number(x.tenantId) === Number(tenantId)) || [];
          for (const child of childs) {
            child.ref = child.licenseTypeId;
          }
          const countAssigned = [...childs].filter(x => x.licenseTypeId > 0).length;
          const isModify = this.expanded.indexOf(tenantId) !== -1;
          this.assignedTenants.push({ tenantId, tenantName: childs[0].tenantName, childs, countAssigned, isModify });
        }
      });
    });
  }

  getDisplayLicenseType(data: any) {
    return `${data.licenseType} (${Number(data.maxUser) - Number(data.assignedUserCount)} of ${Number(data.maxUser)} Available)`;
  }

  getLicenseTypesByModuleId(moduleId: number, tenantId: number) {
    return this.licenseTypes?.filter(x => Number(x.moduleId) === moduleId && Number(x.tenantId) === tenantId);
  }

  assignLicenseType(data, id: number, moduleId: number, licenseTypeId: number, tenantId: number) {
    if (!licenseTypeId) {
      this.userService.removeUserLicenseTypeAndRoles(id).then(response => {
        if (!response.status) {
          this.toastr.error(response.message);
          return;
        }
        this.toastr.success(response.message);
      });
    }
    else {
      this.userService.assignUserLicenseType(this.userId, moduleId, licenseTypeId, tenantId).then(response => {
        if (!response.status) {
          this.toastr.error(response.message);
          data.licenseTypeId = data.ref;
          return;
        }
        data.ref = data.licenseTypeId;
        this.loadData();
        this.toastr.success(response.message);
      });
    }
  }

  openModal() {
    this.modalAddTenant.open();
  }

  addTenants() {
    this.disableAssign = true;
    const tenantIds = this.tmpTenants.filter(x => x.isAssigned).map(x => x.tenantId);

    this.userService.assignTenantsToUser(this.userId, tenantIds).then(response => {
      this.disableAssign = false;
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.toastr.success(response.message);

      // Remove assigned tenant from tenants list
      this.tmpTenants = this.tmpTenants.filter(x => !x.isAssigned);

      this.modalAddTenant.close();
      this.loadData();

      this.userDetailService.sendMessage(
        UserDetailService.MessageTypes.RELOAD_PARENT_COMP
      );
    });
  }

  remove(tenantId: number) {
    this.userService.removeTenantFromUser(this.userId, tenantId).then(response => {
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.toastr.success(response.message);
      const item = this.tenants.find(x => x.tenantId == tenantId);
      if (!item) { return; }
      this.tmpTenants.push(item);
      this.loadData();
      this.modalConfirmRemove.close(true);

      this.userDetailService.sendMessage(
        UserDetailService.MessageTypes.RELOAD_PARENT_COMP
      );
    });
  }

  disabledBtn() {
    return this.disableAssign || this.tmpTenants?.length == 0 || this.tmpTenants?.filter(x => x.isAssigned).length == 0;
  }

  openModalConfirmRemove(tenantId: number) {
    this.tenantIdRemove = tenantId;
    this.modalConfirmRemove.open();
  }

  confirmRemove(isOk) {
    if (isOk) this.remove(this.tenantIdRemove);
    else this.modalConfirmRemove.close(true);
  }

  cancelModify(item) {
    item.isModify = !!!item.isModify;
    _.remove(this.expanded, function(x) { return x == item.tenantId; });
  }
}
