import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ViewCommentComponent } from '../view-comment/view-comment';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';
//import { ViewCommentComponent } from '../view-comment/view-comment.component';

@Component({
    selector: 'tab-process-standard',
    templateUrl: './process-standard.html',
    styleUrls: ['./process-standard.scss']
})

export class ProcessStandardComponent implements OnInit, OnDestroy {
    @Input('costSchemaId') costSchemaId: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('note') note: ElementRef;
    notes: any[];
    @ViewChild('modalViewComments') modalViewComments: any;
    processTypeId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    currentTab = 'ProcessStandard';
    currentId: number;
    constructor(private fb: FormBuilder, private toast: ToastrService,
        private settingService: SettingService, private userService: UserService, private slabCostDetailService: SlabCostDetailService) { }

    ngOnDestroy() {
        this.save();
    }

    ngOnInit() {
        this.processTypeId = 2;
        this.loadData();
    }

    loadData() {
        this.loading = true;
        this.settingService.getProcessStandard(this.costSchemaId).then(response => {
            if (response.status) {
                this.data = response.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
            else this.toast.error("Load data failed");
        });
    }

    viewComment(item) {
        this.currentId = item.value.schemaProcessId;
        this.settingService.getMtxNoteProcessStandardById(this.currentId).then(res => {
            if (res.status) {
                this.notes = res.data;
                this.modalViewComments.open();

                //Update last comment when delete comment
                this.slabCostDetailService.getMessages().subscribe(data => {
                    if (!data) {
                        return;
                    }

                    switch (data.messageType) {
                        case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                            const payload = data.payload;
                            if (payload) {
                                item.controls.note.setValue(payload.note);
                                item.controls.updatedOn.setValue(payload.updateOn);
                            }
                            break;
                    }
                });
            }
            else this.toast.error('view comment failed.');
        });
    }

    filterData() {
        this.dataFilter = this.data.filter(e => e.processTypeId == this.processTypeId);// && e.channelId == this.channelId);
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });
        if (this.userService.isTenantOnly()) this.dataForm.disable();
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            schemaProcessId: e.schemaProcessId,
            processName: e.processName,
            unit: e.unit,
            outputPerHour: new DecimalPipe('en-US').transform(e.outputPerHour, '.2-2'),
            stdHourlyRate: [{ value: new CurrencyPipe('en-US').transform(e.stdHourlyRate), disabled: true }],
            laborCostPerHourOverride: new CurrencyPipe('en-US').transform(e.laborCostPerHourOverride),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.rateId == e.rateId);
        item.outputPerHour = this.settingService.replaceMask(e.outputPerHour);
        item.laborCostPerHourOverride = this.settingService.replaceMask(e.laborCostPerHourOverride);
        item.note = e.note;
        const cell = this.data.find(i => i.schemaProcessId == e.schemaProcessId);
        if (!!cell) {
            cell.outputPerHour = this.settingService.replaceMask(e.outputPerHour);
            cell.laborCostPerHourOverride = this.settingService.replaceMask(e.laborCostPerHourOverride);
            cell.note = e.note;
        }
        return {
            schemaProcessId: e.schemaProcessId,
            outputPerHour: this.settingService.replaceMask(e.outputPerHour),
            laborCostPerHourOverride: this.settingService.replaceMask(e.laborCostPerHourOverride),
            note: e.note,
        };
    }

    save() {
        let dataUpdate = [];
        let cellUpdate: AbstractControl;
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                cellUpdate = e;
                dataUpdate.push(this.createItemUpdate(e.value));
                e.markAsPristine();
                e.markAsUntouched();
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateProcessStandard(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Process standard has been updated');
                    cellUpdate?.get('updatedOn').setValue(response.data);
                    if (dataUpdate.length > 0) {
                        this.reloadStatusBatchVersions.emit();
                    }
                }
                else this.toast.error('Update process standard failed');
            });
        }
    }
}
