<div class="content-wrapper">
  <section class="content-header">
    <h1>Log Of Batch {{batchId}}</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/compare-tool"><i class="fa fa-balance-scale"></i> Compare Tool</a></li>
      <li class="active">Log Of Batch {{batchId}}</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
        <div class="form-group">
          <label class="control-label">Filter by Status</label>
          <select #cmbStatus (change)="filter(cmbStatus.value)" class="form-control">
            <option [ngValue]="'ALL'">All</option>
            <option [ngValue]="'PASS'">Pass</option>
            <option [ngValue]="'FAIL'">Fail</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-xs-6">
        <label>Search by bid Id</label>
        <div class="input-group">
          <input #txtBidId type="text" class="form-control" placeholder="Bid Id" (keyup.enter)="showLog(txtBidId.value)">
          <span class="input-group-btn">
            <button class="btn btn-primary" type="button" (click)="showLog(txtBidId.value)">Show log</button>
          </span>
        </div>
      </div>
    </div>
    
    <h4 style="line-height: 1.5" *ngIf="!dataBidFilter">Loading...</h4>
    <h4 style="line-height: 1.5" *ngIf="dataBid">Bid status: Pass {{numOfPass}}, Fail {{numOfFail}}</h4>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="80">Bid Id</th>
            <th [style.width.px]="80">Status</th>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataBidFilter">
            <td [style.width.px]="80">{{item.bidId}}</td>
            <td [style.width.px]="80">{{item.status}}</td>
            <td><button type="button" class="btn btn-primary" (click)="showLog(item.bidId)">Show log</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">View log of bid id {{currentDataBid?.bidId}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <pre [innerHTML]="currentDataBid?.log"></pre>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
  </bs-modal-footer>
</bs-modal>
