import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { DecimalPipe, CurrencyPipe, PercentPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';

@Component({
  selector: 'tab-supply-overrides',
  templateUrl: './supply-overrides.html',
  styleUrls: ['./supply-overrides.scss']
})

export class SupplyOverridesComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  @Input() channels: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes: any[];
  channelId: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  currentTab = 'SupplyOverrides';
  currentId: number;

  constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService,
    private slabCostDetailService: SlabCostDetailService) {

  }

  ngOnInit() {
    this.channelId = this.channels[0].channelId;
    this.loadData();
  }
  ngOnDestroy() {
    this.save();
  }
  viewComment(item) {
    this.currentId = item.value.appSupplyRateId;
    this.settingService.getMtxNoteSupplyOverridesById(this.currentId).then(res => {
      if (res.status) {
        this.notes = res.data;
        this.modalViewComments.open();

        //Update last comment when delete comment
        this.slabCostDetailService.getMessages().subscribe(data => {
          if (!data) {
            return;
          }

          switch (data.messageType) {
            case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
              const payload = data.payload;
              if (payload) {
                item.controls.note.setValue(payload.note);
                item.controls.updatedOn.setValue(payload.updateOn);
              }
              break;
          }
        });
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData() {
    this.loading = true;
    const data = [];
    this.settingService.getSupplyOverride(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.data.forEach(e => {
          data.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
          dataUpdate: this.fb.array(data)
        });
        if (this.userService.isTenantOnly()) this.dataForm.disable();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      costSchemaId: e.costSchemaId,
      appSupplyRateId: e.appSupplyRateId,
      applicationName: e.applicationName,
      supplyBaseRate: new DecimalPipe('en-US').transform(e.supplyBaseRate, '.2-2'),
      defaultSupplyRate: [{ value: new CurrencyPipe('en-US').transform(e.defaultSupplyRate), disabled: true }],
      useDefault: e.useDefault,
      note: e.note,
      updatedOn: e.updatedOn
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.appSupplyRateId == e.appSupplyRateId);
    item.supplyBaseRate = this.settingService.replaceMask(e.supplyBaseRate);
    item.useDefault = e.useDefault,
      item.note = e.note;
    return {
      costSchemaId: e.costSchemaId,
      appSupplyRateId: e.appSupplyRateId,
      supplyBaseRate: this.settingService.replaceMask(e.supplyBaseRate),
      useDefault: e.useDefault,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateSupplyOverride(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Supply overrides has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          // if (dataUpdate.length > 0) {
          //               this.settingService.setBidsIsRefresh(this.costSchemaId, this.channelId);
          //               this.reloadStatusBatchVersions.emit();
          //           }
        }
        else this.toast.error('Update supply overrides failed');
      });
    }
  }
}
