<div class="content-wrapper">
  <div *ngIf="!!tenantChannel?.dataForm?.dirty" class="alert alert-warning" role="alert">
    <strong>Be sure to apply changes to have the changes take affect.</strong>
  </div>
  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Tile Configuration</a></span> | <span><a class="sub-menu-txt">Channel Assignments</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Channel Assignments</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Select Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant2()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-9 col-sm-8 col-xs-12">
        <button
          style="margin-top: 25px;"
          class="btn btn-primary"
          type="button"
          [disabled]="enabledEdit && !tenantChannel?.dataForm?.dirty && !tenantChannelsService.isDirty()"
          (click)="apply()"
          *ngIf="!loadingTenant && tenantId > 0 && curTab.id == 'tenantChannels'">
          {{ enabledEdit ? 'Apply Changes' : 'Edit Channels' }}
        </button>
      </div>
    </div>

    <div class="nav-tabs-custom" *ngIf="!loadingTenant && tenantId > 0">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box" id="tenantChannels" *ngIf="curTab.id == 'tenantChannels'">
          <tile-tenant-channels [tenantId]="tenantId" [enabledEdit]="enabledEdit" (calcHeightTable)="calcHeightTable('tenantChannels')"></tile-tenant-channels>
        </div>
        <div class="tab-pane box" id="changeLog" *ngIf="curTab.id == 'changeLog'">
          <tile-tenant-channel-change-log [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('changeLog')"></tile-tenant-channel-change-log>
        </div>
      </div>
    </div>
  </section>
</div>
