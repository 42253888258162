import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tab-tile-trim',
  templateUrl: './trim.html',
})

export class TileTrimComponent implements OnInit {
  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

  curTab: number;

  constructor() {

  }

  ngOnInit() {
    this.curTab = 1;
  }

  change(tabIndex: number){
    if(this.curTab == tabIndex) return;
    this.curTab = tabIndex;
  }
}
