import { Component, ViewChild, OnInit, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';

@Component({
    selector: 'tab-tiered-volume-sizing',
    templateUrl: './tiered-volume-sizing.html',
    styleUrls: ['./tiered-volume-sizing.scss']
})

export class TieredVolumeSizingComponent implements OnInit, OnDestroy {
    @Input() costSchemaId: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('modalProcessVolumeTiers') modalProcessVolumeTiers: BsModalComponent;
    @ViewChild('modalConfirmReset') modalConfirmReset: BsModalComponent;
    @ViewChild('modalConfirmSave') modalConfirmSave: BsModalComponent;
    @ViewChild('valueAdd') txtValueAdd: ElementRef;
    @ViewChild('modalViewComments') modalViewComments: any;
    notes: any[];
    beginFilterData = new EventEmitter<any>();
    countToBeginFilterData = 0;

    processes: any;
    processId: any;
    processFilter: any;
    processTypeId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    currentTab = 'TieredVolumeSizing';
    currentId: number;

    processName = "";
    tiers = [];
    addTierValue = "0";

    isTenantOnly: boolean = false;

    constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService, private userService: UserService,
        private slabCostDetailService: SlabCostDetailService) {
        this.isTenantOnly = this.userService.isTenantOnly()
        this.beginFilterData.subscribe(() => {
            this.countToBeginFilterData = this.countToBeginFilterData + 1;
            if (this.countToBeginFilterData == 2) {
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
        });
    }

    ngOnInit() {
        this.processTypeId = 2;
        this.loadData();
    }
    ngOnDestroy() {
        this.save();
    }
    viewComment(item) {
        this.currentId = item.value.id;
        this.settingService.getMtxNoteTiredVolumeSizingById(this.currentId).then(res => {
            if (res.status) {
                this.notes = res.data;
                // this.viewComments.open();
                this.modalViewComments.open();

                //Update last comment when delete comment
                this.slabCostDetailService.getMessages().subscribe(data => {
                    if (!data) {
                        return;
                    }

                    switch (data.messageType) {
                        case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                            const payload = data.payload;
                            if (payload) {
                                item.controls.note.setValue(payload.note);
                                item.controls.updatedOn.setValue(payload.updateOn);
                            }
                            break;
                    }
                });
            }
            else this.toast.error('view comment failed.');
        });
    }
    loadData(resetProcessId = true) {
        this.loading = true;
        this.countToBeginFilterData = 0;

        this.settingService.getProcess(this.costSchemaId).then(response => {
            if (response.status) {
                this.processes = response.data;
                this.filterProcess(resetProcessId);
                this.beginFilterData.emit();
            }
            else this.toast.error('Can\'t get list process');
        });

        this.settingService.getTieredVolumeSizing(this.costSchemaId).then(response => {
            if (response.status) {
                this.data = response.data;
                this.beginFilterData.emit();
            }
            else this.toast.error("Load data failed");
        });
    }

    filterProcess(resetProcessId = true) {
        this.processFilter = this.processes.filter(e => e.processTypeId == this.processTypeId);
        if (resetProcessId) this.processId = this.processFilter[0].processId;
    }

    filterData() {
        this.dataFilter = this.data.filter(e => e.processId == this.processId);
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });
        if (this.isTenantOnly) this.dataForm.disable();
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            id: e.id,
            lowerValue: new DecimalPipe('en-US').transform(e.lowerValue, '.0-2'),
            upperValue: new DecimalPipe('en-US').transform(e.upperValue, '.0-2'),
            percentAdjustment: new PercentPipe('en-US').transform(e.percentAdjustment, '.2-2'),
            commercial: new PercentPipe('en-US').transform(e.commercial, '.2-2'),
            retail: new PercentPipe('en-US').transform(e.retail, '.2-2'),
            production: new PercentPipe('en-US').transform(e.production, '.2-2'),
            other: new PercentPipe('en-US').transform(e.other, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.id == e.id);
        item.percentAdjustment = this.settingService.replaceMask(e.percentAdjustment) / 100;
        item.commercial = this.settingService.replaceMask(e.commercial) / 100;
        item.retail = this.settingService.replaceMask(e.retail) / 100;
        item.production = this.settingService.replaceMask(e.production) / 100;
        item.other = this.settingService.replaceMask(e.other) / 100;
        item.note = e.note;
        return {
            id: e.id,
            percentAdjustment: this.settingService.replaceMask(e.percentAdjustment) / 100,
            commercial: this.settingService.replaceMask(e.commercial) / 100,
            retail: this.settingService.replaceMask(e.retail) / 100,
            production: this.settingService.replaceMask(e.production) / 100,
            other: this.settingService.replaceMask(e.other) / 100,
            note: e.note
        };
    }

    save() {
        let dataUpdate = [];
        let cellUpdate: any;
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                cellUpdate = e;
                dataUpdate.push(this.createItemUpdate(e.value));
                e.markAsPristine();
                e.markAsUntouched();
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateTieredVolumeSizing(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Tiered volume sizing has been updated');
                    cellUpdate?.get('updatedOn').setValue(response.data);
                    if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
                }
                else this.toast.error('Update tiered volume sizing failed');
            });
        }
    }

    changeTiers() {
        this.loadTier();
        this.modalProcessVolumeTiers.open();
        setTimeout(() => {
            this.txtValueAdd.nativeElement.focus();
        }, 500);
        this.processName = this.processes.find(p => p.processId == this.processId).processName;
    }

    loadTier() {
        this.settingService.getProcessVolumeTiers(this.costSchemaId, this.processId).then(response => {
            if (response.status) this.tiers = response.data;
            else this.toast.error('Load process volume tiers failed');
        });
    }

    addNewTier() {
        const value = this.settingService.replaceMask(this.addTierValue);
        if (value && value > 0) {
            this.settingService.addNewTier(this.costSchemaId, this.processId, value).then(response => {
                if (response.status) {
                    this.toast.success('New tier has been added');
                    this.addTierValue = "0";
                    setTimeout(() => {
                        this.txtValueAdd.nativeElement.focus();
                    }, 500);
                    this.loadTier();
                }
                else this.toast.error('Add new tier failed');
            });
        }
    }

    removeTier(tierId) {
        if (tierId) {
            this.settingService.removeTier(this.costSchemaId, this.processId, tierId).then(response => {
                if (response.status) {
                    this.toast.success('Tier has been removed');
                    this.loadTier();
                }
                else this.toast.error('Remove tier failed');
            });
        }
    }

    resetTier() {
        this.settingService.resetTier(this.costSchemaId, this.processId).then(response => {
            if (response.status) {
                this.modalConfirmReset.dismiss();
                this.toast.success('Tier has been reset');
                this.loadTier();
            }
            else this.toast.error('Reset tier failed');
        });
    }

    saveAndClose() {
        this.settingService.saveTier(this.costSchemaId, this.processId).then(response => {
            if (response.status) {
                this.modalConfirmSave.dismiss();
                this.modalProcessVolumeTiers.dismiss();
                this.toast.success('Tier has been saved');
                this.loadData(false);
            }
            else this.toast.error('Save tier failed');
        });
    }
}
