import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { CompareService } from '../../services/compare.service';
declare var $: any;

@Component({
  templateUrl: './log-of-batch.html'
})

export class LogOfBatchComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  batchId: any;
  dataBid: any;
  dataBidFilter: any;
  currentDataBid: any;
  numOfPass = 0;
  numOfFail = 0;

  constructor(activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private compareService: CompareService) {

    activatedRoute.params.subscribe(params => {
      this.batchId = params.id;
      if (!Number(this.batchId)) this.router.navigate(['/not-found']);
      else this.loadData();
    });
  }

  ngOnInit() {
    $('body').layout('fix');
  }

  loadData() {
    this.dataBid = null;
    this.compareService.getLogByBatchId(this.batchId).then(res => {
      if (res.status) {
        this.dataBid = res.data || [];
        this.dataBid.map(i => i.log = JSON.stringify(JSON.parse(i.log), undefined, 4));
        this.dataBidFilter = this.dataBid;
        this.numOfPass = this.dataBid.filter(b => b.status == 'PASS').length;
        this.numOfFail = this.dataBid.filter(b => b.status == 'FAIL').length;
      }
      else this.toastr.error("Get log of batch failed");
    });
  }

  filter(e) {
    switch(e) {
      case "All": this.dataBidFilter = this.dataBid; break;
      case "Pass": this.dataBidFilter = this.dataBid.filter(b => b.status == 'PASS'); break;
      case "Fail": this.dataBidFilter = this.dataBid.filter(b => b.status == 'FAIL'); break;
    }
  }

  showLog(bidId) {
    this.currentDataBid = this.dataBid.find(b => b.bidId == bidId);
    if (this.currentDataBid) this.modal.open();
    else this.toastr.error("Not found");
  }
}
