<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Default Tax Regions</h4>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Sub-Type</th>
              <th>Tax Region</th>
              <th [style.width.px]="120">Sales Tax %</th>
              <th [style.width.px]="120">Material Tax %</th>
              <th [style.width.px]="120">Supplies Tax %</th>
              <th [style.width.px]="120">Items Tax %</th>
              <th [style.width.px]="120">Labor Tax %</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <input type="text" class="form-control" formControlName="channelName" readonly>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="bidSubTypeName" readonly>
                  </td>
                  <td>
                    <select class="form-control" formControlName="defaultTaxRegionId" (change)="save()">
                      <option [value]=""></option>
                      <option *ngFor="let t of taxAssignments" [value]="t.taxAssignmentId">{{t.assignmentName}}</option>
                    </select>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="salesTaxRate" readonly>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="materialCostRate" readonly>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="suppliesCostRate" readonly>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="itemCostRate" readonly>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="laborCostRate" readonly>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
