<div class="content-wrapper">
  <section class="content-header">
    <h1>Permissions</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Permissions</li>
    </ol>
  </section>

  <section class="content">

    <h4 style="line-height: 1.5" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-lg"></i> Loading...</h4>

    <div class="row" *ngIf="!loading">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="!isModeChangeOrder">
        <div class="form-group">
          <label class="control-label">Module</label>
          <select [(ngModel)]="moduleSelectedId" (change)="changeModule()" class="form-control">
            <option [value]="0">All</option>
            <option [value]="1">Sales</option>
            <option [value]="2">Production Sales</option>
            <option [value]="3">Scheduling</option>
            <option [value]="4">Price Manager</option>
            <option [value]="5">Client Administrator</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="!isModeChangeOrder">
        <div class="form-group">
          <label class="control-label">Permission Category</label>
          <select [(ngModel)]="categoryId" (change)="changeCategory()" class="form-control">
            <option [value]="0">All</option>
            <option [value]="1">General</option>
            <option [value]="8">Item</option>
            <option [value]="4">Drawing</option>
            <option [value]="5">Administrative</option>
            <option [value]="6">Security</option>
            <option [value]="7">Reporting</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 margin-top-25" *ngIf="hasRoleQXMSecurityAdmin && !isModeChangeOrder">
        <button type="button" class="btn btn-primary" (click)="isEditMode=true" *ngIf="!isEditMode">Edit Mode</button>
        <button type="button" class="btn btn-primary margin-right-15" (click)="save()" *ngIf="isEditMode">Apply Changes</button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="isEditMode">Cancel</button>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 margin-top-25" *ngIf="isEditMode">
        <div class="form-group">
          <button type="button" class="btn btn-default" (click)="changeOrder()" *ngIf="!isModeChangeOrder">Order</button>
          <button type="button" class="btn btn-primary margin-right-15" (click)="saveChangeOrder()" *ngIf="isModeChangeOrder" [disabled]="indexOrderChanged==-1">Apply Changes</button>
          <button type="button" class="btn btn-default" (click)="cancelOrder()" *ngIf="isModeChangeOrder">Cancel</button>
        </div>
      </div>

      <div class="col-md-12">
        <form [formGroup]="dataForm">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <ng-container *ngFor="let x of tabs">
                <li *ngIf="(!isModeChangeOrder && x.id == 'slab')
                          || (!isModeChangeOrder && x.id == 'tile' && moduleSelectedId != 2)
                          || (!isModeChangeOrder && x.id == 'global')
                          || (isModeChangeOrder && x.id == 'all')"
                  [ngClass]="{'active': tabSelected == x.id}"><a href="#{{ x.id }}" (click)="tabSelected = x.id" data-toggle="tab">{{ x.name }}</a></li>
              </ng-container>
            </ul>
            <div class="tab-content">
              <ng-container *ngFor="let x of tabs">
                <div *ngIf="(!isModeChangeOrder && x.id == 'slab')
                          || (!isModeChangeOrder && x.id == 'tile' && moduleSelectedId != 2)
                          || (!isModeChangeOrder && x.id == 'global')
                          || (isModeChangeOrder && x.id == 'all')"
                  [ngClass]="{'active': tabSelected == x.id}" class="tab-pane box" id="{{ x.id }}" >
                  <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="width: 110px;" *ngIf="!isModeChangeOrder">Permission Id</th>
                          <th style="width: 70px;" *ngIf="isModeChangeOrder">Order</th>
                          <th style="cursor: pointer;" (click)="sort('name')" *ngIf="!isModeChangeOrder">Permission Name
                            <i class="fa fa-angle-down" *ngIf="dataSort.orderBy == 'name' && dataSort.type == 'desc'"></i>
                            <i class="fa fa-angle-up" *ngIf="dataSort.orderBy == 'name' && dataSort.type == 'asc'"></i>
                          </th>
                          <th *ngIf="isModeChangeOrder">Permission Name</th>
                          <th>Description</th>
                          <th *ngIf="isEditMode && !isModeChangeOrder">Permission Category</th>
                          <th style="width: 130px;" *ngIf="isEditMode && !isModeChangeOrder">Services</th>
                          <th *ngIf="isModeChangeOrder"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="dataUpdate">
                          <ng-container [formGroupName]="i"
                            *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                            <tr [ngClass]="{'order-changed': isModeChangeOrder && i == indexOrderChanged}"
                              *ngIf="isModeChangeOrder || (tabSelected == 'slab' && item.value.serviceSlab)
                                || (tabSelected == 'tile' && item.value.serviceTile) || (tabSelected == 'global' && item.value.serviceGlobal)">
                              <td style="width: 110px;" *ngIf="!isModeChangeOrder" class="text-center">{{ item.value.permissionId }}</td>
                              <td style="width: 70px;" *ngIf="isModeChangeOrder">{{i+1}}</td>
                              <td>
                                <div class="form-group no-margin">
                                  <input type="text" class="form-control no-margin" formControlName="permissionName" [readonly]="!isEditMode || isModeChangeOrder">
                                </div>
                              </td>
                              <td>
                                <div class="form-group no-margin">
                                  <input type="text" class="form-control no-margin" formControlName="description" [readonly]="!isEditMode || isModeChangeOrder">
                                </div>
                              </td>
                              <td *ngIf="isEditMode && !isModeChangeOrder">
                                <div class="form-group no-margin">
                                  <div class="checkbox no-margin">
                                    <label><input type="checkbox" formControlName="general"/>General</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="item"/>Item</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="drawing"/>Drawing</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="administrative"/>Administrative</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="security"/>Security</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="reporting"/>Reporting</label>
                                  </div>
                                </div>
                              </td>
                              <td style="width: 130px;" *ngIf="isEditMode && !isModeChangeOrder">
                                <div class="form-group no-margin">
                                  <div class="checkbox no-margin">
                                    <label><input type="checkbox" formControlName="serviceSlab" disabled/>Slab</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="serviceTile" disabled/>Tile</label>
                                    &nbsp;
                                    <label><input type="checkbox" formControlName="serviceGlobal" disabled/>Global</label>
                                  </div>
                                </div>
                              </td>
                              <td *ngIf="isModeChangeOrder">
                                <button type="button" class="btn btn-default" (click)="moveUp(i)" [disabled]="i==0">Up</button>
                                &nbsp;
                                <button type="button" class="btn btn-default" (click)="moveDown(i)" [disabled]="i==dataForm.get('dataUpdate').value.length-1">Down</button>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>

  </section>
</div>
