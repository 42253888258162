import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TileTenantChannelsService {

  public static MessageTypes = {
    SEND_TO_MODAL: 'SEND_TO_MODAL',
    RELOAD_PARENT_COMP: 'RELOAD_PARENT_COMPONENT',
    SEND_TO_MODAL_REASSIGN_CHANNEL: 'SEND_TO_MODAL_REASSIGN_CHANNEL',
  };

  arrUpdateIsDefault: { channelId: number, costSchemaAssignmentId: number, isDefault: boolean }[] = [];
  arrDelete: number[] = [];
  arrAdd: {
    tenantId: number,
    channelId: number,
    assignedOn: Date,
    description: string,
    id: number,
    isDefault: boolean,
    schemaId: number,
    schemaName: string,
    schemaNameAlias: string,
    updatedBy: string,
    updatedOn: Date,
    businessUnitId: number,
    businessUnit: string,
  }[] = [];
  arrDeleteChannel: number[] = [];
  arrReassignChannel: { costSchemaAssignmentId: number, channelId: number }[] = [];

  private subject = new Subject<any>();

  sendMessage(messageType: string, payload: any = null) {
    this.subject.next({ messageType, payload });
  }

  // clearAll() {
  //   this.subject.next();
  // }

  getMessages(): Observable<any> {
    return this.subject.asObservable();
  }

  isDirty(): boolean {
    return this.arrUpdateIsDefault.length > 0
    || this.arrDelete.length > 0
    || this.arrAdd.length > 0
    || this.arrDeleteChannel.length > 0
    || this.arrReassignChannel.length > 0
  }

  clear(): void {
    this.arrUpdateIsDefault = [];
    this.arrDelete = [];
    this.arrAdd = [];
    this.arrDeleteChannel = [];
    this.arrReassignChannel = [];
  }
}
