import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  templateUrl: './users.html'
})

export class UsersComponent implements OnInit {
  @ViewChild('modalAddUser') modalAddUser: BsModalComponent;

  addUserForm: FormGroup;
  submittingSearch: boolean;
  users: Array<any>;
  waiting_loading: string;
  paginationCount: number;
  arrTenant: any;
  tenantId = 0;
  filter: PaginationFilter = {
    textSearch: '',
    tenant: 0,
    isQXMStaff: false,
    orderBy: 'userId',
    order: 'desc',
    itemPerPage: 20,
    pageNumber: 1
  };
  showQXM = false;

  constructor(private service: UserService, private toastr: ToastrService, private fb: FormBuilder, private router: Router) {
    this.addUserForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      fullName: [''],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-z]{2,6}?([.][a-z]{2})?')])],
      confirmEmail: ['', Validators.required],
      phone: ['', Validators.pattern('[0-9]{10}')],
      arrTenantRoleUser: [[]],
      arrRoleView: [[]],
      employee: [true],
      staff: [false]
    }, { validators: this.checkPasswords });
  }

  ngOnInit() {
    $('body').layout('fix');
    if (sessionStorage.getItem('tenantId')) {
      this.tenantId = Number(sessionStorage.getItem('tenantId'));
      this.filter.tenant = this.tenantId;
    }
    this.getAllTenant();
    this.getAllUsers();
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const isMatch = group.get('email').value === group.get('confirmEmail').value;
    if (!isMatch) this.confirmEmail.setErrors({ notSame: true });
    return isMatch ? null : {}
  }

  get firstName(): FormControl {
    return this.addUserForm.get('firstName') as FormControl;
  }
  getErrorFirstName() {
    return this.firstName.hasError('required') ? 'First name is required' : '';
  }

  get lastName(): FormControl {
    return this.addUserForm.get('lastName') as FormControl;
  }
  getErrorLastName() {
    return this.lastName.hasError('required') ? 'Last name is required' : '';
  }

  get confirmEmail(): FormControl {
    return this.addUserForm.get('confirmEmail') as FormControl;
  }
  getErrorConfirmEmail() {
    if (this.confirmEmail.hasError('required')) {
      return 'Confirm Email is required';
    } else if (this.confirmEmail.hasError('notSame')) {
      return 'Email not matched';
    }
  }

  get email(): FormControl {
    return this.addUserForm.get('email') as FormControl;
  }
  getErrorEmail() {
    if (this.email.hasError('required')) {
      return 'Email is required';
    } else if (this.email.hasError('email') || this.email.hasError('pattern')) {
      return 'Invalid email format';
    }
  }

  get phone(): FormControl {
    return this.addUserForm.get('phone') as FormControl;
  }
  getErrorPhone() {
    return this.phone.hasError('pattern') ? 'Invalid phone number format' : '';
  }

  get arrTenantRoleUser(): FormControl {
    return this.addUserForm.get('arrTenantRoleUser') as FormControl;
  }
  get arrRoleView(): FormControl {
    return this.addUserForm.get('arrRoleView') as FormControl;
  }
  getErrorRoleView() {
    return this.arrRoleView.hasError('required') ? 'Role is required' : '';
  }

  addNewUser() {
    this.addUserForm.reset();

    const listRoleView = [];
    this.arrTenant.forEach(e => {
      const arrRole = [];
      e.arrRole.forEach(r => { arrRole.push({ roleId: r.roleId, roleName: r.roleName, isCheck: false }); });
      const obj = { tenantId: e.tenantId, tenantName: e.tenantName, active: e.active, arrRole };
      listRoleView.push(obj);
    });

    this.addUserForm.setValue({
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      confirmEmail: '',
      phone: '',
      arrRoleView: listRoleView,
      arrTenantRoleUser: [],
      employee: true,
      staff: false
    });
    this.modalAddUser.open();
  }

  saveUser() {
    this.addUserForm.patchValue({ fullName: this.firstName.value + ' ' + this.lastName.value });
    if (this.addUserForm.valid) {
      this.addUserForm.disable();

      const listRole = [];
      this.arrRoleView.value.forEach(e => {
        const arrRole = [];
        e.arrRole.forEach(r => { if (r.isCheck == '1') { arrRole.push(r.roleId); } });
        listRole.push({ tenantId: e.tenantId, arrRole });
      });
      this.arrTenantRoleUser.setValue(listRole);

      this.service.createUserToTenant(this.addUserForm.value).then(response => {
        this.addUserForm.enable();
        if (response.status) {
          // if (response.data.isResult) {
          this.toastr.success('The user has been added');
          this.modalAddUser.close();
          this.router.navigate(['/user-detail/' + response.data]);
          // } else if (response.data.existsMail) {
          //   this.toastr.error('Email already exists');
          // }
        } else {
          this.toastr.error(response.message || 'Add user failed');
        }
      });
    }
  }

  changeTenant() {
    sessionStorage.setItem('tenantId', this.tenantId.toString());
    this.filter.tenant = this.tenantId;
    this.filter.pageNumber = 1;
    this.getAllUsers();
  }

  getAllTenant() {
    this.arrTenant = null;
    this.service.getAllTenant().then(response => {
      if (response.status) {
        this.arrTenant = response.data;
      } else {
        this.toastr.error('Can\'t get list tenant');
      }
    });
  }

  getAllUsers() {
    this.users = null;
    this.submittingSearch = true;
    this.waiting_loading = 'Loading...';
    this.service.getAllUsers(this.filter).then(response => {
      this.submittingSearch = false;
      if (response.status && response.data.total > 0) {
        this.users = response.data.listUser;
        this.paginationCount = response.data.total;
      } else {
        this.waiting_loading = 'No result found.';
      }
      if (response.status && response.data.listTenant) {
        this.arrTenant = response.data.listTenant;
      }
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  activeUser(user, status) {
    this.service.activeUser(user.userId, status).then(response => {
      if (response.status) {
        if (status) {
          this.toastr.success('Activate user successfully');
        } else {
          this.toastr.success('Deactivate user successfully');
        }
        user.active = !user.active;
      } else {
        if (status) {
          this.toastr.error('Activate user failed');
        } else {
          this.toastr.error('Deactivate user failed');
        }
      }
    });
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) {
      return;
    }
    this.filter.pageNumber = n;
    this.getAllUsers();
  }

  onNext(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getAllUsers();
  }

  onPrev(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getAllUsers();
  }

  search() {
    this.filter.pageNumber = 1;
    this.getAllUsers();
  }

  onShowQXM() {
    this.filter.isQXMStaff = this.showQXM;
    this.getAllUsers();
  }
}

export class PaginationFilter {
  textSearch: string;
  tenant: number;
  isQXMStaff: boolean;
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
}
