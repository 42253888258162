<div class="row">
  <div>
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Thickness Configuration</h4>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12">
      <div class="form-group">
        <label>Thickness (*)</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="thicknessName" maxlength="200" trim="blur" required>
      </div>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12">
      <div class="form-group">
        <label>Abbreviation (*)</label>
        <div class="row">
          <div class="col-sm-8">
            <input type="text" class="form-control" trim="blur" [(ngModel)]="abbreviation" maxlength="200" trim="blur" required>
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn btn-primary" (click)="add()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Thickness</th>
              <th [style.width.px]="120">Abbreviation</th>
              <th [style.width.px]="100">Active</th>
              <th>Updated On</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.thickness.invalid && item.controls.thickness.dirty && item.controls.thickness.touched">
                      <input type="text" class="form-control" formControlName="thickness" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="abbreviation" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td>{{item.value.updatedAt | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" [disabled]='!item.value.isRemove' (click)="delete(item.value.id)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this thickness? Removing the thickness will remove all rules and items associated to this thickness.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
