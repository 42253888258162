<div class="row">
  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Channel</label>
      <select [(ngModel)]="channelId" (change)="filterData()" class="form-control">
        <option *ngFor="let c of channels" [ngValue]="c.channelId">{{c.channelName}}</option>
      </select>
    </div>
  </div> -->
  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px;">
      <span>
        <a (click) = 'change(false)' [ngClass]="{'sub-menu-hight': !isApp, 'sub-menu-txt': isApp}">Material Waste</a>
      </span>
       | 
       <span>
         <a (click) = 'change(true)' [ngClass]="{'sub-menu-hight': isApp, 'sub-menu-txt': !isApp}">Application Waste</a>
       </span>

    </label>
  </div>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <div class="form-group">
      <label>Instructions:</label>
      <p>Assign Waste Percentage adjustments for each application. 
        A positive waste percentage will increase the base level waste percentage defined for material type. 
        A negative will decrease the base level waste percentage. 
        There may be many other factors impacting waste, so it's important to think in relative terms. 
        Be sure base waste percentage for each material type has been set.</p>
    </div>
  </div>

  <div class="col-xs-12" *ngIf="isApp">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">APPLICATION WASTE % ADJUSTMENTS</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.applicationName}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="wastePercentAdjustment" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <div class="col-xs-12" *ngIf="!isApp">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">MATERIAL WASTE % ADJUSTMENTS</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="materialForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Material Type</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of materialForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.materialGroup}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="wastePercentAdjustment" (blur)="note2.focus()" (keyup.enter)="note2.focus()">
                  </td>
                  <td>
                    <input #note2 type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
