<div class="content-wrapper">
  <section class="content-header">
    <h1>Reports</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/reports"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Reports</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="loadingTenant">
      <p class="text-center">
        <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loadingTenant"></i>
      </p>
    </div>
    <div *ngIf="!loadingTenant && tenantId > 0">
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="addNewReport()">Add New</button>
      </div>
      <h4 style="line-height: 1.5" *ngIf="!reports">{{ waiting_loading }}</h4>
      <div class="table-responsive" *ngIf="reports">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">#</th>
              <th style="cursor: pointer;" (click)="changeFilter('reportName')">Report Name
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'reportName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'reportName' && filter.order == 'asc'"></i>
              </th>
              <th>Report Description</th>
              <th style="cursor: pointer;" (click)="changeFilter('reportCategoryName')">Report Category
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'reportCategoryName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'reportCategoryName' && filter.order == 'asc'"></i>
              </th>
              <th [style.width.px]="110" class="text-center">Standard</th>
              <th>Tenant Source Folder</th>
              <th style="cursor: pointer;" (click)="changeFilter('sourceReport')">Source Report
                <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'sourceReport' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'sourceReport' && filter.order == 'asc'"></i>
              </th>

              <th [style.width.px]="110" class="text-center">Assign Roles</th>
              <th [style.width.px]="80" class="text-center">Active</th>
              <th [style.width.px]="80" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of reports; let index = index">
              <td [style.width.px]="40" class="text-center">{{ filter.itemPerPage * (filter.pageNumber - 1) + index + 1 }}</td>
              <td>{{ item.reportName }}</td>
              <td>{{ item.reportDescription }}</td>
              <td>{{ item.reportCategoryName }}</td>
              <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.IsStandard != 1 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="setReportStandard(item, !item.IsStandard)"></i>
              </td>
              <td>{{ item.tenantSourceFolder }}</td>
              <td>{{ item.sourceReport }}</td>
              <td [style.width.px]="110" class="text-center">
                <a href="javascript:;" (click)="assignRoles(item.reportId)" title="Assign Roles" class="fa fa-lg fa-pencil-square-o text-success"></a>
              </td>
              <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="activeReport(item, !item.active)"></i>
              </td>
              <td [style.width.px]="80" class="text-center">
                <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit" (click)="update(item)"></a>&nbsp;&nbsp;&nbsp;
                <a href="javascript:;" class="fa fa-lg fa-trash-o text-danger" title="Delete" (click)="deleteReport(item.reportId)"></a>
              </td>
            </tr>
          </tbody>
        </table>
        <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
      </div>
      <div>
        <center>{{ waiting_loading }}</center>
      </div>
    </div>
  </section>
</div>

<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="form">
      <div class="form-group" [class.has-error]="reportName.invalid && (reportName.dirty || reportName.touched)">
        <label>Report Name
          <span class="text-danger">*</span>
        </label>
        <input type="text" trim="blur" class="form-control" formControlName="reportName" maxlength="250" required>
        <ng-container *ngIf="reportName.invalid && (reportName.dirty || reportName.touched)">
          <span *ngIf="reportName.errors.required" class="help-block">Report name is required.</span>
          <span *ngIf="reportName.errors.isExistReport" class="help-block">{{error_message}}</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label>Report Description</label>
        <textarea type="text" trim="blur" class="form-control" style="resize: none" formControlName="reportDescription"></textarea>
      </div>
      <div class="form-group" [class.has-error]="reportCategoryId.invalid && (reportCategoryId.dirty || reportCategoryId.touched)">
        <label>Report Category
          <span class="text-danger">*</span>
        </label>
        <select formControlName="reportCategoryId" class="form-control" required>
          <ng-container *ngFor="let rc of reportCategories">
            <option [ngValue]="rc.id">{{rc.name}}</option>
          </ng-container>
        </select>
        <span *ngIf="reportCategoryId.invalid && (reportCategoryId.dirty || reportCategoryId.touched)" class="help-block">{{getErrorReportCategoryId()}}</span>
      </div>
      <div class="form-group" [class.has-error]="reportObjectName.invalid && (reportObjectName.dirty || reportObjectName.touched)">
        <label>Source Report
          <span class="text-danger">*</span>
        </label>
        <select formControlName="reportObjectName" class="form-control" (change)="setSourceReport()" required>
          <ng-container *ngFor="let t of reportsOfTenantFromActiveReport">
            <option [ngValue]="t.id">{{t.title}}</option>
            <!--<option [ngValue]="t._id">{{t.Name}}</option>-->
          </ng-container>
        </select>
        <!-- <input type="text" trim="blur" class="form-control" formControlName="reportObjectName" maxlength="200" required> -->
        <span *ngIf="reportObjectName.invalid && (reportObjectName.dirty || reportObjectName.touched)" class="help-block">{{getErrorReportObjectName()}}</span>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loading_submit" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px;"></i>
    <button type="button" [disabled]="loading_submit" class="btn btn-default" (click)="modal.close()">Cancel</button>
    <button type="button" [disabled]="form.invalid || loading_submit" class="btn btn-primary" (click)="submit()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure delete this report?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmDelete.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAssignRole>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Assign role of report for this tenant</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="form-group">
      <div class="checkbox" *ngFor="let role of listRoles; let i = index">
        <label>
          <input type="checkbox" [(ngModel)]="role.isCheck">{{role.roleName}}
        </label>
      </div>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAssignRole.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submitAssignRole()">Submit</button>
  </bs-modal-footer>
</bs-modal>
