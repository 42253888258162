import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class CostSchemaService {

constructor(private http: AuthHttp) { }
 copy(id): Promise<any>{
  return this.http.get(`CostSchemas/Copy/${id}`).toPromise();
 }
 delete(id, reassignCostSchemaId): Promise<any>{
  return this.http.delete(`CostSchemas/${id}/${reassignCostSchemaId}`).toPromise();
 }
 isAssignedToChannel(costSchemaId): Promise<any>{
  return this.http.get(`CostSchemas/IsAssignedToChannel/${costSchemaId}`).toPromise();
 }
 getAllActive(): Promise<any>{
  return this.http.get(`CostSchemas/GetAllActive`).toPromise();
 }

 clearAllComments(schemaId):Promise<any>{
    return this.http.delete(`CostSchemas/ClearAllComments/${schemaId}`).toPromise();
 }
}
