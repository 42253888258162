import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';

@Component({
    selector: 'tab-material-direction',
    templateUrl: './material-direction.html',
    styleUrls: ['./material-direction.scss']
})

export class MaterialDirectionComponent implements OnInit, OnDestroy {
    @Input() costSchemaId: any;
    @Input() directionalities: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('modalViewComments') modalViewComments: any;
    notes: any;
    directionId: any;
    processTypeId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    currentTab = 'MaterialDirection';
    currentId: number;

    constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService,
        private slabCostDetailService: SlabCostDetailService) {

    }

    ngOnInit() {
        this.directionId = this.directionalities[0].directionId;
        this.processTypeId = 2;
        this.loadData();
    }
    ngOnDestroy() {
        this.save();
    }
    viewComment(item) {
        this.currentId = item.value.id;
        this.settingService.getMtxNoteMaterialDirectionById(this.currentId).then(res => {
            if (res.status) {
                this.notes = res.data;
                // this.viewComments.open();
                this.modalViewComments.open();

                //Update last comment when delete comment
                this.slabCostDetailService.getMessages().subscribe(data => {
                    if (!data) {
                        return;
                    }

                    switch (data.messageType) {
                        case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                            const payload = data.payload;
                            if (payload) {
                                item.controls.note.setValue(payload.note);
                                item.controls.updatedOn.setValue(payload.updateOn);
                            }
                            break;
                    }
                });
            }
            else this.toast.error('view comment failed.');
        });
    }
    loadData() {
        this.loading = true;
        this.settingService.getMaterialDirection(this.costSchemaId).then(response => {
            if (response.status) {
                this.data = response.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
            else this.toast.error("Load data failed");
        });
    }

    filterData() {
        this.dataFilter = this.data.filter(e => e.processTypeId == this.processTypeId && e.materialDirectionalityId == this.directionId);
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });
        if (this.userService.isTenantOnly()) this.dataForm.disable();
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            id: e.id,
            processName: e.processName,
            percentAdjustment: new PercentPipe('en-US').transform(e.percentAdjustment, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.id == e.id);
        item.percentAdjustment = this.settingService.replaceMask(e.percentAdjustment) / 100;
        item.note = e.note;
        return {
            id: e.id,
            percentAdjustment: this.settingService.replaceMask(e.percentAdjustment) / 100,
            note: e.note,
        };
    }

    save() {
        let dataUpdate = [];
        let cellUpdate: any;
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                cellUpdate = e;
                dataUpdate.push(this.createItemUpdate(e.value));
                e.markAsPristine();
                e.markAsUntouched();
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateMaterialDirection(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Material direction has been updated');
                    cellUpdate?.get('updatedOn').setValue(response.data);
                    if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
                }
                else this.toast.error('Update material direction failed');
            });
        }
    }
}
