import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class SlabGroupService {
  constructor(private http: AuthHttp) {}

  getSlabGroupsByTenantId(tenantId): Promise<any> {
    return this.http.get(`SlabGroups/GetSlabGroupsByTenantId/${tenantId}`).toPromise();
  }
}
