import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/services/setting.service';
import { UserService } from '../../../../services/user.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';

@Component({
  selector: 'app-view-comment',
  templateUrl: './view-comment.html'
})
export class ViewCommentComponent implements OnInit {

  @Input() notes: any;
  @Input() tab: string;
  @Input() parentId: number;
  @ViewChild('modal') modal: BsModalComponent;

  isTenantOnly: boolean = false;

  constructor(private settingService: SettingService, private toast: ToastrService,
    private userService: UserService,
    public slabCostDetailService: SlabCostDetailService) {
    this.isTenantOnly = this.userService.isTenantOnly();
  }
  ngOnInit() {
  }
  open() {
    this.modal.open('lg');
  }
  close() {
    this.modal.close();
  }
  deleteComment(id) {
    this.settingService.removeComment(id).then(res => {
      if (res.status) {
        this.toast.success('The comment has been removed.');
        this.notes = this.notes.filter(i => i.id != id);

        //emit last comment
        let lastComment = this.notes && this.notes.length > 0 ? this.notes[0] : {};
        this.slabCostDetailService.sendMessage(
          SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.tab + "_" + this.parentId,
          { note: lastComment.note, updateOn: lastComment.updatedOn }
        )
      }
      else this.toast.error('Delete comment has been failed.');
    });
  }
}
