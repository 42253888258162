import { Routes, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Route } from '@angular/compiler/src/core';
import { SettingService } from '../../services/setting.service';
declare var $: any;

@Component({
  templateUrl: './regions.html'
})

export class RegionsComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  modal_title: string;

  data: any;
  timezones: any;

  form: FormGroup;
  loading_submit: boolean;
  waiting_loading: string;
  error_message = '';

  validAdd = false;

  paginationPage = 1;
  paginationCount: number;
  deleteRegionId = 0;

  filter: PaginationFilter = {
    orderBy: 'createdOn',
    order: 'desc',
    itemPerPage: 20,
    pageNumber: 1
  };

  constructor(private service: SettingService, private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.form = this.formBuilder.group({
      regionName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      active: [true]
    });
  }

  get regionName(): FormControl {
    return this.form.get('regionName') as FormControl;
  }

  getErrorRegionName() {
    return this.regionName.hasError('required') ? 'Region is required' : '';
  }

  get active(): FormControl {
    return this.form.get('active') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    this.loadData();
  }
  
  loadData() {
    this.data = null;
    this.waiting_loading = 'Loading...';
    this.service.getRegions(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.data = response.data.data;
        this.paginationCount = response.data.total;
      } else { this.waiting_loading = 'No data'; }
    });
  }

  addNew() {
    debugger;
    this.waiting_loading = 'Loading...';
    this.service.addRegions(this.regionName.value, this.active.value).then(res => {
      if(res.status) {
        this.toastr.success('Region has been added');
        this.loadData();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  setActive(t) {
    t.active = !t.active;
    this.service.setActive(t.regionId, t.active).then(response => {
      if (response.status && response.data) {
        if (t.active) this.toastr.success(response.message, 'Region has been Activated');
        else this.toastr.success(response.message, 'Region has been deactivated');
      }
      else {
        t.active = !t.active;
        this.toastr.error(response.message, 'Update Region Error');
      }
    }).catch(() => {
      t.active = !t.active;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  

  delete(id: number) {
    this.deleteRegionId = id;
    this.modalConfirm.open();
  }

  submitDelete() {
    document.getElementById('submitDelete').setAttribute('disabled', 'disabled');
    document.getElementById('cancelDelete').setAttribute('disabled', 'disabled');
    this.service.deleteRegion(this.deleteRegionId)
      .then(response => {
        document.getElementById('submitDelete').removeAttribute('disabled');
        document.getElementById('cancelDelete').removeAttribute('disabled');
        if (!response.status) {
          this.toastr.error(response.message, 'Error');
        } else {
          this.toastr.success('Region has been deleted', 'Success');
          this.loadData();
        }
        this.modalConfirm.close();
      })
      .catch(() => {
        this.toastr.error('Internal Server Error', 'Error');
        document.getElementById('submitDelete').removeAttribute('disabled');
        document.getElementById('cancelDelete').removeAttribute('disabled');
      });
  }

  changeFilter(orderBy: string) {
    if (!orderBy) {
      this.filter.orderBy = 'createdOn';
      this.filter.order = 'desc';
    } else if (orderBy === this.filter.orderBy) {
      if (this.filter.order === 'desc') { this.filter.order = 'asc'; } else { this.filter.order = 'desc'; }
    } else {
      this.filter.orderBy = orderBy;
      this.filter.order = 'desc';
    }

    this.loadData();
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) { return; }
    this.filter.pageNumber = n;
    this.loadData();
  }

  onNext(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber++;
    this.loadData();
  }

  onPrev(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber--;
    this.loadData();
  }
}

export class PaginationFilter {
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
}
