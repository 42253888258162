import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-special-condition-weights',
  templateUrl: './special-condition-weights.html',
})

export class TileSpecialConditionWeightsComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  notes: any[];
  @ViewChild('modalViewComments') modalViewComments: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  constructor(private fb: FormBuilder, private toast: ToastrService,
    private settingService: SettingService, private userService: UserService) {}

  ngOnDestroy(){
    this.save();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getSpecialConditionWeight(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error("Load data failed");
    });
  }
  viewComment(item){
    const id = item.value.id;
    this.settingService.getMtxNoteSpecialConditionWeightById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      specialConditionName: e.specialConditionName,
      percentage: new PercentPipe('en-US').transform(e.percentage, '.2-2'),
      note: e.note,
      updatedOn: e.updatedOn
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentage = this.settingService.replaceMask(e.percentage) / 100;
    item.note = e.note;
    const cell = this.data.find(i=> i.id == e.id);
    if(!!cell){
      cell.percentage = this.settingService.replaceMask(e.percentage);
      cell.note = e.note;
    }
    return {
      id: e.id,
      percentage: this.settingService.replaceMask(e.percentage) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: AbstractControl;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateSpecialConditionWeight(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Special Condition has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
        }
        else this.toast.error('Update Special Condition failed');
      });
    }
  }
}
