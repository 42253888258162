import { LicenseTypesComponent } from './../pages/license-types/license-types';
import { SlabCostDetailComponent } from './../pages/settings/slab-cost/slab-cost-detail/slab-cost-detail';
import { NewTenantComponent } from './../pages/tenants/new-tenant/new-tenant';
import { DashboardsComponent } from '../pages/dashboards/dashboards';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth-guard.service';
import { RouteDictionary } from './types';

import { HomeComponent } from '../pages/home/home';
import { TenantsComponent } from '../pages/tenants/tenants';
import { TenantDetailsComponent } from '../pages/tenants/details/details';
import { UsersComponent } from '../pages/users/users';
import { UserDetailsComponent } from '../pages/users/details/details';
import { RolesComponent } from '../pages/roles/roles';
import { PermissionsComponent } from '../pages/permissions/permissions';
import { LoginComponent } from '../pages/login/login.component';
import { NotFoundComponent } from '../pages/not-found/not-found';
import { SlabCostComponent } from '../pages/settings/slab-cost/slab-cost';

import { SlabPricingComponent } from '../pages/settings/slab-pricing/slab-pricing';
import { SlabConfigurationComponent } from '../pages/settings/slab-configuration/slab-configuration';
import { GlobalConfigurationComponent } from '../pages/settings/global-configuration/global-configuration';
import { TenantChannelContainerComponent } from '../pages/settings/tenant-channels/tenant-channel-container';
import { ReportsComponent } from '../pages/reports/reports';
import { PricingRecalculationComponent } from '../pages/pricing-recalculation/pricing-recalculation';
import { CompareToolComponent } from '../pages/compare-tool/compare-tool';
import { LogOfBatchComponent } from '../pages/compare-tool/log-of-batch';
import { RegionsComponent } from '../pages/regions/regions';
import { GlobalRolesComponent } from '../pages/global-roles/global-roles';

// Tile
import { TileGlobalConfigurationComponent } from '../pages/settings/tile/tile-global-configuration/tile-global-configuration';
import { TileTenantSpecificConfigurationComponent } from '../pages/settings/tile/tile-tenant-specific-configuration/tile-tenant-specific-configuration';
import { TileCostSchemaComponent } from '../pages/settings/tile/tile-cost-schema/tile-cost-schema';
import { TileCostSchemaDetailComponent } from '../pages/settings/tile/tile-cost-schema';
import { TileTenantChannelContainerComponent } from '../pages/settings/tile/tenant-channels/tenant-channel-container';
import { RoleDetailComponent } from '../pages/roles/role-detail/role-detail';

const routes: Routes = [
  { path: '', redirectTo: RouteDictionary.Login, pathMatch: 'full' },
  { path: RouteDictionary.Home, component: HomeComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Tenants, component: TenantsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TenantDetails, component: TenantDetailsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.NewTenant, component: NewTenantComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Users, component: UsersComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.UserDetails, component: UserDetailsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Roles, component: RolesComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.RoleDetail, component: RoleDetailComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.GlobalRoles, component: GlobalRolesComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Permissions, component: PermissionsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.LicenseTypes, component: LicenseTypesComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Regions, component: RegionsComponent, canActivate: [AuthGuard] },
  // { path: RouteDictionary.SlabCost, component: SlabCostComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.CostSchemas, component: SlabCostComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.SlabCostDetail, component: SlabCostDetailComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.SlabPricing, component: SlabPricingComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.SlabConfiguration, component: SlabConfigurationComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.GlobalConfiguration, component: GlobalConfigurationComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TenantChannels, component: TenantChannelContainerComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Reports, component: ReportsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Dashboards, component: DashboardsComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.PricingRecalculation, component: PricingRecalculationComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.CompareTool, component: CompareToolComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.LogOfBatch, component: LogOfBatchComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.Login, component: LoginComponent },
  // Tile
  { path: RouteDictionary.TileGlobalConfiguration, component: TileGlobalConfigurationComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TileTenantSpecificConfiguration, component: TileTenantSpecificConfigurationComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TileCostSchemas, component: TileCostSchemaComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TileCostSchemaDetail, component: TileCostSchemaDetailComponent, canActivate: [AuthGuard] },
  { path: RouteDictionary.TileTenantChannels, component: TileTenantChannelContainerComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent }
];

export const Routing = RouterModule.forRoot(routes);
