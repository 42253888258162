import { SettingService } from './../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TenantsService } from 'src/app/services/tenants.service';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-new-tenant',
  templateUrl: './new-tenant.html',
  styleUrls: ['./new-tenant.scss']
})
export class NewTenantComponent implements OnInit {

  form: FormGroup;
  // formCostSchema: FormArray;
  states: any;
  // schemas: any;
  timezones: any;
  // lstchannel: any;
  error_message: string;

  constructor(private tenantService: TenantsService,private router: Router, private settingService: SettingService, private  formBuilder: FormBuilder, private toastr: ToastrService) {
    this.form = this.formBuilder.group({
      // tenantId: [0],
      tenantName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      locationName: ['', [Validators.required]],
      // company: ['', [Validators.required]],
      // website: ['', [Validators.required, Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
      // phone: ['', [Validators.pattern('[0-9]{10}')]],
      // timeZoneId: ['', Validators.required],
      // active: [true],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      // channels: this.formBuilder.array([ ])
    });
  }


  ngOnInit() {
    $('body').layout('fix');
    this.getListState();
    // this.getListTimeZone();
    // this.getAllSchemas();
    // this.getAllChanel();
  }
  save(){
    this.tenantService.CreateTenant(this.form.value).then(res => {
      if(res.status){
        this.toastr.success('Create tenant succeess!');
        this.router.navigate(['/tenants']);
      }
      else this.toastr.error(res.message);
    });
  }
  cancel(){
    this.router.navigate(['/tenants']);
  }
  // getAllChanel(){
  //   this.settingService.getChannel().then(res=> {
  //     if(res.status) {
  //       this.lstchannel = res.data;

  //       this.lstchannel.forEach(e => {
  //          this.channels.push(this.createItemView(e));
  //       });
  //     }
  //     else this.toastr.error('Can\'t load list channels');
  //   });
  // }
  private getListState(){
    this.settingService.getListState().then(res => {
      if(res.status){
        this.states = res.data;
      }
      else this.toastr.error('Can\'t load list state');
    });
  }
  // private getListTimeZone() {
  //   this.tenantService.getTimeZone().then(response => {
  //     if (response.status) this.timezones = response.data
  //     else this.toastr.error('Can\'t load list timezone');
  //   });
  // }
  // getAllSchemas(){
  //   this.settingService.getAllSchemas().then(res => {
  //     if(res.status) this.schemas = res.data;
  //     else this.toastr.error('Can\'t load list cost schema');
  //   });
  // }

  // createItemView(e): FormGroup{
  //   return this.formBuilder.group({
  //     costSchemaId: 0,
  //     channelId: [e.channelId, [Validators.required]],
  //     channelName: e.channelName,
  //     active: !!e.active
  //   });
  // }

  // get channels(): FormArray{
  //   return this.form.get('channels') as FormArray;
  // }
  get address1(): FormControl{
    return this.form.get('address1') as FormControl;
  }
  get address2(): FormControl{
    return this.form.get('address2') as FormControl;
  }
  get city(): FormControl{
    return this.form.get('city') as FormControl;
  }
  get state(): FormControl{
    return this.form.get('state') as FormControl;
  }
  get postalCode(): FormControl{
    return this.form.get('postalCode') as FormControl;
  }
  // get tenantId(): FormControl {
  //   return this.form.get('tenantId') as FormControl;
  // }
  get tenantName(): FormControl {
    return this.form.get('tenantName') as FormControl;
  }
  get locationName(): FormControl {
    return this.form.get('locationName') as FormControl;
  }
  // get company(): FormControl {
  //   return this.form.get('company') as FormControl;
  // }
  // get website(): FormControl {
  //   return this.form.get('website') as FormControl;
  // }
  // get phone(): FormControl {
  //   return this.form.get('phone') as FormControl;
  // }
  // get timeZoneId(): FormControl {
  //   return this.form.get('timeZoneId') as FormControl;
  // }


}
