<!-- Modal -->
<div class="modal fade" id="assignCostSchemaModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Assign Cost Schema</h4>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="assignCostSchemaForm">
          <div class="row">
            <div class="col-sm-3 col-xs-12">
              <div class="form-group">
                <label>Type</label>
                <select formControlName="businessUnitId" class="form-control">
                  <option *ngFor="let t of businessUnitIds" [ngValue]="t.businessUnitId">{{t.businessUnit}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Channel</label>
                <input type="text" class="form-control" formControlName="channelName">
              </div>
            </div>
            <div class="col-sm-9 col-xs-12">
              <div class="form-group">
                <label>Cost Schema
                  <span class="text-danger">*</span>
                </label>
                <select *ngIf="businessUnitId.value == 1" formControlName="costSchemaIds" class="form-control" multiple style="height: 25rem">
                  <option *ngFor="let t of unassignedCostSchemas" [ngValue]="t.schemaId">{{t.schemaName}}</option>
                </select>
                <select *ngIf="businessUnitId.value == 2" formControlName="costSchemaIds" class="form-control" multiple style="height: 25rem">
                  <option *ngFor="let t of tileUnassignedCostSchemas" [ngValue]="t.schemaId">{{t.schemaName}}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button
          data-dismiss="modal"
          type="button" class="btn btn-primary"
          (click)="save()"
          [disabled]="assignCostSchemaForm.invalid || assignCostSchemaForm.disabled">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
