import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'tab-default-item-properties',
  templateUrl: './default-item-properties.html',
  styleUrls: ['./default-item-properties.scss']
})

export class DefaultItemPropertiesComponent implements OnInit {
  @Input() tenantId: any;
  @Input() itemCategories: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirmAddIpt') modalConfirmAddIpt: BsModalComponent;
  @ViewChild('modalAddIpt') modalAddIpt: BsModalComponent;

  itemTypes: any;
  itemTypesFilter: any;
  itemPropertyTypes: any;
  itemPropertyTypesFilter: any;

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  formAdd: FormGroup;
  formAddIpt: FormGroup;
  idToRemove: any;
  curTextNew = "";

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    $('.select2').select2();
    $('.select2').on('select2:select', (e) => {
      this.itemPropertyTypeId.setValue(Number(e.params.data.id));
    });
    $('.select2').on('select2:closing', (e) => {
      const textNew = $('.select2-search__field')[0].value;
      if (textNew != "") {
        const ipt = this.itemPropertyTypes.find(i => i.itemProperty.trim().toLowerCase() == textNew.trim().toLowerCase());
        if (ipt) {
          $('.select2').val(ipt.itemPropertyTypeId).trigger('change');
          this.itemPropertyTypeId.setValue(ipt.itemPropertyTypeId);
        }
        else {
          $('.select2').val(null).trigger('change');
          this.curTextNew = textNew;
          this.itemProperty.setValue(this.curTextNew);
          this.modalConfirmAddIpt.open('sm');
        }
      }
    });

    this.loadData();
    this.formAdd = this.fb.group({
      tenantId: [this.tenantId],
      itemCategoryId: [''],
      itemTypeId: ['', Validators.required],
      itemPropertyTypeId: ['', Validators.required],
      propertyScope: [3],
      required: false,
      optional: false,
      available: false
    });

    this.formAddIpt = this.fb.group({
      tenantId: [this.tenantId],
      itemProperty: [this.curTextNew, Validators.compose([Validators.required, Validators.minLength(3)])],
      part: [true],
      service: [true],
      fee: [true],
    });
  }

  get itemCategoryId(): FormControl {
    return this.formAdd.get('itemCategoryId') as FormControl;
  }
  getErrorItemCategoryId() {
    return this.itemCategoryId.hasError('required') ? 'Item category cannot be empty' : '';
  }

  get itemTypeId(): FormControl {
    return this.formAdd.get('itemTypeId') as FormControl;
  }
  getErrorItemTypeId() {
    return this.itemTypeId.hasError('required') ? 'Item type cannot be empty' : '';
  }

  get itemPropertyTypeId(): FormControl {
    return this.formAdd.get('itemPropertyTypeId') as FormControl;
  }
  getErrorItemPropertyTypeId() {
    if (this.itemPropertyTypeId.hasError('required')) return 'Item property type cannot be empty';
    else if (this.itemPropertyTypeId.hasError('exists')) return 'Item property type already exists';
  }

  get itemProperty(): FormControl {
    return this.formAddIpt.get('itemProperty') as FormControl;
  }
  getErrorItemProperty() {
    if (this.itemProperty.hasError('required')) {
      return 'New property name is required';
    } else if (this.itemProperty.hasError('minlength')) {
      return 'New property name must be at least 3 characters long';
    }
  }

  loadItemPropertyType(id?) {
    this.settingService.getItemPropertyTypes(this.tenantId).then(res => {
      if (res.status) {
        this.itemPropertyTypes = res.data;
        this.itemPropertyTypesFilter = _.differenceBy(this.itemPropertyTypes, this.dataFilter, 'itemPropertyTypeId');
        setTimeout(() => {
          if (id) $('.select2').val(id + '').trigger('change');
        });
      }
      else this.toast.error('Load item property types failed');
    });
  }

  loadData() {
    this.loading = true;
    this.settingService.getDefaultItemProperties(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
      }
      else this.toast.error('Load item default properties failed');
    });

    this.settingService.getItemTypes(this.tenantId).then(response => {
      if (response.status) this.itemTypes = response.data;
      else this.toast.error('Load item types failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    if (this.itemCategoryId.value) this.dataFilter = this.dataFilter.filter(d => d.itemCategoryId == this.itemCategoryId.value);
    if (this.itemTypeId.value) this.dataFilter = this.dataFilter.filter(d => d.itemTypeId == this.itemTypeId.value);

    this.loadItemPropertyType();

    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    this.calcHeightTable.emit();
  }

  filterItemTypes() {
    this.itemTypeId.setValue(null);
    this.filterData();
    this.itemTypesFilter = this.itemTypes.filter(i => i.itemCategoryId == this.itemCategoryId.value);
  }

  filterItemPropertyTypes() {
    this.itemPropertyTypeId.setValue(null);
    this.filterData();
    this.itemPropertyTypesFilter = _.differenceBy(this.itemPropertyTypes, this.dataFilter, 'itemPropertyTypeId');
  }

  createItemView(e): FormGroup {
    let propertyScope;
    if (e.required) propertyScope = 1
    else if (e.optional) propertyScope = 2
    else if (e.available) propertyScope = 3

    return this.fb.group({
      itemDefaultId: e.itemDefaultId,
      itemCategory: e.itemCategory,
      itemType: e.itemType,
      itemProperty: e.itemProperty,
      propertyScope: propertyScope
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.itemDefaultId == e.itemDefaultId);
    item.required = e.propertyScope == 1 ? true : false;
    item.optional = e.propertyScope == 2 ? true : false;
    item.available = e.propertyScope == 3 ? true : false;
    return {
      itemDefaultId: e.itemDefaultId,
      required: e.propertyScope == 1 ? true : false,
      optional: e.propertyScope == 2 ? true : false,
      available: e.propertyScope == 3 ? true : false
    };
  }

  add() {
    if (this.formAdd.valid) {
      const data = this.formAdd.value;
      switch (Number(data.propertyScope)) {
        case 1:
          data.required = true;
          break;
        case 2:
          data.optional = true;
          break;
        case 3:
          data.available = true;
          break;
        default:
          break;
      }
      this.formAdd.disable();
      this.settingService.addDefaultItemProperties(data).then(response => {
        if (response.status) {
          this.itemPropertyTypeId.reset();
          this.formAdd.patchValue({
            tenantId: this.tenantId,
            itemCategoryId: data.itemCategoryId,
            itemTypeId: data.itemTypeId,
            itemPropertyTypeId: '',
            propertyScope: 3,
            required: false,
            optional: false,
            available: false
          });
          this.toast.success('Add item default properties successfully');
          this.loadData();
        }
        else {
          this.toast.error(response.message);
        }
        this.formAdd.enable();
      });
    }
  }

  remove(id) {
    this.modalConfirm.open();
    this.idToRemove = id;
  }

  submitDelete() {
    this.settingService.removeDefaultItemProperties(this.idToRemove).then(response => {
      this.modalConfirm.close();
      if (response.status) {
        this.toast.success('Remove item default properties successfully');
        this.loadData();
      }
      else this.toast.error(response.message);
    });
  }

  save() {
    let dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateDefaultItemProperties(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Item default properties has been updated');
        }
        else this.toast.error('Update item default properties failed');
      });
    }
  }

  addIpt() {
    if (this.formAddIpt.valid) {
      this.settingService.addItemPropertyType(this.formAddIpt.value).then(response => {
        if (response.status) {
          this.toast.success('Item property type has been added');
          this.itemPropertyTypeId.setValue(Number(response.data));
          this.loadItemPropertyType(response.data);
          this.modalAddIpt.close();
        }
        else {
          if (response.code == 1) {
            this.toast.error('This property type already exists, please input again!');
          } else {
            this.toast.error('Add item property type failed');
          }
        }
      });
    }
  }
}
