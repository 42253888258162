import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-tile-process-standard',
  templateUrl: './process-standard.html',
})

export class TileProcessStandardComponent implements OnInit, OnDestroy {
  @Input('costSchemaId') costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('note') note: ElementRef;
  notes: any[];
  @ViewChild('modalViewComments') modalViewComments: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  constructor(private fb: FormBuilder, private toast: ToastrService,
    private settingService: SettingService, private userService: UserService) {}

  ngOnDestroy(){
    this.save();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getProcessStandard(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error("Load data failed");
    });
  }
  viewComment(item){
    const id = item.value.schemaProcessId;
    this.settingService.getMtxNoteProcessStandardById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      schemaProcessId: e.schemaProcessId,
      processName: e.processName,
      unit: e.unit,
      outputPerHour: new DecimalPipe('en-US').transform(e.outputPerHour, '.2-2'),
      stdHourlyRate: [{ value: new CurrencyPipe('en-US').transform(e.stdHourlyRate), disabled: true }],
      laborCostPerHourOverride: new CurrencyPipe('en-US').transform(e.laborCostPerHourOverride),
      note: e.note,
      updatedOn: e.updatedOn
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.rateId == e.rateId);
    item.outputPerHour = this.settingService.replaceMask(e.outputPerHour);
    item.laborCostPerHourOverride = this.settingService.replaceMask(e.laborCostPerHourOverride);
    item.note = e.note;
    const cell = this.data.find(i=> i.schemaProcessId == e.schemaProcessId);
    if(!!cell){
      cell.outputPerHour = this.settingService.replaceMask(e.outputPerHour);
      cell.laborCostPerHourOverride = this.settingService.replaceMask(e.laborCostPerHourOverride);
      cell.note = e.note;
    }
    return {
      schemaProcessId: e.schemaProcessId,
      outputPerHour: this.settingService.replaceMask(e.outputPerHour),
      laborCostPerHourOverride: this.settingService.replaceMask(e.laborCostPerHourOverride),
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: AbstractControl;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateProcessStandard(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Process standard has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
        }
        else this.toast.error('Update process standard failed');
      });
    }
  }
}
