<header class="main-header">
  <!-- Logo -->
  <a href="home" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">QXM</span>
    <!-- logo for regular state and mobile devices -->
    <!-- <span class="logo-lg">Construction-CPQ</span> -->
    <img src="assets/img/QXM-Pro-White-Green.png" style="height: 35px;"/>
  </a>

  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{userName}}</a>
          <ul class="dropdown-menu dropdown-menu-right">
            <li><a href="javascript:;" (click)="logout()">Sign out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>
