export const environment = {
  dev: false,
  qa: false,
  qa2: false,
  slot: true,
  staging: false,
  production: false,
  apiURL: 'http://westus-cpqbackendqa2-dev.azurewebsites.net/api/',
  linkDPT: '',
  activeReportServer: 'http://13.64.148.43:8080/api/',
  linkAPIToolCompare: '',
};
