<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: 22px;">Cost Schema Detail</label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i>Home</a></li>
      <li><a routerLink="/cost-schemas">Cost Schema</a></li>
      <li class="active">Detail</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-lg-6 col-xs-12">
         <div class="form-top">
           <form novalidate [formGroup]="form">
             <div class="row inline-block">
               <div class="col-sm-5 col-xs-12">
                 <div class="form-group">
                   <label for='sname'>Schema Name
                     <span class="text-danger">*</span>
                   </label>
                   <input id='sname' type="text" trim="blur" class="form-control" formControlName="schemaName" (blur)= 'save()' (keyup.enter) = 'save()' maxlength="250" required>
                   <ng-container *ngIf="schemaName.invalid && (schemaName.dirty || schemaName.touched)">
                     <span *ngIf="schemaName.errors.required" class="help-block">Schema name is required.</span>
                   </ng-container>
                 </div>
               </div>
               <div class="col-sm-5 col-xs-12">
                 <div class="form-group" >
                   <label for = 'disc'>Description</label>
                   <input id='disc' type="text" trim="blur" class="form-control" style="resize: none" formControlName="description" (blur)= 'save()' (keyup.enter) = 'save()'>
                 </div>
               </div>
               <div class="col-sm-2">
                 <div class="form-group" style="margin-top: 30px;">
                   <label for="act" class="checkbox-inline">
                     <input id= 'act' class="custom-control-input" style="vertical-align: text-bottom;" type="checkbox" formControlName="active" (change)= 'save()'>Active
                   </label>
                 </div>
               </div>
             </div>
           </form>
         </div>
      </div>
   </div>
   <div class="row">
       <div class="col-sm-12">
         <a class="pull-right btn-close" routerLink='/cost-schemas'><i class="fa fa-times-circle" aria-hidden="true"></i></a>
         <!-- <button class="pull-right bottom-right btn-close">X</button> -->
         <div class="nav-tabs-custom">
           <ul class="nav nav-tabs">
             <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
               <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
             </li>
             <div class="collapse navbar-collapse pull-right">
               <div>
                <i class="fa fa-cog fa-spin fa-2x fa-fw" *ngIf="loading"></i>
               </div>
               <div class="nav navbar-nav" *ngIf="!loading && !isTenantOnly">
                 <div class="dropdown">
                   <a href="javascript:" style="font-size: 20px; color: black;" class="dropdown-toggle" data-toggle="dropdown"><span><i class="fa fa-list-ul"></i></span></a>
                   <ul class="dropdown-menu" style="margin-left: -95px;">
                      <li><a href="javascript:" (click) = 'copy();loading = true;' class="text-center">Copy</a></li>
                      <li><a href="javascript:" (click) = 'delete();' class="text-center">Delete</a></li>
                      <li><a href="javascript:" (click) = 'modalConfirm.open();' class="text-center">Clear all comments</a></li>
                   </ul>
                 </div>
               </div>
             </div>
           </ul>
           <div class="tab-content">
             <div class="tab-pane box" id="ProcessStandard" *ngIf="curTab.id == 'ProcessStandard'">
               <tab-process-standard [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ProcessStandard')"></tab-process-standard>
             </div>
             <div class="tab-pane box" id="SubTypeWeights" *ngIf="curTab.id == 'SubTypeWeights'">
               <tab-sub-type-weights *ngIf="bidSubTypes" [costSchemaId]="schemaId" [bidSubTypes]="bidSubTypes" (calcHeightTable)="calcHeightTable('SubTypeWeights')"></tab-sub-type-weights>
             </div>
             <div class="tab-pane box" id="ApplicationWeights" *ngIf="curTab.id == 'ApplicationWeights'">
               <tab-application-weights [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('ApplicationWeights')"></tab-application-weights>
             </div>
             <div class="tab-pane box" id="MaterialWeights" *ngIf="curTab.id == 'MaterialWeights'">
               <tab-material-weights [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('MaterialWeights')"></tab-material-weights>
             </div>
             <div class="tab-pane box" id="MaterialDirection" *ngIf="curTab.id == 'MaterialDirection'">
               <tab-material-direction *ngIf="directionalities" [costSchemaId]="schemaId" [directionalities]="directionalities"  (calcHeightTable)="calcHeightTable('MaterialDirection')"></tab-material-direction>
             </div>
             <div class="tab-pane box" id="LayoutApproval" *ngIf="curTab.id == 'LayoutApproval'">
               <tab-layout-approval  [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('LayoutApproval')"></tab-layout-approval>
             </div>
             <div class="tab-pane box" id="EdgeBase" *ngIf="curTab.id == 'EdgeBase'">
               <tab-edge-base [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('EdgeBase')"></tab-edge-base>
             </div>
             <div class="tab-pane box" id="EdgeProcess" *ngIf="curTab.id == 'EdgeProcess'">
               <tab-edge-process [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('EdgeProcess')"></tab-edge-process>
             </div>
             <div class="tab-pane box" id="EdgeMaterial" *ngIf="curTab.id == 'EdgeMaterial'">
               <tab-edge-material [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('EdgeMaterial')"></tab-edge-material>
             </div>
             <div class="tab-pane box" id="BookmatchWeights" *ngIf="curTab.id == 'BookmatchWeights'">
               <tab-bookmatch-weights [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('BookmatchWeights')"></tab-bookmatch-weights>
             </div>
             <div class="tab-pane box" id="TieredVolumeSizing" *ngIf="curTab.id == 'TieredVolumeSizing'">
               <tab-tiered-volume-sizing [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('TieredVolumeSizing')"></tab-tiered-volume-sizing>
             </div>
             <div class="tab-pane box" id="ThicknessWeights" *ngIf="curTab.id == 'ThicknessWeights'">
               <tab-thickness-weights *ngIf="thicknesses" [costSchemaId]="schemaId" [thicknesses]="thicknesses"  (calcHeightTable)="calcHeightTable('ThicknessWeights')"></tab-thickness-weights>
             </div>
             <div class="tab-pane box" id="SiteAccessDifficulty" *ngIf="curTab.id == 'SiteAccessDifficulty'">
               <tab-site-access-difficulty *ngIf="siteAccesses" [costSchemaId]="schemaId" [siteAccesses]="siteAccesses"  (calcHeightTable)="calcHeightTable('SiteAccessDifficulty')"></tab-site-access-difficulty>
             </div>
             <div class="tab-pane box" id="BuildingLevels" *ngIf="curTab.id == 'BuildingLevels'">
               <tab-building-levels *ngIf="buildingLevels" [costSchemaId]="schemaId" [buildingLevels]="buildingLevels"  (calcHeightTable)="calcHeightTable('BuildingLevels')"></tab-building-levels>
             </div>
             <div class="tab-pane box" id="AdjustOnsite" *ngIf="curTab.id == 'AdjustOnsite'">
               <tab-adjust-onsite [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('AdjustOnsite')"></tab-adjust-onsite>
             </div>
             <div class="tab-pane box" id="OnsitePolish" *ngIf="curTab.id == 'OnsitePolish'">
               <tab-onsite-polish [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('OnsitePolish')"></tab-onsite-polish>
             </div>
             <div class="tab-pane box" id="ApplicationWaste" *ngIf="curTab.id == 'ApplicationWaste'">
               <tab-application-waste  [costSchemaId]="schemaId"  (calcHeightTable)="calcHeightTable('ApplicationWaste')"></tab-application-waste>
             </div>
             <div class="tab-pane box" id="supplyOverrides" *ngIf="curTab.id == 'supplyOverrides'">
               <tab-supply-overrides [costSchemaId]="schemaId" [channels]="channels" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('supplyOverrides')"></tab-supply-overrides>
             </div>
             <div class="tab-pane box" id="materialRemove" *ngIf="curTab.id == 'materialRemove'">
               <tab-material-removal [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('materialRemove')"></tab-material-removal>
             </div>
             <div class="tab-pane box" id="ChangeLogSlabCost" *ngIf="curTab.id == 'ChangeLogSlabCost'">
               <tab-change-log-slab-cost [costSchemaId]="schemaId" (calcHeightTable)="calcHeightTable('ChangeLogSlabCost')"></tab-change-log-slab-cost>
             </div>
           </div>
         </div>
       </div>
   </div>
  </section>
</div>

<bs-modal #modal data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div>
      <label class="row col-sm-12">Currently, this cost schema is assigned to a channel.  You must assign another cost schema to channel prior to deleting Cost Schema.</label>
      <select class="row col-sm-12" style="width: 300px; height: 40px; margin-left: 10px;" [(ngModel)] = 'reassignSchemaId'>
        <option [value]="0">None</option>
        <option *ngFor="let c of activeCostSchemas" [value]="c.schemaId">{{ c.schemaName }}</option>
      </select>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-primary" [disabled]= "reassignSchemaId < 1"  (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close(); loading = false;">No</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure? This will remove all comments
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-danger" (click)="submitClearAllComments()" id="submitClearAllComments">Yes</button>
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">No</button>
  </bs-modal-footer>
</bs-modal>