import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-sets',
  templateUrl: './global-sets.html',
})

export class TileSetsGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  setName: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  setId: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileSets(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      setId: e.setId,
      setName: [e.setName, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedOn: e.updatedOn,
    });
  }

  validData() {
    if (this.setName == null || this.setName == '' || this.setName.length < 2) {
      this.toast.error('Set name needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addTileSets({
        tenantId: 0,
        setName: this.setName,
      }).then(response => {
        if (response.status) {
          this.setName = '';
          this.toast.success('New set has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The set is added fail');
        }
      });
    }
  }

  delete(setId) {
    this.modalConfirm.open();
    this.setId = setId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteTileSets(this.setId).then(response => {
      if (response.status) {
        this.toast.success('This set has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.setId == e.setId);
    item.setName = e.setName;
    item.active = e.active;
    return {
      setId: e.setId,
      setName: e.setName,
      active: e.active,
      isGlobal: true
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.setName == null || e.value.setName.trim() == "" || e.value.setName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Set name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileSets(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Set has been updated');
        }
        else this.toast.error('Update set failed');
      });
    }
  }
}
