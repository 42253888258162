import { Component } from '@angular/core';
declare var $: any;

@Component({
  templateUrl: './not-found.html'
})

export class NotFoundComponent {
  constructor() { }

  ngOnInit() {
    $('body').layout('fix');
  }
}