<div class="row">
  <div *ngIf="!isTenantOnly">
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Application Configuration</h4>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Application</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="application" maxlength="200" trim="blur">
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Unit Of Measure</label>
        <select [(ngModel)]="ddlUnitOfdMeasure"   class="form-control">
          <option *ngFor="let t of unitsOfMeasure" [ngValue]="t.unitId">{{t.unit}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Default Supply Rate</label>
        <div class="row">
          <div class="col-sm-8">
            <input type="text" class="form-control text-right" prefix="$" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" [allowNegativeNumbers]="true" [(ngModel)]="txtDefaultSupplyRate">
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn btn-primary" (click)="addApplication()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th [style.width.px]="130">Unit Of Measure</th>
              <th [style.width.px]="150">Default Supply Rate</th>
              <th>Updated On</th>
              <th [style.width.px]="96" *ngIf="!isTenantOnly"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td *ngIf="!isTenantOnly">
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.applicationName.invalid && item.controls.applicationName.dirty && item.controls.applicationName.touched">
                      <input type="text" class="form-control" formControlName="applicationName" maxlength="200" trim="blur" (input)="checkDuplicate(item.controls)"  required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td *ngIf="isTenantOnly">{{item.value.applicationName}}</td>
                  <td [style.width.px]="130">{{item.value.unit}}</td>
                  <td [style.width.px]="150" *ngIf="!isTenantOnly">
                    <input type="text" class="form-control text-right" prefix="$" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" [allowNegativeNumbers]="true" formControlName="defaultSupplyRate" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="150" *ngIf="isTenantOnly">${{item.value.defaultSupplyRate}}</td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="96" *ngIf="!isTenantOnly">
                    <button type="button" class="btn btn-primary" (click)="deleteApplication(item.value.applicationId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this application? Removing the application type will remove all rules and bids associated to this application.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
