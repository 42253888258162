import { LicenseTypeService } from './../../../services/license-type.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TenantsService } from '../../../services/tenants.service';
import { SettingService } from '../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { LocationService } from 'src/app/services/location.service';
import { AddressService } from 'src/app/services/address.service';
declare var $: any;

@Component({
  templateUrl: './details.html',
  styleUrls: ['./details.scss']
})

export class TenantDetailsComponent implements OnInit {
  tenantId: any;
  tenantInfo: any;
  tenantDetailForm: FormGroup;
  tenantConfigForm: FormGroup;
  tenantIntegrateForm: FormGroup;
  timezones: any;
  states: any;
  modules: any;
  loading = false;
  licenseTypes = [];
  userCountValid = true;
  old: any;

  tenantSubscription: any;
  loadingSubscription = false;
  @ViewChild('modalSubscription') modalSubscription: BsModalComponent;
  // tslint:disable-next-line:variable-name
  modal_title_subscription = '';
  formAddOrEditSubscription: FormGroup;

  // Location
  locations: any;
  loadingLocation = false;
  @ViewChild('modalLocation') modalLocation: BsModalComponent;
  // tslint:disable-next-line:variable-name
  modal_title_location = '';
  formAddOrEditLocation: FormGroup;

  // Modal Delete Location
  @ViewChild('modalDeleteLocation') modalDeleteLocation: BsModalComponent;
  dstLocationId = 0;
  deleteLocationId = 0;
  filterLocations: any[];

  // Modal Reassign Location
  @ViewChild('modalReassignLocation') modalReassignLocation: BsModalComponent;

  // Address
  loadingAddress = false;
  @ViewChild('modalAddress') modalAddress: BsModalComponent;
  formAddAddress: FormGroup;

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private tenantService: TenantsService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private locationService: LocationService,
    private licenseTypeService: LicenseTypeService,
    private addressService: AddressService) {

    activatedRoute.params.subscribe(params => {
      this.tenantId = params.id;
      if (!Number(this.tenantId)) { this.router.navigate(['/not-found']); }
    });

    this.formAddOrEditSubscription = this.formBuilder.group({
      id: [0],
      tenantId: [0],
      moduleId: ['', Validators.required],
      startDate: [],
      termId: [1],
      amount: [],
      nextBillingDate: [],
      status: [1],
      isEdit: [0]
    });

    this.formAddOrEditLocation = this.formBuilder.group({
      locationId: [0],
      tenantId: [0],
      locationName: ['', Validators.required],
      active: [true],
      // Address
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
    });

    this.formAddAddress = this.formBuilder.group({
      locationId: [0],
      addressType: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      active: [true]
    });
  }

  getLicenseTypes(moduleId?: number | null) {
    this.licenseTypes = [];
    if (moduleId == null) { return; }
    this.licenseTypeService.getLicenseTypeByModuleTenant(moduleId, this.tenantDetailForm.get('tenantId').value).then(res => {
      if (!res.status) { this.toastr.error('Can\'t get list license types'); return; }
      this.licenseTypes = res.data;
    });
  }

  get tenantName(): FormControl {
    return this.tenantDetailForm.get('tenantName') as FormControl;
  }
  get company(): FormControl {
    return this.tenantDetailForm.get('company') as FormControl;
  }
  get website(): FormControl {
    return this.tenantDetailForm.get('website') as FormControl;
  }
  get phone(): FormControl {
    return this.tenantDetailForm.get('phone') as FormControl;
  }
  get timeZoneId(): FormControl {
    return this.tenantDetailForm.get('timeZoneId') as FormControl;
  }
  get showEdgeImg(): FormControl {
    return this.tenantConfigForm.get('showEdgeImg') as FormControl;
  }

  get url(): FormControl {
    return this.tenantIntegrateForm.get('url') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    this.userCountValid = true;

    this.tenantService.getTimeZone().then(response => {
      if (response.status) { this.timezones = response.data; }
      else { this.toastr.error('Can\'t load list timezone'); }
    });

    this.settingService.getListState().then(response => {
      if (response.status) { this.states = response.data; }
      else { this.toastr.error('Can\'t get list state'); }
    });

    this.settingService.getListModule().then(response => {
      if (response.status) { this.modules = response.data; }
      else { this.toastr.error('Can\'t get list module'); }
    });

    this.tenantService.getTenantDetails(this.tenantId).then(res => {
      if (res.status) {
        this.tenantInfo = res.data;
        this.tenantDetailForm = this.formBuilder.group({
          tenantId: [this.tenantInfo.tenantId],
          tenantName: [this.tenantInfo.tenantName, [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
          company: [this.tenantInfo.company, [Validators.required]],
          website: [this.tenantInfo.website, [Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
          phone: [this.tenantInfo.phone, [Validators.pattern('[0-9]{10}')]],
          timeZoneId: [this.tenantInfo.timeZoneId, Validators.required],
          active: [this.tenantInfo.active]
        });

        this.tenantSubscription = this.tenantInfo.subscriptions;
        this.locations = this.tenantInfo.locations;
        const integration = this.tenantInfo.integration;

        this.tenantIntegrateForm = this.formBuilder.group({
          tenantId: [this.tenantInfo.tenantId],
          label: [integration?.label ?? ''],
          url: [integration?.url ?? '', [Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
          active: [integration?.active ?? false],
          key: ['CONTRACT_RIGHTMENU'],
        })
      }
    });
  }

  private loadData() {
    this.tenantService.getTenantDetails(this.tenantId).then(res => {
      if (res.status) {
        this.tenantInfo = res.data;
        this.tenantSubscription = this.tenantInfo.subscriptions;
        this.locations = this.tenantInfo.locations;
      }
    });
  }

  updateConfiguration() {
    this.tenantInfo.isShowEdgeImage = !this.tenantInfo.isShowEdgeImage;

    this.loading = true;
    this.tenantService.UpdateTenant(this.tenantInfo).then(res => {
      this.loading = false;
      if (res.status && res.data) {
        this.toastr.success(res.message, 'Tenant has been updated');
        this.router.navigate(['/tenants']);
      }
      else {
        this.tenantInfo.isShowEdgeImage = !this.tenantInfo.isShowEdgeImage;
        this.tenantName.setErrors({ isExistTenant: true });
        this.toastr.error(res.message, 'Edit tenant failed');
      }
    });
  }

  save() {
    this.loading = true;
    this.tenantService.UpdateTenant(this.tenantDetailForm.value)
      .then(response => {
        this.loading = false;
        if (response.status && response.data) {
          this.saveIntegrationForm();
          this.toastr.success(response.message, 'Tenant has been updated');
          this.router.navigate(['/tenants']);
        }
        else {
          this.tenantName.setErrors({ isExistTenant: true });
          this.toastr.error(response.message, 'Edit tenant failed');
        }
      }).catch(() => {
        this.loading = false;
        this.toastr.error('Internal Server Error', 'Error');
      });
  }

  saveIntegrationForm() {
    this.tenantService.UpdateTenantIntegration(this.tenantIntegrateForm.value)
  }

  // For subscription
  get id(): FormControl {
    return this.formAddOrEditSubscription.get('id') as FormControl;
  }
  get moduleId(): FormControl {
    return this.formAddOrEditSubscription.get('moduleId') as FormControl;
  }
  get startDate(): FormControl {
    return this.formAddOrEditSubscription.get('startDate') as FormControl;
  }
  get nextBillingDate(): FormControl {
    return this.formAddOrEditSubscription.get('nextBillingDate') as FormControl;
  }

  openPopupSubscription(item?) {
    this.userCountValid = true;
    if (item) {
      this.modal_title_subscription = 'Edit Subscription';
      this.formAddOrEditSubscription.reset();
      this.formAddOrEditSubscription.patchValue({
        id: item.id,
        tenantId: item.tenantId,
        moduleId: item.moduleId,
        startDate: new Date(item.startDate).toLocaleDateString('en-US'),
        termId: item.termId,
        amount: item.amount,
        nextBillingDate: new Date(item.nextBillingDate).toLocaleDateString('en-US'),
        status: item.status
      });
      this.moduleId.disable();
    }
    else {
      this.modal_title_subscription = 'Add Subscription';
      this.formAddOrEditSubscription.reset();
      this.formAddOrEditSubscription.patchValue({
        id: 0,
        tenantId: this.tenantId,
        moduleId: '',
        startDate: '',
        termId: 1,
        amount: null,
        nextBillingDate: '',
        status: 1
      });
      this.moduleId.enable();
    }
    this.modalSubscription.open();

    $('#startDate').datepicker({ autoclose: true }).on('changeDate', (e) => {
      this.startDate.setValue(e.date.toLocaleDateString('en-US'));
    });

    $('#nextBillingDate').datepicker({ autoclose: true }).on('changeDate', (e) => {
      this.nextBillingDate.setValue(e.date.toLocaleDateString('en-US'));
    });
  }

  submitSubscription() {
    if (this.id.value > 0) {
      this.updateSubscription();
    }
    else { this.addSubscription(); }
  }

  addSubscription() {
    this.loadingSubscription = true;
    this.tenantService.addSubscription({
      ...this.formAddOrEditSubscription.value,
      licenseTypes: this.licenseTypes.map(obj => ({ licenseTypeId: obj.licenseTypeId, maxUser: obj.maxUser }))
    }).then(res => {
      this.loadingSubscription = false;
      if (res.status) {
        this.loadData();
        this.toastr.success('Subscription have been added');
        this.modalSubscription.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  private getLicense() {
    return this.licenseTypes.map(obj => ({ licenseTypeId: obj.licenseTypeId, maxUser: obj.maxUser }));
  }

  checkUserCountValid(licenseType, oldValue) {
    this.userCountValid = false;
    const license = this.getLicense();
    this.licenseTypeService.checkUserCountValid(this.tenantId, license)
      .then(res => {
        if (!res.status) {
          this.toastr.error(res.message);
          licenseType.maxUser = oldValue;
          return;
        }
        this.userCountValid = true;
      });
  }


  updateSubscription() {
    this.loadingSubscription = true;
    const license = this.getLicense();
    this.tenantService.updateSubscription(this.id.value, {
      ...this.formAddOrEditSubscription.value,
      licenseTypes: license
    }).then(response => {
      this.loadingSubscription = false;
      if (response.status) {
        this.loadData();
        this.modalSubscription.close();
        this.toastr.success('Subcription has been updated.');
      }
      else {
        this.toastr.error(response.message);
      }
    });
  }

  // For tenant Location
  get locationId(): FormControl {
    return this.formAddOrEditLocation.get('locationId') as FormControl;
  }
  get locationName(): FormControl {
    return this.formAddOrEditLocation.get('locationName') as FormControl;
  }
  get activeL(): FormControl {
    return this.formAddOrEditLocation.get('active') as FormControl;
  }
  get address1L(): FormControl {
    return this.formAddOrEditLocation.get('address1') as FormControl;
  }
  get address2L(): FormControl {
    return this.formAddOrEditLocation.get('address2') as FormControl;
  }
  get cityL(): FormControl {
    return this.formAddOrEditLocation.get('city') as FormControl;
  }
  get stateL(): FormControl {
    return this.formAddOrEditLocation.get('state') as FormControl;
  }
  get postalCodeL(): FormControl {
    return this.formAddOrEditLocation.get('postalCode') as FormControl;
  }

  openPopupLocation(item?) {
    if (item) {
      this.modal_title_location = 'Edit Location';
      this.formAddOrEditLocation.reset();
      this.formAddOrEditLocation.patchValue({
        locationId: item.locationId,
        tenantId: item.tenantId,
        locationName: item.locationName,
        active: item.active,
      });
      this.clearValidators();
    }
    else {
      this.modal_title_location = 'Add Location';
      this.formAddOrEditLocation.reset();
      this.formAddOrEditLocation.patchValue({
        locationId: 0,
        tenantId: this.tenantId,
        locationName: '',
        active: true,
      });
      this.setValidators();
    }
    this.modalLocation.open();
  }

  private setValidators() {
    this.address1L.setValidators([Validators.required]);
    this.address1L.updateValueAndValidity();
    this.cityL.setValidators([Validators.required]);
    this.cityL.updateValueAndValidity();
    this.stateL.setValidators([Validators.required]);
    this.stateL.updateValueAndValidity();
    this.postalCodeL.setValidators([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')]);
    this.postalCodeL.updateValueAndValidity();
  }

  private clearValidators() {
    this.address1L.clearValidators();
    this.address1L.updateValueAndValidity();
    this.cityL.clearValidators();
    this.cityL.updateValueAndValidity();
    this.stateL.clearValidators();
    this.stateL.updateValueAndValidity();
    this.postalCodeL.clearValidators();
    this.postalCodeL.updateValueAndValidity();
  }

  submitLocation() {
    if (this.locationId.value > 0) {
      this.updateLocation();
    }
    else {
      this.addLocation();
    }
  }

  private addLocation() {
    this.loadingLocation = true;
    this.locationService.addLocation(this.tenantId, this.formAddOrEditLocation.value).then(res => {
      this.loadingLocation = false;
      if (res.status) {
        this.loadData();
        this.modalLocation.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  private updateLocationActive(item: any): void {
    let oldVal = item.active;
    let newVal = !oldVal;

    if (!newVal) {
      // each tenant must have at least one active location.
      const existedActive = this.locations.filter(x => x.locationId !== item.locationId && x.active);
      if (existedActive.length === 0) {
        return;
      }
    }

    let patchVal = {
      active: newVal,
    };
    this.formAddOrEditLocation.patchValue(patchVal);

    this.locationService.updateLocationAndAddress(item.locationId, this.tenantId, patchVal).then(response => {
      if (response.status) {
        // Update local data
        item.active = newVal;
        this.toastr.success(response.message);
      } else {
        // Update local data
        item.active = oldVal;
        this.toastr.error(response.message);
      }
    });
  }

  private updateLocation(): void {
    this.loadingLocation = true;
    this.locationService.updateLocationAndAddress(this.locationId.value, this.tenantId, this.formAddOrEditLocation.value).then(response => {
      this.loadingLocation = false;
      if (response.status) {
        this.loadData();
        this.modalLocation.close();
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  confirmDeleteLocation(locationId: number) {
    this.deleteLocationId = locationId;
    this.modalDeleteLocation.open();
    // this.filterLocations = this.locations.filter(x => x.locationId !== this.deleteLocationId && x.active);
  }

  deleteLocation() {
    this.locationService.deleteLocation(this.deleteLocationId, this.tenantId, 0).then(response => {
      this.loadingLocation = false;
      if (response.status) {
        this.loadData();
        this.toastr.success('Location has been deleted');

        // Clean Delete Modal
        this.deleteLocationId = 0;
        // this.dstLocationId = 0;
        // this.filterLocations = [];
        this.modalDeleteLocation.close();
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  countActiveLocations() {
    return this.locations.filter(x => x.active).length;
  }

  // Address
  get addressType(): FormControl {
    return this.formAddAddress.get('addressType') as FormControl;
  }
  get address1(): FormControl {
    return this.formAddAddress.get('address1') as FormControl;
  }
  get address2(): FormControl {
    return this.formAddAddress.get('address2') as FormControl;
  }
  get city(): FormControl {
    return this.formAddAddress.get('city') as FormControl;
  }
  get state(): FormControl {
    return this.formAddAddress.get('state') as FormControl;
  }
  get postalCode(): FormControl {
    return this.formAddAddress.get('postalCode') as FormControl;
  }

  openPopupAddress(locationId: number) {
    this.formAddAddress.reset();
    this.formAddAddress.patchValue({
      locationId,
      addressType: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      active: true,
    });
    this.modalAddress.open();
  }

  submitAddress() {
    this.addAddress();
  }

  private addAddress() {
    this.loadingAddress = true;
    this.addressService.addAddress(this.formAddAddress.value).then(res => {
      this.loadingAddress = false;
      if (res.status) {
        this.loadData();
        this.modalAddress.close();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  changeActive(item: any): void {
    let oldVal = item.active;
    let newVal = !oldVal;

    if (newVal) {
      this.updateLocationActive(item);
      return;
    }
    this.locationService.checkDeleteLocation(item.locationId, this.tenantId).then(res => {
      if (res.status) {
        this.updateLocationActive(item);
      } else {
        // Open modal reassign location
        this.deleteLocationId = item.locationId;
        this.filterLocations = this.locations.filter(x => x.locationId !== this.deleteLocationId && x.active);
        this.modalReassignLocation.open();
      }
    });
  }

  reassignLocation(): void {
    this.locationService.updateLocationAndAddress(this.deleteLocationId, this.tenantId, {
      active: false,
      newAssignLocationId: this.dstLocationId,
    }).then(res => {
      if (res.status) {
        this.toastr.success(res.message);

        // Update local data
        let found = this.locations.find(x => x.locationId == this.deleteLocationId);
        if (found) {
          found.active = false;
        }

        // Clean Delete Modal
        this.deleteLocationId = 0;
        this.dstLocationId = 0;
        this.filterLocations = [];
        this.modalReassignLocation.close();
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}
