<div class="row">
  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Tab Name</th>
            <th>Description</th>
            <th>Field Name</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Note</th>
            <th>User</th>
            <th>Updated Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataLog">
            <td>{{ item.tabName }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.valueName }}</td>
            <td>{{ item.oldValue }}</td>
            <td>{{ item.newValue }}</td>
            <td>{{ item.note }}</td>
            <td>{{ item.updatedBy }}</td>
            <td>{{ item.updatedTime | date:'MM/dd/yyyy h:mm a' }}</td>
          </tr>
        </tbody>
      </table>
      <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="paging.pageNumber" [perPage]="paging.itemPerPage" [count]="paginationCount"></my-pagination>
    </div>
  </div>
</div>
