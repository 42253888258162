import { Injectable } from '@angular/core';

@Injectable()
export class Config {
  public static BusinessUnitId = {
    Slab: 1,
    Tile: 2,
  };

  public static ApplicationTypeId = {
    Slab: 0,
    FlatWork: 1,
    Vertical: 2,
  };

  public static ProcessTypeId = {
    Tile: 1,
    Fab: 2,
    Install: 3,
  };
}
