import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-item-types',
  templateUrl: './item-types.html',
  styleUrls: ['./item-types.scss']
})

export class ItemTypesComponent implements OnInit {
  public static ItemCategory = {
    Part: 1,
    Service: 2,
    Fee: 3,
    Credit: 4,
  };

  @Input() tenantId: any;
  @Input() itemCategories: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  formAdd: FormGroup;
  idToRemove: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
    this.formAdd = this.fb.group({
      tenantId: [this.tenantId],
      itemType: ['', Validators.required],
      typeDescription: [''],
      itemCategoryId: [, Validators.required],
      defaultChargeInstallOverhead: [true],
      defaultChargeSgna: [true],
      defaultChargeProfit: [true]
    });
  }

  get itemCategoryId(): FormControl {
    return this.formAdd.get('itemCategoryId') as FormControl;
  }
  getErrorItemCategoryId() {
    return this.itemCategoryId.hasError('required') ? 'Item category cannot be empty' : '';
  }

  get itemType(): FormControl {
    return this.formAdd.get('itemType') as FormControl;
  }
  getErrorItemType() {
    return this.itemType.hasError('required') ? 'Item type cannot be empty' : '';
  }

  get chargeOverhead(): FormControl {
    return this.formAdd.get('defaultChargeInstallOverhead') as FormControl;
  }
  get chargeSgna(): FormControl {
    return this.formAdd.get('defaultChargeSgna') as FormControl;
  }
  get chargeProfit(): FormControl {
    return this.formAdd.get('defaultChargeProfit') as FormControl;
  }

  loadData() {
    this.loading = true;
    this.settingService.getItemTypes(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    let isDisable: boolean = false;
    if (e.itemCategoryId == ItemTypesComponent.ItemCategory.Credit)
    {
      isDisable = true;
    }
    return this.fb.group({
      itemTypeId: e.itemTypeId,
      itemCategory: e.itemCategory,
      itemType: [e.itemType, Validators.required],
      typeDescription: e.typeDescription,
      defaultChargeInstallOverhead: [{ value: e.defaultChargeInstallOverhead, disabled: isDisable }],
      defaultChargeSgna: [{ value: e.defaultChargeSgna, disabled: isDisable }],
      defaultChargeProfit: [{ value: e.defaultChargeProfit, disabled: isDisable }]
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.itemTypeId == e.itemTypeId);
    item.itemType = e.itemType;
    item.typeDescription = e.typeDescription;
    item.defaultChargeInstallOverhead = e.defaultChargeInstallOverhead;
    item.defaultChargeSgna = e.defaultChargeSgna;
    item.defaultChargeProfit = e.defaultChargeProfit;
    return {
      itemTypeId: e.itemTypeId,
      itemType: e.itemType,
      typeDescription: e.typeDescription,
      defaultChargeInstallOverhead: e.defaultChargeInstallOverhead,
      defaultChargeSgna: e.defaultChargeSgna,
      defaultChargeProfit: e.defaultChargeProfit,
      canPricing: e.canPricing
    };
  }

  add() {
    this.itemType.markAsPristine();
    this.itemType.markAsUntouched();
    this.itemCategoryId.markAsPristine();
    this.itemCategoryId.markAsUntouched();
    if (this.formAdd.valid) {
      this.formAdd.disable();
      this.settingService.addItemTypes(this.formAdd.value).then(response => {
        if (response.status) {
          this.formAdd.reset();
          this.formAdd.patchValue({
            tenantId: this.tenantId,
            itemType: '',
            typeDescription: '',
            itemCategoryId: null,
            defaultChargeInstallOverhead: true,
            defaultChargeSgna: true,
            defaultChargeProfit: true
          });
          this.toast.success('Item types has been added');
          this.loadData();
        }
        else {
          this.toast.error(response.message);
        }
        this.formAdd.enable();
      });
    }
  }

  remove(id) {
    this.modalConfirm.open();
    this.idToRemove = id;
  }

  submitDelete() {
    this.settingService.removeItemTypes(this.idToRemove).then(response => {
      this.modalConfirm.close();
      if (response.status) {
        this.toast.success('Item types has been removed');
        this.loadData();
      }
      else this.toast.error(response.message);
    });
  }

  save() {
    let dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty && e.valid) {
        if (e.get('defaultChargeInstallOverhead').dirty || e.get('defaultChargeSgna').dirty || e.get('defaultChargeProfit').dirty) e.value.canPricing = true;
        else e.value.canPricing = false;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateItemTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Item types has been updated');
          if (dataUpdate.some(d => d.canPricing == true)) {
            // this.settingService.setBidsIsRefesh(this.tenantId);
            this.reloadStatusBatchVersions.emit();
          }
        }
        else this.toast.error('Update item types failed');
      });
    }
  }

  changeItemCategory(): void {
    switch (this.itemCategoryId.value) {
      case ItemTypesComponent.ItemCategory.Part:
      case ItemTypesComponent.ItemCategory.Service:
      case ItemTypesComponent.ItemCategory.Fee:
        this.chargeOverhead.enable();
        this.chargeSgna.enable();
        this.chargeProfit.enable();
        break;
      case ItemTypesComponent.ItemCategory.Credit:
        this.chargeOverhead.setValue(false);
        this.chargeOverhead.disable();
        this.chargeSgna.setValue(false);
        this.chargeSgna.disable();
        this.chargeProfit.setValue(false);
        this.chargeProfit.disable();
        break;
    }
  }
}
