<div class="pagination" *ngIf="count > 0">
	<div class="description hidden-xs hidden-sm">
		<span class="page-counts">{{ getMin() }} - {{ getMax() }} of {{ count }}</span>
		/
    <span class="page-totals">{{ totalPages() }} pages</span>
	</div>
	<div class="numbers">
		<button class="link" (click)="bugOnPrev()" [disabled]="page === 1 || loading" [ngClass]="{ 'disabled': page === 1 || loading }">&lt; Previous</button>
		
		<button
			*ngFor="let pageNum of getPages()"
			(click)="onPage(pageNum)"
			[ngClass]="{'active': pageNum === page, 'disabled': loading}">{{ pageNum }}</button>

		<button class="link" (click)="bugOnNext()" [disabled]="lastPage() || loading" [ngClass]="{ 'disabled': lastPage() || loading }">Next &gt;</button>
	</div>
</div>