<div class="content-wrapper">
  <section class="content-header">
    <h1>Pricing Recalculation</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Pricing Recalculation</li>
    </ol>
  </section>

  <section class="content">
    <!-- <div class="form-group">
      <button type="button" class="btn btn-primary" (click)="refreshPage()">Refresh</button>
    </div> -->
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Select Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12" *ngIf="!loadingTenant && tenantId > 0">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!schemas"></i>
        <div class="form-group" *ngIf="schemas">
          <label>Select Price Schema</label>
          <select [(ngModel)]="schemaPriceId" (change)="changeSchema()" class="form-control">
            <option [ngValue]="0">All</option>
            <ng-container *ngFor="let s of schemas">
              <option [ngValue]="s.schemaPriceId">{{s.schemaPriceName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-sm-4 col-xs-12 p-btn">
        <button type="button" *ngIf="schemaPriceId > 0" [disabled]="loading" class="btn btn-primary" (click)="modalCalculateBids.open()">Recalculate Open Bids</button>
        <button type="button" *ngIf="schemaPriceId > 0" [disabled]="loading" class="btn btn-primary" (click)="getInprogressBids()">Reload</button>
      </div>
    </div>

    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id"
          (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.name}}</a>
        </li>
        <div class="collapse navbar-collapse pull-right">
          <div>
            <i class="fa fa-cog fa-spin fa-2x fa-fw" *ngIf="loading"></i>
          </div>
        </div>
      </ul>
      <div class="tab-role-content" [style.min-height.px]="300">
        <div class="tab-pane box" id="bid_calculate" *ngIf="curTab.id == 'bid_calculate'">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Bid Id</th>
                  <th>Reference</th>
                  <th>Version Number</th>
                  <th>Bid Name</th>
                  <th>Bid Version Status</th>
                  <th>Last Progressing Time</th>
                  <th>Job Status</th>
                </tr>
              </thead>
              <tbody>
                <div class="text-center" *ngIf="!(bids.length > 0)">
                  No Data
                </div>
                <tr *ngFor="let bid of bids">
                  <td>
                    {{bid.bidId}}
                  </td>
                  <td>
                    {{bid.bidReference}}
                  </td>
                  <td>
                    {{bid.versionNumber}}
                  </td>
                  <td>
                    {{bid.bidName}}
                  </td>
                  <td>
                    {{bid.versionTypeName}}: {{bid.bidVersionStatusName}}
                  </td>
                  <td>
                    {{bid.progressingTime | date:'MM/dd/yyyy h:mm a'}}
                  </td>
                  <td>
                    {{bid.isProgressing ? 'Queued' : 'Completed' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tab-pane box" id="price_calculate" *ngIf="curTab.id == 'price_calculate'">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th [style.width.px]="40" class="text-center">#</th>
                  <th style="cursor: pointer;" (click)="changeFilter('tenantName')">Tenant Name
                    <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'tenantName' && filter.order == 'desc'"></i>
                    <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'tenantName' && filter.order == 'asc'"></i>
                  </th>
                  <th style="cursor: pointer;" (click)="changeFilter('pricingRecalculationStatus')">Status
                    <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'pricingRecalculationStatus' && filter.order == 'desc'"></i>
                    <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'pricingRecalculationStatus' && filter.order == 'asc'"></i>
                  </th>
                  <th [style.width.px]="120" class="text-center">Recalculate</th>
                  <th [style.width.px]="120" class="text-center">Show Log</th>
                </tr>
              </thead>
              <tbody>
                <div class="text-center" *ngIf="!(tenantCalculates?.length > 0)">
                  No Data
                </div>
                <tr *ngFor="let item of tenantCalculates; let index = index">
                  <td [style.width.px]="40" class="text-center">{{ filter.itemPerPage * (filter.pageNumber - 1) + index + 1 }}</td>
                  <td>{{ item.tenantName }}</td>
                  <td>{{ item.pricingRecalculationStatus }}</td>
                  <td [style.width.px]="120" class="text-center">
                    <a href="javascript:;" class="fa fa-refresh fa-lg text-danger" title="Recalculate" (click)="recalculatePricing(item)"></a>
                  </td>
                  <td [style.width.px]="120" class="text-center">
                    <a href="javascript:;" class="fa fa-pencil-square-o fa-lg text-success" title="Show Log" (click)="showLog(item)"></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10" [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="form">
      <div class="form-group">
        <label>Log</label>
        <textarea rows="30" trim="blur" class="form-control" formControlName="pricingRecalculationLog" maxlength="50" required></textarea>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loading_submit" class="fa fa-spinner fa-spin" aria-hidden="true" style="font-size: 20px; margin-right: 15px;"></i>
    <button type="button" [disabled]="loading_submit" class="btn btn-default" (click)="modal.close()">OK</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalCalculateBids>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure?
    This will recalculate all open Bids with designated Price Schema for a single Tenant.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalCalculateBids.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="recalculate()" id="recalculate">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmCancel>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Pricing recalculation process is started. Do you want to cancel this process?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmCancel.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="cancelRecalculation()" id="cancelRecalculation">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalCalculate>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to recalculate pricing for all Bids of this tenant?
    Pricing recalculation is long-running process and will be executed under background mode. Please refresh the page to see the updated status of recalculating process.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalCalculate.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="startRecalculation()" id="startRecalculation">Submit</button>
  </bs-modal-footer>
</bs-modal>
