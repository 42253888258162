import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { PercentPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { SlabCostDetailService } from 'src/app/services/slab-cost-detail.service';

@Component({
    selector: 'tab-application-waste',
    templateUrl: './application-waste.html',
    styleUrls: ['./application-waste.scss']
})

export class ApplicationWasteComponent implements OnInit, OnDestroy {
    @Input() costSchemaId: any;
    // @Input() channels: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('modalViewComments') modalViewComments: any;
    notes: any;
    //channelId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    isApp: boolean;
    dataMaterial: any;
    materialForm: FormGroup;
    currentTab = 'ApplicationWaste';
    currentId: number;


    constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService,
        private slabCostDetailService: SlabCostDetailService) {

    }

    ngOnInit() {
        this.isApp = false;
        this.loadData();
    }
    ngOnDestroy() {
        this.save();
    }
    viewComment(item: any) {
        if (this.isApp) {
            this.currentId = item.value.appWasteId;
            this.settingService.getMtxNoteApplicationWasteById(this.currentId).then(res => {
                if (res.status) {
                    this.notes = res.data;
                    this.modalViewComments.open();

                    //Update last comment when delete comment
                    this.slabCostDetailService.getMessages().subscribe(data => {
                        if (!data) {
                            return;
                        }

                        switch (data.messageType) {
                            case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                                const payload = data.payload;
                                if (payload) {
                                    item.controls.note.setValue(payload.note);
                                    item.controls.updatedOn.setValue(payload.updateOn);
                                }
                                break;
                        }
                    });
                }
                else this.toast.error('view comment failed.');
            });
        }
        else {
            this.currentId = item.value.matWasteId;
            this.settingService.getMtxNoteMaterialWasteById(this.currentId).then(res => {
                if (res.status) {
                    this.notes = res.data;
                    this.modalViewComments.open();

                    //Update last comment when delete comment
                    this.slabCostDetailService.getMessages().subscribe(data => {
                        if (!data) {
                            return;
                        }

                        switch (data.messageType) {
                            case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                                const payload = data.payload;
                                if (payload) {
                                    item.controls.note.setValue(payload.note);
                                    item.controls.updatedOn.setValue(payload.updateOn);
                                }
                                break;
                        }
                    });
                }
                else this.toast.error('view comment failed.');
            });
        }
    }
    loadData() {
        this.loading = true;
        if (this.isApp) {
            this.settingService.getApplicationWaste(this.costSchemaId).then(response => {
                if (response.status) {
                    this.data = response.data;
                    const dataView = [];
                    this.data.forEach(e => {
                        dataView.push(this.createItemView(e));
                    });
                    this.dataForm = this.fb.group({
                        dataUpdate: this.fb.array(dataView)
                    });
                    if (this.userService.isTenantOnly()) this.dataForm.disable();
                    this.loading = false;
                    this.calcHeightTable.emit();
                }
                else this.toast.error("Load data failed");
            });
        }
        else {
            this.settingService.getMaterialWaste(this.costSchemaId).then(response => {
                if (response.status) {
                    this.dataMaterial = response.data;
                    const dataView = [];
                    this.dataMaterial.forEach(e => {
                        dataView.push(this.matItemView(e));
                    });
                    this.materialForm = this.fb.group({
                        dataUpdate: this.fb.array(dataView)
                    });
                    if (this.userService.isTenantOnly()) this.materialForm.disable();
                    this.loading = false;
                    this.calcHeightTable.emit();
                }
                else this.toast.error("Load data failed");
            });
        }

    }

    createItemView(e): FormGroup {
        return this.fb.group({
            appWasteId: e.appWasteId,
            applicationName: e.applicationName,
            wastePercentAdjustment: new PercentPipe('en-US').transform(e.wastePercentAdjustment, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }
    matItemView(e): FormGroup {
        return this.fb.group({
            matWasteId: e.matWasteId,
            materialGroup: e.materialGroup,
            wastePercentAdjustment: new PercentPipe('en-US').transform(e.wastePercentAdjustment, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn
        });
    }
    matItemUpdate(e) {
        let item = this.dataMaterial.find(i => i.matWasteId == e.matWasteId);
        item.wastePercentAdjustment = this.settingService.replaceMask(e.wastePercentAdjustment) / 100;
        item.note = e.note;
        return {
            matWasteId: e.matWasteId,
            wastePercentAdjustment: this.settingService.replaceMask(e.wastePercentAdjustment) / 100,
            note: e.note,
        };
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.appWasteId == e.appWasteId);
        item.wastePercentAdjustment = this.settingService.replaceMask(e.wastePercentAdjustment) / 100;
        item.note = e.note;
        return {
            appWasteId: e.appWasteId,
            wastePercentAdjustment: this.settingService.replaceMask(e.wastePercentAdjustment) / 100,
            note: e.note,
        };
    }
    change(isApp) {
        if (this.isApp == isApp) return;
        this.isApp = isApp;
        this.loadData();
    }
    save() {
        let dataUpdate = [];
        let cellUpdate: any;
        if (this.isApp) {
            (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
                if (e.dirty) {
                    cellUpdate = e;
                    dataUpdate.push(this.createItemUpdate(e.value));
                    e.markAsPristine();
                    e.markAsUntouched();
                }
            });
            if (dataUpdate.length > 0) {
                this.settingService.updateApplicationWaste(dataUpdate).then(response => {
                    if (response.status) {
                        this.toast.success('Application waste has been updated');
                        cellUpdate?.get('updatedOn').setValue(response.data);
                    }
                    else this.toast.error('Update application waste failed');
                });
            }
        }
        else {
            (this.materialForm.get('dataUpdate') as FormArray).controls.forEach(e => {
                if (e.dirty) {
                    cellUpdate = e;
                    dataUpdate.push(this.matItemUpdate(e.value));
                    e.markAsPristine();
                    e.markAsUntouched();
                }
            });
            console.log('updte', dataUpdate)
            if (dataUpdate.length > 0) {
                this.settingService.updateMaterialWaste(dataUpdate).then(response => {
                    if (response.status) {
                        this.toast.success('Material waste has been updated');
                        cellUpdate?.get('updatedOn').setValue(response.data);
                    }
                    else this.toast.error('Update material waste failed');
                });
            }


        }

    }
}
