import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  bodyClasses = 'skin-blue sidebar-mini';
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  waiting_response: boolean;
  loginFlag: boolean;

  constructor(private router: Router, private userService: UserService, private toastr: ToastrService) {
    this.loginFlag = true;
    if (this.userService.checkAuthorization()) {
      this.router.navigate(['home']);
    }
  }

  ngOnInit() {
    // add the the body classes
    this.body.classList.add('skin-blue');
    this.body.classList.add('sidebar-mini');
    sessionStorage.removeItem('Authorization');
  }

  ngOnDestroy() {
    // remove the the body classes
    this.body.classList.remove('skin-blue');
    this.body.classList.remove('sidebar-mini');
  }

  login() {
    document.getElementById('btnLoginSubmit').setAttribute('disabled', 'disabled');
    this.waiting_response = true;
    this.userService.login({ Email: this.email, Password: this.password }).then(response => {
      document.getElementById('btnLoginSubmit').removeAttribute('disabled');
      this.waiting_response = false;
      if (response.status) {
        const data = response.data;
        sessionStorage.setItem('Authorization', 'Bearer ' + data.token);
        sessionStorage.setItem('RefreshToken', data.refreshToken);
        sessionStorage.setItem('Username', data.user);
        this.router.navigate(['home']);
      } else {
        this.toastr.error(response.message, 'Error');
      }
    }).catch(() => {
      document.getElementById('btnLoginSubmit').removeAttribute('disabled');
      this.waiting_response = false;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  forgot() {
    // document.getElementById('btnForgotSubmit').setAttribute("disabled","disabled");
    // this.waiting_response = true;
    // this.userService.forgotPassword(this.email)
    //   .then(response => {
    //     document.getElementById('btnForgotSubmit').removeAttribute("disabled");
    //     this.waiting_response = false;
    //     if (response.status) {
    //       this.toastr.success(response.message, 'Success');
    //       this.loginFlag = true;
    //     } else {
    //       this.toastr.error(response.message, 'Error');
    //     }
    //   })
    //   .catch(() => {
    //     document.getElementById('btnForgotSubmit').removeAttribute("disabled");
    //     this.waiting_response = false;
    //     this.toastr.error("MJX Internal Server Error", 'Error');
    //   });
  }

  keypress(event) {
    if (event.keyCode == 13 || event.which == 13) {
      event.preventDefault();
      if (this.loginFlag) { this.login(); } else { this.forgot(); }
    }
  }
}
