import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TileCostSchemaAssignmentService {

  controller = 'TileCostSchemaAssignments';

  constructor(private http: AuthHttp) {
  }

  getByTenant(tenantId): Promise<any> {
    return this.http.get(`${this.controller}/${tenantId}`).toPromise();
  }

  getChangeLogs(tenantId): Promise<any> {
    return this.http.get(`${this.controller}/GetChangeLogs/${tenantId}`).toPromise();
  }

  getCostSchemaAssignsByChannelId(tenantId: number, channelId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetCostSchemaAssignsByChannelId/${tenantId}/${channelId}`).toPromise();
  }

  applyChanges(model: any): Promise<any> {
    return this.http.post(`${this.controller}/ApplyChanges`, model).toPromise();
  }
}
