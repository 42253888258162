import { SettingService } from './../../services/setting.service';
import { LicenseTypeService } from './../../services/license-type.service';
import { AddressService } from 'src/app/services/address.service';
import { async } from '@angular/core/testing';
import { OnInit } from '@angular/core';
import { Component, } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
declare var $: any;


@Component({
  templateUrl: './license-types.html',
  styleUrls: ['./license-types.scss']
})

export class LicenseTypesComponent implements OnInit {

  addLicenseForm: FormGroup;
  loading = false;
  moduleSelectedId = 0;
  moduleLicenseTypes: any = [];
  modules: any = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private licenseTypeService: LicenseTypeService,
    private settingService: SettingService) {
    this.addLicenseForm = fb.group({
      licenseType: ['', Validators.required],
      description: ['']
    });
  }
  get licenseType(): FormControl {
    return this.addLicenseForm.get('licenseType') as FormControl;
  }

  getErrorLicenseName() {
    return this.licenseType.hasError('required') ? 'License Type is required' : '';
  }

  ngOnInit() {
    $('body').layout('fix');
    this.getListModule();
  }

  getLicenseType(moduleId): void {
    this.moduleLicenseTypes = [];
    this.loading = true;
    this.licenseTypeService.getLicenseTypeByModule(moduleId)
      .then((res) => {
        if (!res.status) {
          this.toastr.error('Can\'t get list license type');
          return;
        }
        this.moduleLicenseTypes = res.data;
        setTimeout(() => this.loading = false, 300);
      });
  }

  init(module: any) {
    module.expand = !module.expand;
    this.modules.forEach(m => {
      if (m.moduleId !== module.moduleId) {
        m.expand = false;
      }
    });
    if (!module.expand) { return; }
    this.moduleSelectedId = module.moduleId;
    this.getLicenseType(module.moduleId);
  }
  getListModule() {
    this.settingService.getListModule()
      .then((res) => {
        if (!res.status) {
          this.toastr.error('Can\'t get list module');
          return;
        }
        this.modules = res.data.map(obj => ({ moduleId: obj.moduleId, moduleName: obj.moduleName }));
      });
  }

  addNewLicenseType() {
    this.loading = true;
    this.addLicenseForm.disable();
    this.licenseTypeService.addLicenseType(this.moduleSelectedId, this.addLicenseForm.value)
      .then((res) => {
        this.loading = false;
        this.addLicenseForm.enable();
        if (!res.status) {
          this.toastr.error(res.message);
          return;
        }
        this.toastr.success('License Type has been added.');
        this.addLicenseForm.reset();
        this.addLicenseForm.markAllAsTouched();
        this.addLicenseForm.markAsPristine();
        this.getLicenseType(this.moduleSelectedId);
      });
  }
}
