import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-thickness',
  templateUrl: './global-thickness.html',
})

export class TileThicknessGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  thicknessName: string;
  abbreviation: string;
  loading = false;
  data: any[];
  dataFilter: any[];
  dataForm: FormGroup;
  thicknessId: number;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileThickness().then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      thickness: [e.thickness, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedAt: e.updatedAt,
      abbreviation: e.abbreviation,
    });
  }

  validData() {
    if (this.thicknessName == null || this.thicknessName.trim() == '' || this.thicknessName.trim().length < 2) {
      this.toast.error('Thickness needs to be greater than 2 characters');
      return false;
    }
    if (this.abbreviation == null || this.abbreviation.trim() == '' || this.abbreviation.trim().length < 2) {
      this.toast.error('Abbreviation needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addTileThickness({
        thickness: this.thicknessName,
        abbreviation: this.abbreviation,
      }).then(response => {
        if (response.status) {
          this.thicknessName = '';
          this.abbreviation = '';
          this.toast.success('New Thickness has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The Thickness is added fail');
        }
      });
    }
  }

  delete(thicknessId) {
    this.modalConfirm.open();
    this.thicknessId = thicknessId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteTileThickness(this.thicknessId, 0).then(response => {
      if (response.status) {
        this.toast.success('This thickness has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.thickness = e.thickness;
    item.active = e.active;
    return {
      id: e.id,
      thickness: e.thickness,
      active: e.active,
      isGlobal: true,
      abbreviation: e.abbreviation,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.thickness == null || e.value.thickness.trim() == "" || e.value.thickness.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Thickness name needs to be greater than 2 characters');
        }
        isValid = false;
      }
      if (e.value.abbreviation == null || e.value.abbreviation.trim() == "" || e.value.abbreviation.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Abbreviation name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileThickness(dataUpdate[0]).then(response => {
        if (response.status) {
          this.toast.success('Thickness has been updated');
          // this.loadData();
          // TODO: Update just UpdatedAt
        }
        else this.toast.error('Update thickness failed');
      });
    }
  }
}
