<div class="content-wrapper">
  <section class="content-header">
    <h1>Compare Tool</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Compare Tool</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-xs-6">
        <label>Search by bid Id</label>
        <div class="input-group">
          <input #txtBidId type="text" class="form-control" placeholder="Bid Id" (keyup.enter)="showLog(txtBidId.value)">
          <span class="input-group-btn">
            <button class="btn btn-primary" type="button" (click)="showLog(txtBidId.value)">Show log</button>
          </span>
        </div>
      </div>
      <div class="col-lg-10 col-md-8 col-xs-6">
        <div class="form-group" style="margin-top: 25px;">
          <button type="button" class="btn btn-primary" (click)="modalConfirm.open()">Execute compare tenant Stockett</button>
          <button type="button" class="btn btn-primary" style="margin-left: 5px" (click)="loadData()">Refresh</button>
        </div>
      </div>
    </div>
    <h4 style="line-height: 1.5" *ngIf="!dataBatch">Loading...</h4>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="80">Batch Id</th>
            <th [style.width.px]="100">Status</th>
            <th>Message</th>
            <th [style.width.px]="160">Created On</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataBatch">
            <td [style.width.px]="80">
              <a routerLink="/log-of-batch/{{item.batchId}}">{{item.batchId}}</a>
            </td>
            <td [style.width.px]="100">{{item.status}}</td>
            <td>{{item.message}}</td>
            <td [style.width.px]="160">{{item.createdDate | date:'MM/dd/yyyy h:mm a'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>

<bs-modal #modal>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">View log of bid id {{currentBidId}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <pre [innerHTML]="currentDataBid"></pre>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you compare all bid data of tenant Stockett?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()">No</button>
    <button type="button" class="btn btn-primary" (click)="compareByTenantStockett();modalConfirm.close()">Yes</button>
  </bs-modal-footer>
</bs-modal>
