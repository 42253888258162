<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Tax Regions</h4>
  </div>
  <div class="col-xs-12">
    <form [formGroup]="formAdd" (submit)="add()" novalidate>
      <div class="row">
        <div class="col-lg-2 col-sm-6 col-xs-12">
          <div class="form-group" [class.has-error]="regionName.invalid && (regionName.dirty || regionName.touched)">
            <label class="control-label">Region Name</label>
            <input type="text" trim="blur" class="form-control" formControlName="assignmentName" maxlength="200" required>
            <span *ngIf="regionName.invalid && (regionName.dirty || regionName.touched)" class="help-block">{{getErrorRegionName()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-lg-9 col-xs-12">
          <div class="row">
            <div class="col-xs-2">
              <label class="control-label">Sales Tax %</label>
              <input type="text" class="form-control" placeholder="0.0000%" formControlName="salesTaxRate" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true">
            </div>
            <div class="col-xs-2">
              <label class="control-label">Material Tax %</label>
              <input type="text" class="form-control" placeholder="0.0000%" formControlName="materialCostRate" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true">
            </div>
            <div class="col-xs-2">
              <label class="control-label">Supplies Tax %</label>
              <input type="text" class="form-control" placeholder="0.0000%" formControlName="suppliesCostRate" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true">
            </div>
            <div class="col-xs-2">
              <label class="control-label">Items Tax %</label>
              <input type="text" class="form-control" placeholder="0.0000%" formControlName="itemCostRate" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true">
            </div>
            <div class="col-xs-2">
              <label class="control-label">Labor Tax %</label>
              <input type="text" class="form-control" placeholder="0.0000%" formControlName="laborCostRate" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true">
            </div>
            <div class="col-xs-2" style="margin-top: 25px">
              <button type="submit" class="btn btn-primary btn-block">Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Tax Region</th>
              <th [style.width.px]="120">Sales Tax %</th>
              <th [style.width.px]="120">Material Tax %</th>
              <th [style.width.px]="120">Supplies Tax %</th>
              <th [style.width.px]="120">Items Tax %</th>
              <th [style.width.px]="120">Labor Tax %</th>
              <th [style.width.px]="70" class="text-center">Active</th>
              <th>Updated On</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <input type="text" class="form-control" formControlName="assignmentName" readonly>
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" placeholder="0.0000%" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="salesTaxRate" (blur)="save()" (keyup.enter)="save()" [readonly]="item.value.editAllowable ? null: 'readonly'">
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" placeholder="0.0000%" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="materialCostRate" (blur)="save()" (keyup.enter)="save()" [readonly]="item.value.editAllowable ? null: 'readonly'">
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" placeholder="0.0000%" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="suppliesCostRate" (blur)="save()" (keyup.enter)="save()" [readonly]="item.value.editAllowable ? null: 'readonly'">
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" placeholder="0.0000%" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="itemCostRate" (blur)="save()" (keyup.enter)="save()" [readonly]="item.value.editAllowable ? null: 'readonly'">
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" placeholder="0.0000%" mask="separator.4" suffix="%" thousandSeparator="," separatorLimit="100000" [allowNegativeNumbers]="true" formControlName="laborCostRate" (blur)="save()" (keyup.enter)="save()" [readonly]="item.value.editAllowable ? null: 'readonly'">
                  </td>
                  <td [style.width.px]="70" class="text-center">
                    <input type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" (click)="remove(item.value.taxAssignmentId)" [disabled]="item.value.deleteAllowable ? null: 'disabled'">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this tax?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
