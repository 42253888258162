import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { Router } from '@angular/router';
import * as _ from 'lodash';

export enum ObjectRoles {
  SuperAdmin = 1,
  TenantAdmin = 2,
  // ProcessAdjustments = 4,
  PricingAdmin = 6,
  TenantSecurityAdmin = 7,
  TenantOnly = 8,
  QXMSecurityAdmin = 9,
}

export enum ObjectPermission {
  Account = 1,
  Project = 2,
  Bid = 3,
  OthersAccount = 4,
  OthersProject = 5,
  OthersBid = 6,
  DimensionAdjustment = 7,
  Quote = 8,
  OthersQuote = 9,
  Comment = 12,
  OthersComment = 13,
  Document = 14,
  OthersDocument = 15,
  AccountPricingSchema = 16,
  Contract = 17,
  OthersContract = 18,
  AccountTransferOwnership = 19,
  BidPriceSchemaAssignment = 22,
  BidMaterial = 23,
  AdminQuoteDeletion = 25,
  ApplicationLineBudgetDetails = 26,
  Dashboard = 27,
  ContractOwnership = 28,
  BidSlabGroup = 29,
  BidItemGroup = 31,
  CommunityMaterialGroupChange = 32,
  BulkContactDeletion = 35,
  BidPriceOverride = 36,
  Opportunities = 37,
  OtherOpportunities = 38,
  ChangeProjectChannel = 39,
  EdgeGroups = 40,
  CommunityPlans = 41,
  CommunityPricing = 42,
  Orders = 43,
  ModifyDirectAccountCustomerAssignment = 44,
  TenantRoleSecurity = 45,
  ExcludeApplicationFromBidLevelCharges = 46,
  PlanBillingCodes = 47,
  AdvancedApplicationPricing = 48,
  Community = 49,
  MaterialGroupPricing = 53,
  ItemGroupPricing = 54,
  EdgeGroupPricing = 55,
  DrawApplication = 56,
  TileGroups = 57,
  GlobalTileCatalog = 58,
  EditMaterialGroupCost = 59,
  EditItemGroupCost = 60,
  EditEdgeGroupCost = 61,
  EditTileGroupCost = 62,
  Estimator = 63,
  ViewOtherEstimators = 64,
  Contacts = 65,
  Budget = 66,
  Job = 67,
  WorkOrders = 68,
  Appointments = 69,
  Planning = 70,
  Scheduling = 71,
  Execution = 72,
  Teams = 73,
  People = 74,
  LocationSchedule = 75,
}

@Injectable()
export class UserService {

  public roles: any = [];
  public tenants: any = [];
  public ready = false;

  constructor(private http: AuthHttp, private router: Router) {
  }

  hasRoleSupperAdmin() {
    return _.indexOf(this.roles, ObjectRoles.SuperAdmin) !== -1;
  }

  hasRoleTenantOnly() {
    return _.indexOf(this.roles, ObjectRoles.TenantOnly) !== -1;
  }

  isTenantOnly() {
    return !this.hasRoleSupperAdmin() && this.hasRoleTenantOnly();
  }

  hasRoleQXMSecurityAdmin() {
    return _.indexOf(this.roles, ObjectRoles.QXMSecurityAdmin) !== -1;
  }

  checkAuthorization() {
    if (sessionStorage.getItem('Authorization') === null) {
      return false;
    }
    return true;
  }

  login(info): Promise<any> {
    return this.http.post('loginAdmin', JSON.stringify(info)).toPromise();
  }

  getAllUsers(filter): Promise<any> {
    return this.http.post('Users/GetAllUsersByFilter', JSON.stringify(filter)).toPromise();
  }
  getUsersByRoleFromAdmin(filter): Promise<any> {
    return this.http.post('Users/GetUsersByRoleFromAdmin', JSON.stringify(filter)).toPromise();
  }

  getUsersByGlobalRole(filter): Promise<any> {
    return this.http.post('Users/GetUsersByGlobalRole', JSON.stringify(filter)).toPromise();
  }

  unassignRole(userId, roleId): Promise<any> {
    return this.http.put(`Users/Unassign/${userId}/${roleId}`).toPromise();
  }

  getAllTenant(): Promise<any> {
    return this.http.get('GetAllTenant').toPromise();
  }

  activeUser(id, status): Promise<any> {
    return this.http.post(`SetActiveUser/${id}/${status}`).toPromise();
  }

  getUserInfo(userId): Promise<any> {
    return this.http.get('Users/' + userId).toPromise();
  }

  getListActivities(userId, paging): Promise<any> {
    return this.http.post(`GetListActivitiesAdmin/${userId}`, JSON.stringify(paging)).toPromise();
  }

  resetPassword(info): Promise<any> {
    return this.http.post(`AdminResetPassword`, JSON.stringify(info)).toPromise();
  }

  updateUserInfo(userId, userInfo): Promise<any> {
    return this.http.put('Users/' + userId, userInfo).toPromise();
  }

  createUserToTenant(info): Promise<any> {
    return this.http.post('CreateUserToTenant', JSON.stringify(info)).toPromise();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }
  validateUserLicenseType(data): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/ValidateUserLicenseType`, JSON.stringify(data)).toPromise();
  }
  applyChangeUserLicenseType(data): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/ApplyChangeUserLicenseType`, JSON.stringify(data)).toPromise();
  }

  getUserModuleLicenseTypes(userId: number): Promise<any> {
    return this.http.get(`User/GetUserModuleLicenseTypes/${userId}`).toPromise();
  }
  getUserLicenseTypesByModuleId(moduleId: number): Promise<any> {
    return this.http.get(`UserModuleLicenseTypes/GetUserLicenseTypesByModuleId/${moduleId}`).toPromise();
  }
  assignUserLicenseType(userId: number, moduleId: number, licenseTypeId: number, tenantId: number): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/AssignUserLicenseType/${userId}/${moduleId}/${licenseTypeId}/${tenantId}`).toPromise();
  }
  removeUserLicenseTypeAndRoles(id: number): Promise<any> {
    return this.http.delete(`UserModuleLicenseTypes/RemoveUserLicenseTypeAndRoles/${id}`).toPromise();
  }

  assignTenantsToUser(userId: number, tenantIds: number[]): Promise<any> {
    return this.http.put(`Users/AssignTenantsToUser/${userId}`, JSON.stringify(tenantIds)).toPromise();
  }

  removeTenantFromUser(userId: number, tenantId: number): Promise<any> {
    return this.http.delete(`Users/RemoveTenantFromUser/${userId}/${tenantId}`).toPromise();
  }

  resendInvitation(userId: number): Promise<any> {
    return this.http.post(`Users/ResendInvitationToAllTenants/${userId}`).toPromise();
  }
}
