<div class="row">
  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Channel</label>
      <select [(ngModel)]="channelId" (change)="filterData()" class="form-control">
        <option *ngFor="let c of channels" [ngValue]="c.channelId">{{c.channelName}}</option>
      </select>
    </div>
  </div> -->
  <div class="col-md-3 col-sm-4 col-xs-12">
    <fieldset class="filter-type">
      <legend>Process Type Filter</legend>
      <div>
        <label class="radio-inline">
          <input type="radio" name="processTypeId0" [value]="2" [(ngModel)]="processTypeId" (change)="filterData()"> Fabrication
        </label>
        <label class="radio-inline">
          <input type="radio" name="processTypeId0" [value]="3" [(ngModel)]="processTypeId" (change)="filterData()"> Install
        </label>
      </div>
    </fieldset>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">BASE OUTPUT PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Process</th>
              <th>Unit Type</th>
              <th [style.width.px]="110">Base Output</th>
              <th [style.width.px]="120">Std Labor Rate</th>
              <th [style.width.px]="140">Labor Override</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.processName}}</td>
                  <td>{{item.value.unit}}</td>
                  <td [style.width.px]="110">
                    <input type="text" class="form-control text-right" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" formControlName="outputPerHour" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" formControlName="stdHourlyRate" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <td [style.width.px]="140">
                    <input type="text" class="form-control text-right" prefix="$" mask="separator.2" thousandSeparator="," separatorLimit="100000000000" [allowNegativeNumbers]="true" formControlName="laborCostPerHourOverride" (blur)="note.focus()" (keyup.enter)="note.focus()">
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}} <span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>

