import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-material-removal',
  templateUrl: './material-removal.html',
  styleUrls: ['./material-removal.scss']
})

export class MaterialRemovalComponent implements OnInit {
  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  materialType: any;
  baseCostPerHour = null;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  removalMaterialId: any;
  sqft: any;
  isTenantOnly: boolean = false;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService, private userService: UserService) {
    this.isTenantOnly = this.userService.isTenantOnly();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getMaterialRemoval(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.isTenantOnly) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      removalMaterialId: e.removalMaterialId,
      removalMaterial: [e.removalMaterial, Validators.required],
      baseOutputPerHour: new DecimalPipe('en-US').transform(e.baseOutputPerHour, '.2-2'),
      baseHourlyCost: new DecimalPipe('en-US').transform(e.baseHourlyCost, '.2-2'),
      updatedOn: e.updatedOn
    });
  }

  validData() {
    if (this.costSchemaId == 0) {
      this.toast.error('Please select a cost schema');
      return false;
    }
    else if (this.materialType == null || this.materialType == '' || this.materialType.length < 3) {
      this.toast.error('Material type must be longer than 3 characters.');
      return false;
    }
    else if (this.sqft == null || this.sqft == '' || this.settingService.replaceMask(this.sqft) == 0) {
      this.toast.error('Output per hour  must be greater than zero.');
      return false;
    }
    else if (this.baseCostPerHour == null || this.baseCostPerHour == '' || this.settingService.replaceMask(this.baseCostPerHour) == 0) {
      this.toast.error('Cost per hour must be greater than zero.');
      return false;
    }
    return true;
  }

  addMaterialRemoval() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addMaterialRemoval({
        costSchemaId: this.costSchemaId,
        removalMaterial: this.materialType,
        baseOutputPerHour: this.settingService.replaceMask(this.sqft),
        baseHourlyCost: this.settingService.replaceMask(this.baseCostPerHour)
      }).then(response => {
        if (response.status) {
          this.materialType = '';
          this.sqft = new PercentPipe('en-US').transform(0, '.2-2');
          this.baseCostPerHour = new PercentPipe('en-US').transform(0, '.2-2');
          this.toast.success('The material type was has been added');
          this.loadData();
        }
        else {
          // if(response.data == 0){
          //   this.toast.error('This application already exists.  System doesn\'t support duplicate applications.  Make appropriate changes and retry.');
          // }
          // else{
          this.toast.error('The material type is added fail');
          //}
          this.loading = false;
        }
      });
    }
  }

  deleteMaterial(removalMaterialId) {
    this.modalConfirm.open();
    this.removalMaterialId = removalMaterialId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteMaterialRemoval(this.removalMaterialId).then(response => {
      if (response.status) {
        this.toast.success('Material type has been updated');
        this.loading = true;
        this.loadData();
        this.settingService.setBidsIsRefreshByCostSchema(this.costSchemaId);
      }
      else
        this.toast.error('Remove this material type failed');
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.removalMaterialId == e.removalMaterialId);
    item.baseOutputPerHour = this.settingService.replaceMask(e.baseOutputPerHour);
    item.baseHourlyCost = this.settingService.replaceMask(e.baseHourlyCost);
    item.removalMaterial = e.removalMaterial;
    return {
      removalMaterialId: e.removalMaterialId,
      baseOutputPerHour: this.settingService.replaceMask(e.baseOutputPerHour),
      baseHourlyCost: this.settingService.replaceMask(e.baseHourlyCost),
      removalMaterial: e.removalMaterial
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
        if (e.value.removalMaterial == null || e.value.removalMaterial.trim() == '' || e.value.removalMaterial.trim().length < 3) {
          this.toast.error('Material type must be longer than 3 characters.');
          isValid = false;
        }
        else if (e.value.baseOutputPerHour == null || e.value.baseOutputPerHour == '' || this.settingService.replaceMask(e.value.baseOutputPerHour) == 0) {
          this.toast.error('Output per hour must be greater than zero.');
          isValid = false;
        }
        else if (e.value.baseHourlyCost == null || e.value.baseHourlyCost == '' || this.settingService.replaceMask(e.value.baseHourlyCost) == 0) {
          this.toast.error('Cost per hour  must be greater than zero.');
          isValid = false;
        }
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateMaterialRemovals(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Material type has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          // if (dataUpdate.length > 0) this.settingService.setBidsIsRefresh(this.costSchemaId, 0);
        }
        else this.toast.error('Update material type failed');
      });
    }
  }

  checkMin(e: FormControl) {
    if (e.parent.value.baseOutputPerHour == null || e.parent.value.baseOutputPerHour == '' || this.settingService.replaceMask(e.parent.value.baseOutputPerHour) == 0) {
      e.setErrors({ 'hasMin': true });
    }
  }

  checkMinSQFT(e: FormControl) {

    if (e.parent.value.baseHourlyCost == null || e.parent.value.baseHourlyCost == '' || this.settingService.replaceMask(e.parent.value.baseHourlyCost) == 0) {
      e.setErrors({ 'hasMinSQFT': true });
    }

  }
}
