<!-- Modal -->
<div class="modal fade" id="reassignChannelModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Re-assign Channel</h4>
      </div>
      <div class="modal-body">
        <form novalidate [formGroup]="frm">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label>Current Channel</label>
                <input type="text" class="form-control" formControlName="currentChannelName">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label>Channel
                  <span class="text-danger">*</span>
                </label>
                <select formControlName="channelId" class="form-control">
                  <option [value]="null">Select a channel</option>
                  <option *ngFor="let t of channels" [ngValue]="t.channelId">{{t.channelName}}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button
          data-dismiss="modal"
          type="button" class="btn btn-primary"
          (click)="save()"
          [disabled]="frm.invalid || frm.disabled">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
