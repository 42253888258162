import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class LocationService {

  controller = 'Locations';

  constructor(private http: AuthHttp) { }

  getLocationsByTenantId(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetLocationsByTenantId/${tenantId}`).toPromise();
  }

  addLocation(tenantId: number, data: any): Promise<any> {
    return this.http.post(`${this.controller}/AddLocation/${tenantId}`, JSON.stringify(data)).toPromise();
  }

  updateLocationAndAddress(locationId: number, tenantId: number, data: any): Promise<any> {
    return this.http.put(`${this.controller}/UpdateLocationAndAddress/${locationId}/${tenantId}`, JSON.stringify(data)).toPromise();
  }

  checkDeleteLocation(locationId: number, tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckDeleteLocation/${locationId}/${tenantId}`).toPromise();
  }

  deleteLocation(locationId: number, tenantId: number, reassignlocationId: number): Promise<any> {
    return this.http.delete(`${this.controller}/DeleteLocation/${locationId}/${tenantId}/${reassignlocationId}`).toPromise();
  }
}
